/* eslint-disable max-len */
import { AnalysisComponent, ComponentInputProperty, ComponentOutputProperty } from "../types/analysis";
import { ComponentInputPropertyID } from "../types/ComponentsInputPropertyID";

export function newComponentInputProperty(id: ComponentInputPropertyID, name: string, value: string, label = "", used = true, dc = true): ComponentInputProperty {
    return {
        id: id,
        description: "Input property",
        name: name,
        label: label,
        value: value,
        used: used,
        allowedAnalysis: { dc: dc, ac: true, tran: true },
    };
}

export function newComponentOutputProperty(id: string, name: string, expr: string, dc = true, ac = true): ComponentOutputProperty {
    return {
        id: id,
        description: "Output property",
        name: name,
        expr: expr,
        allowedAnalysis: { dc: dc, ac: ac, tran: true },
    };
}

export function newGround(): AnalysisComponent {
    return { class: "Ground", tag: "", inputProperties: [], outputProperties: [], contactPoints: ["0"], allowance: "" };
}

export function newResistor(tag: string, points: string[], val = "1k"): AnalysisComponent {
    return {
        class: "Resistor",
        tag: tag,
        inputProperties: [
            newComponentInputProperty("r_resistance", "@tag(res)", val),
        ],
        outputProperties: [
            newComponentOutputProperty("r_voltage", "v(@tag)", ""),
            newComponentOutputProperty("r_current", "i(@tag)", ""),
            newComponentOutputProperty("r_power", "p(@tag)", "", true, false),
        ],
        contactPoints: points,
        allowance: "",
    };
}

export function newCapacitor(tag: string, points: string[], val = "10n"): AnalysisComponent {
    return {
        class: "Capacitor",
        tag: tag,
        inputProperties: [
            newComponentInputProperty("c_capacitance", "@tag(cap)", val),
            newComponentInputProperty("c_init_condition", "@tag(ic)", "0"),
        ],
        outputProperties: [
            newComponentOutputProperty("c_voltage", "v(@tag)", ""),
            newComponentOutputProperty("c_current", "i(@tag)", ""),
            newComponentOutputProperty("c_power", "p(@tag)", "", true, false),
        ],
        contactPoints: points,
        allowance: "",
    };
}

export function newInductor(tag: string, points: string[], val = "1m"): AnalysisComponent {
    return {
        class: "Inductor",
        tag: tag,
        inputProperties: [
            newComponentInputProperty("l_inductance", "@tag(ind)", val),
            newComponentInputProperty("l_init_condition", "@tag(ic)", val),
        ],
        outputProperties: [
            newComponentOutputProperty("l_voltage", "v(@tag)", ""),
            newComponentOutputProperty("l_current", "i(@tag)", ""),
            newComponentOutputProperty("l_power", "p(@tag)", "", true, false),
        ],
        contactPoints: points,
        allowance: "",
    };
}


export function newSPeriodicSw(tag: string, points: string[], ph = "1"): AnalysisComponent {
    return {
        class: "PeriodicSwitch",
        tag: tag,
        inputProperties: [
            newComponentInputProperty("sp_switching_ph", "@tag(sw_ph)", ph),
        ],
        outputProperties: [
            newComponentOutputProperty("sp_voltage", "v(@tag)", ""),
            newComponentOutputProperty("sp_current", "i(@tag)", ""),
        ],
        contactPoints: points,
        allowance: "",
    };
}

export function newXPotenciometer(tag: string, points: string[], pos = "0.5"): AnalysisComponent {
    return {
        class: "Subcircuit",
        tag: tag,
        inputProperties: [
            newComponentInputProperty("subcirc_model", "", "potenciometer"),// TODO??
            newComponentInputProperty("subcirc_par", "@tag(res)", "2k", "R_par"),
            newComponentInputProperty("subcirc_par", "@tag(pos)", pos, "k_par"),
        ],
        outputProperties: [
            newComponentOutputProperty("x_expr", "@tag(vout)", "v(@node[1])"),
        ],
        contactPoints: points,
        allowance: ".subckt @value[0] 1 2 3 PARAMS: R_par=1k k_par=0.5\nR1 1 2 {@label[1]*(1-@label[2])}\nR2 2 3 {@label[1]*@label[2]}\n.ends @value[0]",
    };
}

// TODO: general npn vs model based npn??
export function newBJT(tag: string, points: string[], model = "npnmod"): AnalysisComponent {
    return {
        class: "BJT",
        tag: tag,
        inputProperties: [
            newComponentInputProperty("bjt_model", "", model),
        ], // TODO: model
        outputProperties: [
            newComponentOutputProperty("bjt_vbase", "@tag(vb)", "v(@node[1])"),
            newComponentOutputProperty("bjt_vcollector", "@tag(vc)", "v(@node[0])"),
            newComponentOutputProperty("bjt_vemitter", "@tag(ve)", "v(@node[2])"),
            newComponentOutputProperty("bjt_vbe", "@tag(vbe)", "v(@node[1])-v(@node[2])"),
            newComponentOutputProperty("bjt_vcb", "@tag(vbc)", "v(@node[1])-v(@node[0])"),
            newComponentOutputProperty("bjt_vce", "@tag(vce)", "v(@node[0])-v(@node[2])"),
            newComponentOutputProperty("bjt_icollector", "@tag(ic)", "", true, false),
            newComponentOutputProperty("bjt_ibase", "@tag(ib)", "", true, false),
            newComponentOutputProperty("bjt_iemitter", "@tag(ie)", "", true, false),
            newComponentOutputProperty("bjt_power", "p(@tag)", "", true, false),
        ],
        contactPoints: points,
        allowance: ".model @value[0] npn bf=100",
    };
}

export function newDiode(tag: string, points: string[], model = "dmod"): AnalysisComponent {
    return {
        class: "Diode",
        tag: tag,
        inputProperties: [
            newComponentInputProperty("diode_model", "", model),
        ],
        outputProperties: [
            newComponentOutputProperty("diode_current", "i(@tag)", ""),
            newComponentOutputProperty("diode_voltage", "v(@tag)", ""),
            newComponentOutputProperty("diode_power", "p(@tag)", "", true, false),
        ],
        contactPoints: points,
        allowance: ".model @value[0] d IS=1e-14",
    };
}

export function newMOS(tag: string, points: string[], model = "nmosmod", l="10u", w = "160u"): AnalysisComponent {
    return {
        class: "MOSFET",
        tag: tag,
        inputProperties: [
            newComponentInputProperty("mosfet_model", "", model),
            newComponentInputProperty("mosfet_length", "@tag(l)", l),
            newComponentInputProperty("mosfet_width", "@tag(w)", w),
        ],
        outputProperties: [
            newComponentOutputProperty("nmos_vemitor", "@tag(ve)", "v(@node[2])"),
            newComponentOutputProperty("nmos_vbe", "@tag(vbe)", "v(@node[1])-v(@node[2])"),
            newComponentOutputProperty("nmos_icollector", "@tag(ic)", "", true, false),
            newComponentOutputProperty("nmos_ibase", "@tag(ib)", "", true, false),
            newComponentOutputProperty("nmos_iemitor", "@tag(ie)", "", true, false),
        ],
        contactPoints: points,
        allowance: ".model @value[0] NMOS VTO=1.9 KP=0.0016",
    };
}

export function newVoltageSource(tag: string, points: string[], dc = "10", ac = "0"): AnalysisComponent {
    return {
        name: "Voltage source",
        class: "IndepSource",
        tag: tag,
        inputProperties: [
            newComponentInputProperty("voltage_dc", "@tag(dc)", dc),
            newComponentInputProperty("voltage_acmag", "@tag(mag)", ac),
            newComponentInputProperty("voltage_acphase", "@tag(phase)", "0"),
        ],
        outputProperties: [
            newComponentOutputProperty("v_voltage", "v(@tag)", ""),
            newComponentOutputProperty("v_current", "i(@tag)", ""),
            newComponentOutputProperty("v_power", "p(@tag)", "", true, false),
        ],
        contactPoints: points,
        allowance: "",
    };
}

export function newCurrentSource(tag: string, points: string[], dc = "1m", ac = "0"): AnalysisComponent {
    return {
        name: "Current source",
        class: "IndepSource",
        tag: tag,
        inputProperties: [
            newComponentInputProperty("current_dc", "@tag(dc)", dc),
            newComponentInputProperty("current_acmag", "@tag(mag)", ac),
            newComponentInputProperty("current_acphase", "@tag(phase)", "0"),
        ],
        outputProperties: [
            newComponentOutputProperty("i_voltage", "v(@tag)", ""),
            newComponentOutputProperty("i_current", "i(@tag)", ""),
            newComponentOutputProperty("i_power", "p(@tag)", "", true, false),
        ],
        contactPoints: points,
        allowance: "",
    };
}

export function newBattery(tag: string, points: string[], dc = "10"): AnalysisComponent {
    return {
        name: "Battery",
        class: "IndepSource",
        tag: tag,
        inputProperties: [
            newComponentInputProperty("voltage_dc", "@tag(dc)", dc),
        ],
        outputProperties: [
            newComponentOutputProperty("v_voltage", "v(@tag)", ""),
            newComponentOutputProperty("v_current", "i(@tag)", ""),
            newComponentOutputProperty("v_power", "p(@tag)", "", true, false),
        ],
        contactPoints: points,
        allowance: "",
    };
}

export function newSinVoltageSource(tag: string, points: string[], va = "1", f = "1k"): AnalysisComponent {
    return {
        name: "Sinusoidal Voltage Source",
        class: "IndepSinSource",
        tag: tag,
        inputProperties: [
            newComponentInputProperty("voltage_dc", "@tag(dc)", "0"),
            newComponentInputProperty("voltage_acmag", "@tag(mag)", "1"),
            newComponentInputProperty("voltage_acphase", "@tag(phase)", "0"),
            newComponentInputProperty("sinus_offset", "@tag(offset)", "0", "", true, false),
            newComponentInputProperty("sinus_ampl", "@tag(ampl)", va, "", true, false),
            newComponentInputProperty("sinus_freq", "@tag(freq)", f, "", true, false),
            newComponentInputProperty("sinus_delay", "@tag(delay)", "0", "", true, false),
            newComponentInputProperty("sinus_damp", "@tag(theta)", "0", "", true, false),
        ],
        outputProperties: [
            newComponentOutputProperty("v_voltage", "v(@tag)", ""),
            newComponentOutputProperty("v_current", "i(@tag)", ""),
            newComponentOutputProperty("v_power", "p(@tag)", "", true, false),
        ],
        contactPoints: points,
        allowance: "",
    };
}

export function newSinCurrentSource(tag: string, points: string[], va = "1", f = "1k"): AnalysisComponent {
    return {
        name: "Sinusoidal Current Source",
        class: "IndepSinSource",
        tag: tag,
        inputProperties: [
            newComponentInputProperty("current_dc", "@tag(dc)", "0"),
            newComponentInputProperty("current_acmag", "@tag(mag)", "1m"),
            newComponentInputProperty("current_acphase", "@tag(phase)", "0"),
            newComponentInputProperty("sinus_offset", "@tag(offset)", "0", "", true, false),
            newComponentInputProperty("sinus_ampl", "@tag(ampl)", va, "", true, false),
            newComponentInputProperty("sinus_freq", "@tag(freq)", f, "", true, false),
            newComponentInputProperty("sinus_delay", "@tag(delay)", "0", "", true, false),
            newComponentInputProperty("sinus_damp", "@tag(theta)", "0", "", true, false),
        ],
        outputProperties: [
            newComponentOutputProperty("i_voltage", "v(@tag)", ""),
            newComponentOutputProperty("i_current", "i(@tag)", ""),
            newComponentOutputProperty("i_power", "p(@tag)", "", true, false),
        ],
        contactPoints: points,
        allowance: "",
    };
}

/** Exx */
export function newVCVS(tag: string, points: string[],  ncp: string, ncn: string, gain = "1"): AnalysisComponent {
    return {
        name: "VCVS",
        class: "VoltageCtrlSource",
        tag: tag,
        inputProperties: [
            newComponentInputProperty("vcvs_nc_pos", "@tag(ctrl_pos)", ncp),
            newComponentInputProperty("vcvs_nc_neg", "@tag(ctrl_neg)", ncn),
            newComponentInputProperty("vcvs_gain", "@tag(gain)", gain),
        ],
        outputProperties: [
            //newComponentOutputProperty("vcvs_vctrl", "@tag(vctrl)", "(v(@node[2])-v(@node[3]))"),
            newComponentOutputProperty("vcvs_vout", "v(@tag)", "(v(@node[0])-v(@node[1]))"),
            newComponentOutputProperty("vcvs_iout", "i(@tag)", "", true, false),
        ],
        contactPoints: points,
        allowance: "",
    };
}

/** Gxx */
export function newVCCS(tag: string, points: string[], ncp: string, ncn: string, gain = "1"): AnalysisComponent {
    return {
        name: "VCCS",
        class: "VoltageCtrlSource",
        tag: tag,
        inputProperties: [
            newComponentInputProperty("vccs_nc_pos", "@tag(ctrl_pos)", ncp),
            newComponentInputProperty("vccs_nc_neg", "@tag(ctrl_neg)", ncn),
            newComponentInputProperty("vccs_gain", "@tag(gain)", gain),
        ],
        outputProperties: [
            //newComponentOutputProperty("vccs_vctrl", "@tag(vctrl)", "(v(@node[2])-v(@node[3]))"), // not true anymore
            newComponentOutputProperty("vccs_vout", "v(@tag)", "(v(@node[0])-v(@node[1]))"),
            newComponentOutputProperty("vccs_iout", "i(@tag)", "", true, false),
        ],
        contactPoints: points,
        allowance: "",
    };
}

/** Fxx */
export function newCCCS(tag: string, points: string[], vref: string, gain = "1"): AnalysisComponent {
    return {
        name: "CCCS",
        class: "CurrentCtrlSource",
        tag: tag,
        inputProperties: [
            newComponentInputProperty("cccs_vref", "@tag(vref)", vref),
            newComponentInputProperty("cccs_gain", "@tag(gain)", gain),
        ],
        outputProperties: [
            newComponentOutputProperty("cccs_vout", "v(@tag)", "(v(@node[0])-v(@node[1]))"),
            newComponentOutputProperty("cccs_iout", "i(@tag)", "", true, false),
        ],
        contactPoints: points,
        allowance: "",
    };
}

/** Hxx */
export function newCCVS(tag: string, points: string[], vref: string, gain = "1"): AnalysisComponent {
    return {
        name: "CCVS",
        class: "CurrentCtrlSource",
        tag: tag,
        inputProperties: [
            newComponentInputProperty("ccvs_vref", "@tag(vref)", vref),
            newComponentInputProperty("ccvs_gain", "@tag(gain)", gain),
        ],
        outputProperties: [
            newComponentOutputProperty("ccvs_vout", "v(@tag)", "(v(@node[0])-v(@node[1]))"),
            newComponentOutputProperty("ccvs_iout", "i(@tag)", "", true, false),
        ],
        contactPoints: points,
        allowance: "",
    };
}

export function newIdealOA(tag: string, points: string[]): AnalysisComponent {
    return {
        name: "Ideal OpAmp",
        class: "IdealOpAmp",
        tag: tag,
        inputProperties: [],
        outputProperties: [
            newComponentOutputProperty("opamp_inp_voltage", "@tag(vin_pos)", "v(@node[1])"),
            newComponentOutputProperty("opamp_inn_voltage", "@tag(vin_neg)", "v(@node[2])"),
            newComponentOutputProperty("opamp_out_voltage", "@tag(vout)", "v(@node[0])"),
        ],
        contactPoints: points,
        allowance: "",
    };
}

/** Exx */
export function newTF(tag: string, points: string[], tf = "h0*omega0^2/(s^2+(omega0/Q)*s+omega0^2)"): AnalysisComponent {
    return {
        name: "Transfer function",
        class: "TransferFunction",
        tag: tag,
        inputProperties: [
            newComponentInputProperty("tfblock_tf", "@tag(tf)", tf),
        ],
        outputProperties: [
            newComponentOutputProperty("tfblock_vin", "@tag(vin)", "v(@node[1])"),
            newComponentOutputProperty("tfblock_vout", "@tag(vout)", "v(@node[0])"),
            newComponentOutputProperty("tfblock_vgain", "@tag(vgain)", "@tag(vout)/@tag(vin)"),
        ],
        contactPoints: points,
        allowance: "",
    };
}

/** Interactive Shunt */
export function newXInteractiveShunt(tag: string, points: string[], mod = "SUstate1"): AnalysisComponent {
    return {
        name: "Interactive Shunt",
        class: "Subcircuit",
        tag: tag,
        inputProperties: [
            newComponentInputProperty("subcirc_model", "", mod),
        ],
        outputProperties: [
        ],
        contactPoints: points,
        allowance: ".subckt SUstate1 1 2\n.ends SUstate1",
    };
}

/** Interactive Switch */
export function newXInteractiveSwitch(tag: string, points: string[], mod = "SWstate1"): AnalysisComponent {
    return {
        name: "Interactive Switch",
        class: "Subcircuit",
        tag: tag,
        inputProperties: [
            newComponentInputProperty("subcirc_model", "", mod),
        ],
        outputProperties: [
        ],
        contactPoints: points,
        allowance: ".subckt SWstate1 1 2 3\nVisw 1 2 dc 0\n.ends SWstate1",
    };
}

/** SI memory cell */
export function newXSIMem(tag: string, points: string[]): AnalysisComponent {
    return {
        name: "SI memory cell",
        class: "SIMemoryCell",
        tag: tag,
        inputProperties: [
            newComponentInputProperty("subcirc_model", "", "si_cell"),
        ],
        outputProperties: [
        ],
        contactPoints: points,
        allowance: ".subckt @value[0] b a c ph\nEb s 0 a 0 1\nSb s cp ph\nCb cp 0 1\nGb b 0 cp 0 1\nGc c 0 cp 0 0.5\n..ends @value[0]",
    };
}