import { useState } from "react";
import moment from "moment";


import {
    AppBar, Toolbar, Typography, Tab, Tabs, Grid, useTheme, FormControlLabel, Checkbox, FormGroup, Button,
    Dialog, DialogTitle, DialogActions, DialogContent,
} from "@mui/material";
import {
    mdiCloudArrowDown, mdiCloudArrowUp, mdiInformation, mdiKeyboard, mdiNewBox,
    mdiPlay, mdiRedo, mdiUndo, mdiBug,
    mdiFloppy, mdiContentSaveMove,
} from "@mdi/js";
import { LoginButton } from "./User";

import * as lzma from "../services/lzma";

import { useStore } from "./Store";
import LoadProject from "./LoadProject";
import { StyleNav } from "./Style";
import { NavDivider, NavItem, NavSection, showNotification } from ".";

import {
    getCircuitFile, getNewProject, getProjectFromCircuitFile, saveProjectToDB, setProjectHeaders,
} from "../services/project";

import { getCircuitName } from "../services/common";

import packageJson from "../../package.json";
import { AnalysisResultDialog, FileCircuit, ShowSetting } from "../types";
import { handleGeneralAnalysis } from "../services/analysis";
import { AnalysisType } from "../analysis/types";
import { getShowDialog } from "./Dialogs";

export default function TopBar() {
    const theme = useTheme();
    const [tab, setTab] = useState(0);

    return <>
        <AppBar position="static">
            <Toolbar variant="dense">
                <Typography
                    variant="h5"
                    noWrap
                    component="div"
                    fontFamily="monospace"
                    fontWeight={700}
                >
                    GEEC
                </Typography>
                <Typography
                    display="flex"
                    height="24px"
                    alignItems="flex-end"
                    fontSize={10}
                    marginLeft={1}
                    marginRight={2}
                >
                    v{packageJson.version}
                </Typography>
                <Tabs
                    value={tab}
                    onChange={(_, nav) => setTab(nav)}
                    indicatorColor="secondary"
                    color="secondary"
                    textColor="inherit"
                    sx={{ flexGrow: 1 }}
                >
                    <Tab label="General" />
                    <Tab label="Analysis" />
                    <Tab label="Tools" />
                    <Tab label="Styles" />
                    <Tab label="Alternatives" />
                    <Tab label="Help" />
                </Tabs>
                <LoginButton />
            </Toolbar>
        </AppBar>
        <Grid container sx={{ padding: theme.spacing(1), bgcolor: theme.palette.secondary.main }}>
            {tab === 0 && <GeneralNav />}
            {tab === 1 && <AnalysisNav />}
            {tab === 2 && <>Tools...</>}
            {tab === 3 && <StyleNav />}
            {tab === 4 && <>Alternatives...</>}
            {tab === 5 && <HelpNav />}
        </Grid>
    </>;
}

function GeneralNav() {
    const { store, setStore } = useStore();
    const { user, project, history } = store;

    const [loadFromServer, setLoadFromServer] = useState(false);

    function setShow(show: Partial<ShowSetting>) {
        setStore(s => ({
            ...s,
            project: { ...s.project, show: { ...s.project.show, ...show } },
            historyAction: "record",
        }));
    }

    async function saveToServer() {
        const newProject = await saveProjectToDB(store.project, user);
        setStore({ ...store, project: newProject, historyAction: "record" });
    }

    async function saveToFile() {
        if (project.type !== "circuit") {
            showNotification("Only circuit can by saved as a file!", "warning");
            return;
        }

        showNotification("Preparing file to save...", "info");
        const fileName = `${getCircuitName(project.title)} ${moment().format("YYYY-MM-DD")}.geec`;
        const circuitToSave = getCircuitFile(project);
        const circuitString = JSON.stringify(circuitToSave);

        const fileArray = await lzma.compress(circuitString, 1);
        const file = new Uint8Array(fileArray);

        const blob = new Blob([file], { type: "application/x-lzma" });
        const url = window.URL.createObjectURL(blob);

        const element = document.createElement("a");
        element.setAttribute("href", url);
        element.setAttribute("download", fileName);
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);

        showNotification("File saved.", "success");
    }

    async function loadFromFile() {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = ".json,.geec"; // Allow only .json and .geec files

        input.addEventListener("change", (event) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const file: File = (event as any).target.files[0];

            if (file) {
                const reader = new FileReader();
                reader.onload = async (e) => {
                    let file = e.target?.result;
                    if (!file) {
                        showNotification("File is not found.", "error");
                        return;
                    }

                    if (typeof file === "object") {
                        try {
                            const fileUint8Array = new Uint8Array(file);
                            file = await lzma.decompress(fileUint8Array);
                        } catch (e) {
                            showNotification("The file format is damaged.", "error");
                            return;
                        }
                    }

                    if (typeof file === "string") {
                        let circuit: null | FileCircuit = null;
                        try {
                            circuit = JSON.parse(file);
                        } catch (e) {
                            // check it bellow
                        }

                        if (!circuit) {
                            showNotification("File is in wrong format.", "error");
                            return;
                        }

                        const project = getProjectFromCircuitFile(circuit);
                        setProjectHeaders(project);

                        setStore(store => ({
                            ...store,
                            project,
                            historyAction: "record",
                        }));

                    } else {
                        showNotification("File is in wrong format.", "error");
                    }
                };

                if (file.name.endsWith(".geec")) {
                    reader.readAsArrayBuffer(file); // Read .geec as ArrayBuffer
                } else if (file.name.endsWith(".json")) {
                    reader.readAsText(file); // Read .json as text
                } else {
                    showNotification("File is in wrong format.", "error");
                }
            }
        });

        input.click();
    }

    return <>

        <LoadProject open={loadFromServer} onClose={() => setLoadFromServer(false)} />

        <NavSection title="Project">
            {/* <NavItem label="New component" icon={mdiNewBox}
                onClick={() => setStore(getNewProject("component", store))} /> */}
            <NavItem label="New circuit" icon={mdiNewBox}
                onClick={() => setStore(getNewProject("circuit", store))} />
        </NavSection>
        <NavDivider />
        <NavSection title={`History ${history.index + 1}/${history.projects.length}`} >
            <NavItem label="Back" icon={mdiUndo}
                disabled={history.index >= history.projects.length - 1}
                onClick={() => setStore(s => ({ ...s, historyAction: "back" }))} />
            <NavItem label="Front" icon={mdiRedo}
                disabled={history.index === 0}
                onClick={() => setStore(s => ({ ...s, historyAction: "front" }))} />
        </NavSection >
        <NavDivider />
        <NavSection title="File">
            <NavItem label="Save" icon={mdiFloppy} onClick={saveToFile} />
            <NavItem label="Load" icon={mdiContentSaveMove} onClick={loadFromFile} />
        </NavSection>
        <NavDivider />
        <NavSection title="Server">
            {user.isAdmin && <NavItem
                label="Save to server" icon={mdiCloudArrowUp} onClick={saveToServer}
            />}
            <NavItem label="Load from server" icon={mdiCloudArrowDown} onClick={() => setLoadFromServer(true)} />
        </NavSection>
        {/* <NavDivider />
        <NavSection title="Export">
            <NavItem label="NetList for Spice" icon={mdiNumeric10Box} onClick={() => alert("Not implemented...")} />
            {user.isLogged && <NavItem
                label="NetList for PraCAn" icon={mdiMathIntegralBox} onClick={() => alert("Not implemented...")}
            />}

            <NavItem label="PostScript" icon={mdiScriptTextOutline} onClick={() => alert("Not implemented...")} />
            <NavItem label="PDF" icon={mdiFilePdfBox} onClick={() => alert("Not implemented...")} />
            <NavItem label="LaTeX" icon={mdiTextBoxMultipleOutline} onClick={() => alert("Not implemented...")} />
        </NavSection> */}
        <NavDivider />
        <NavSection title="Show" bottomNavigation={false}>
            <FormGroup sx={{ flexWrap: "nowrap", marginTop: "-8px" }}>
                <FormControlLabel
                    label="Tags"
                    control={<Checkbox
                        sx={{ padding: "0 4px", marginLeft: "8px" }}
                        size="small"
                        checked={store.project.show.tag}
                        onChange={() => setShow({ tag: !store.project.show.tag })}
                    />} />
                <FormControlLabel
                    label="Values"
                    control={<Checkbox
                        sx={{ padding: "0 4px", marginLeft: "8px" }}
                        size="small"
                        checked={store.project.show.value}
                        onChange={() => setShow({ value: !store.project.show.value })}
                    />} />
                <FormControlLabel
                    label="Current directions"
                    control={<Checkbox
                        sx={{ padding: "0 4px", marginLeft: "8px" }}
                        size="small"
                        checked={store.project.show.currentArrow}
                        onChange={() => setShow({ currentArrow: !store.project.show.currentArrow })}
                    />} />
            </FormGroup>
        </NavSection>
    </>;
}

function AnalysisNav() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { store, setStore } = useStore();

    async function handleAnalysis(type: AnalysisType) {
        const analysis = await handleGeneralAnalysis(store, type);
        const analysisResultDialog: AnalysisResultDialog = { dialog: getShowDialog(), analysis };

        setStore(store => ({
            ...store,
            action: {
                ...store.action,
                analysisResults: [...store.action.analysisResults, analysisResultDialog],
            },
        }));
    }

    return <>
        <NavSection title="Numeric">
            {/* <NavItem label="ngspice (client)" icon={mdiPlay} onClick={handleNgSpice} /> */}
            <NavItem label="OP" icon={mdiPlay} onClick={() => handleAnalysis("NGSpiceOP")} />
            <NavItem label="AC" icon={mdiPlay} onClick={() => handleAnalysis("NGSpiceAC")} />
        </NavSection>
        <NavDivider />
        <NavSection title="Symbolic">
            <NavItem label="OP Symbolic" icon={mdiPlay} onClick={() => handleAnalysis("PracanOPSym")} />
            <NavItem label="OP Semisymbolic" icon={mdiPlay} onClick={() => handleAnalysis("PracanOPSemi")} />
            <NavItem label="AC Symbolic" icon={mdiPlay} onClick={() => handleAnalysis("PracanACSym")} />
            <NavItem label="TF Symbolic" icon={mdiPlay} onClick={() => handleAnalysis("PracanTFSym")} />
        </NavSection>
        <NavDivider />
        <NavSection title="Symcirc">
            <NavItem label="OP Symbolic" icon={mdiPlay} onClick={() => handleAnalysis("SymcircOPSym")} />
            <NavItem label="OP Semisymbolic" icon={mdiPlay} onClick={() => handleAnalysis("SymcircOPSemi")} />
            <NavItem label="AC Symbolic" icon={mdiPlay} onClick={() => handleAnalysis("SymcircACSym")} />
            <NavItem label="TF Symbolic" icon={mdiPlay} onClick={() => handleAnalysis("SymcircTFSym")} />
        </NavSection>
    </>;
}

function HelpNav() {
    const { store, setStore } = useStore();

    const [showAbout, setShowAbout] = useState(false);

    function handleReportBug() {
        const element = document.createElement("a");
        element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(JSON.stringify(store)));
        element.setAttribute("download", `geec-bug-${moment().format("YYYY-MM-DDTHH:mm")}.json`);
        element.style.display = "none";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    return <>
        <NavSection title="Help">

            <NavItem label="About" icon={mdiInformation} onClick={() => setShowAbout(true)} />
            <Dialog open={showAbout} onClose={() => setShowAbout(false)}>
                <DialogTitle>Graphic editor of electrical circuits (GEEC) v{packageJson.version}</DialogTitle>
                <DialogContent>
                    <p>{`GEEC is a graphical editor of electrical circuits that allows not only the creation of 
                    schematics but also their interconnection with Maple and ngspice simulators for both symbolic 
                    and numerical analysis. The editor also enables the export of the vector model of the circuit 
                    into various graphic formats, including formats suitable for LaTeX.`}</p>
                    <Typography variant="h6" component="h3" >Project History</Typography>
                    <p>{`The roots of the project date back to 2013 when Filip Paulů started working on the editor 
                    as part of his bachelor's thesis. His work continued during his master's studies, when 
                    Ondřej Bukovský joined the project and focused on the analysis part. His work also resulted 
                    in a master's thesis. The entire development of the project was led and supervised by
                    `} <b>doc. Dr. Ing. Jiří Hospodka</b>.</p>
                    <p>{`GEEC is the result of many years of effort and collaboration aimed at 
                    creating a tool 
                    that will facilitate the work of electrical engineers and students.`}</p>
                    <p style={{ textAlign: "right", fontStyle: "italic" }}>By Filip Paulů & Ondřej Bukovský</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowAbout(false)}>Close</Button>
                </DialogActions>
            </Dialog>

            <NavItem label="Control" icon={mdiKeyboard} onClick={() => setStore(s => ({
                ...s,
                action: {
                    ...s.action,
                    help: { show: true, type: "classic" },
                },
            }))} />

            <NavItem label="Bug" icon={mdiBug} onClick={handleReportBug} />
        </NavSection>
    </>;
}
