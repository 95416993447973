/* eslint-disable @typescript-eslint/no-unused-vars */
import { AnalysisHandler } from "../core/analysisHandler";
import { mapComputedResults } from "../core/core_draft";
import { sendSymcircRequest } from "../settings";
import { getSpiceGenerator, spiceGetNetlist, SpiceNetlistGenerator } from "../spice/netlist";
import { ServerResponse } from "../types";
import {
    AnalysisCircuit, AnalysisComponent, AnalysisConfig, AnalysisType,
    BasicAnalysisType, ComponentOutputProperty, Result, ResultFormat, SimResultExpression,
} from "../types/analysis";
import { isExpression } from "../utils/expressions";
import { getContactPoints, mangleNode, renderComponentProperty } from "../utils/utilities";
import { symcircAdvGenerateScript, symcircAdvGetFinalResults } from "./symcircAdvanced";
import { SymcircAnalysisType } from "./types";

type SimulationCMD = "DC" | "AC" | "tran" | "TF";

export interface SymcircAnalysisHandler extends AnalysisHandler {
    getOutputDict: (sett: AnalysisConfig, reqRslt: SimResultExpression[]) => string;
}

function symcircDefaultHandler(): SymcircAnalysisHandler {
    return {
        getFinalResults: mapComputedResults,
        getDefaultResults: () => [],
        getAvailableResults: symcircGetDefaultResults,
        generateScript: symcircGenerateScript,
        parseData: symcircParseData,
        parseErrors: symcircParseErrors,
        sendRequest: sendSymcircRequest,
        getOutputDict: symcircGetOutputDict,
    };
}

export function symcircDCHandler(): SymcircAnalysisHandler {
    return {
        ...symcircDefaultHandler(),
    };
}

export function symcircACHandler(): SymcircAnalysisHandler {
    return {
        ...symcircDefaultHandler(),
    };
}

export function symcircTFHandler(): SymcircAnalysisHandler {
    return {
        ...symcircDefaultHandler(),
    };
}

export function symcircTransientHandler(): SymcircAnalysisHandler {
    return {
        ...symcircDefaultHandler(),
    };
}

export function symcircAdvHandler(): SymcircAnalysisHandler {
    return {
        ...symcircDefaultHandler(),
        generateScript: symcircAdvGenerateScript,
        getDefaultResults: () => [],
        getAvailableResults: () => [],
        getFinalResults: symcircAdvGetFinalResults,
    };
}

export function symcircGetAnalysisHandler(analysisType: SymcircAnalysisType): SymcircAnalysisHandler {
    switch (analysisType) {
        case "SymcircOPSym":
        case "SymcircOPSemi":
            return symcircDefaultHandler();
        case "SymcircACSym":
        case "SymcircACSemi":
        case "SymcircACGraph":
            return symcircACHandler();
        case "SymcircTranSym":
        case "SymcircTranSemi":
        case "SymcircTranGraph":
            return symcircTransientHandler();
        case "SymcircTFSym":
        case "SymcircTFSemi":
        case "SymcircTFGraph":
            return symcircTFHandler();
        case "SymcircAdvanced":
            return symcircAdvHandler();
        default:
            throw new Error(`AnalysisHandler for ${analysisType} not implemented`);
    }
}

function symcircGetSimulationType(analysisType: SymcircAnalysisType): SimulationCMD {
    switch (analysisType) {
        case "SymcircOPSym":
        case "SymcircOPSemi":
            return "DC";
        case "SymcircACSym":
        case "SymcircACSemi":
        case "SymcircACGraph":
            return "AC";
        case "SymcircTranSym":
        case "SymcircTranSemi":
        case "SymcircTranGraph":
            return "tran";
        case "SymcircTFSym":
        case "SymcircTFSemi":
        case "SymcircTFGraph":
            return "TF";
        default:
            throw new Error(`Conversion Analysis type ${analysisType} to SimulationType not supported`);
    }
}

function symcircGetSymbolicOption(analysisType: SymcircAnalysisType): "True" | "False" {
    switch (analysisType) {
        case "SymcircOPSym":
        case "SymcircACSym":
        case "SymcircTranSym":
        case "SymcircTFSym":
            return "True";
        case "SymcircACSemi":
        case "SymcircACGraph":
        case "SymcircOPSemi":
        case "SymcircTranSemi":
        case "SymcircTranGraph":
        case "SymcircTFSemi":
        case "SymcircTFGraph":
        default:
            return "False";
    }
}

function symcircGetAnalysisBasicType(analysisType: SymcircAnalysisType): BasicAnalysisType {
    switch (analysisType) {
        case "SymcircTranSym":
        case "SymcircTranSemi":
        case "SymcircTranGraph":
            return "tran";
        case "SymcircACSym":
        case "SymcircACSemi":
        case "SymcircACGraph":
        case "SymcircTFSym":
        case "SymcircTFSemi":
        case "SymcircTFGraph":
            return "ac";
        case "SymcircOPSym":
        case "SymcircOPSemi":
        default:
            return "dc";
    }
}

function symcircGetOutputDict(sett: AnalysisConfig, reqRslt: SimResultExpression[]): string {
    if(reqRslt.length !== 0){
        let out = "output_dict = {\n";
        const rsltAssign = reqRslt.map(r => `\t"result_${r.id}": parse_expression("${r.expr}", results)`);
        out += rsltAssign.join(",\n");
        out += "\n}\n";
        return out;
    }else {
        return "output_dict = {}\n";
    }
}

function symcircGenerateScript(
    proj: AnalysisCircuit, simSettings: AnalysisConfig, reqRslt: SimResultExpression[]): string {

    const analysisType: SymcircAnalysisType = simSettings.analysisType as SymcircAnalysisType;

    const analysisHandler: SymcircAnalysisHandler = symcircGetAnalysisHandler(analysisType);
    const simType: SimulationCMD = symcircGetSimulationType(analysisType);
    const isSymbolic = symcircGetSymbolicOption(analysisType);

    return `# ${proj.title}
import sys
import json
import re

from symcirc import *

from importlib.metadata import version
sys.stderr.write(f"Current version of Symcirc: {version('symcirc')}\\n")

def parse_expression(expr, result_dict):
    tmp = {
        "v": lambda x: result_dict[f"v({x})"],
        "i": lambda x: result_dict[f"i({x})"]
    }
    tmp.update(result_dict)
    tmp.update(sympy.abc._clash)
    return sympy.parse_expr(expr, local_dict=tmp)

netlist = """
${getSymcircNetlist(proj)}
"""
circuit = AnalyseCircuit(netlist, "${simType}", symbolic=${isSymbolic}, method="tableau")
results = circuit.node_voltages()
results.update(circuit.component_values("all"))

${analysisHandler.getOutputDict(simSettings, reqRslt)}

final_results = to_latex(output_dict)

${symcircRemoveSubscript(true)}


# TODO: use file not stdout!!
print(json.dumps(final_results))
`;
}

export function symcircRemoveSubscript(remove: boolean): string {
    if(remove){
        let script = "";
        script += "for key in final_results:\n";
        script += "\tfinal_results[key] = re.sub(r'(\\w*)_{(\\d+)}', r'\\1\\2', final_results[key])\n";

        return script;
    } else {
        return "";
    }
}

export function getSymcircNetlist(proj: AnalysisCircuit): string {
    const defaultGenerator: SpiceNetlistGenerator = getSpiceGenerator();

    const pracanGenerator: SpiceNetlistGenerator = {
        ...defaultGenerator,
        transferFunction: defaultGenerator.default,
    };

    return spiceGetNetlist(proj, pracanGenerator);
}

function symcircGetNodeVoltages(sett: AnalysisCircuit): SimResultExpression[] {
    return getContactPoints(sett).map(n => {
        return {
            id: -1,
            name: `v(${n})`,
            expr: `v(${mangleNode(n)})`,
        };
    });
}

function symcircGetDefaultResults(sett: AnalysisCircuit, analysisType: AnalysisType): SimResultExpression[] {
    const defaultResults: SimResultExpression[] = symcircGetNodeVoltages(sett);

    sett.components.forEach((component: AnalysisComponent) => {
        const basicType = symcircGetAnalysisBasicType(analysisType as SymcircAnalysisType);
        const outputProperties = symcircGetComponentOutputProperties(component, basicType);
        outputProperties.forEach((outProp: ComponentOutputProperty) => {
            const renderedOutput = {
                id: -1,
                name: renderComponentProperty(component, outProp.name),
                expr: renderComponentProperty(component, outProp.expr),
            };

            const finalResultNames = defaultResults.map(r => r.name);

            // check autogenerated "v(0)" node
            renderedOutput.expr = renderedOutput.expr.replaceAll("v(0)", "0");


            if (isExpression(renderedOutput.expr) ||
                finalResultNames.some(name => renderedOutput.expr.includes(name))) {
                defaultResults.forEach((def: SimResultExpression) => {
                    renderedOutput.expr = renderedOutput.expr.replaceAll(def.name, def.expr);
                });
            }

            defaultResults.push(renderedOutput);
        });
    });

    return defaultResults;
    // [{ id: -1, name: "i(r1)", expr: "@r1[i]" },
    //{ id: -1, name: "v(1)", expr: "v(1)" }];//, { id: 2, name: "p(r1)", expr: "v(1)*i(r1)" }
}


export function symcircParseData(data: string): Result[] {
    const finalData: Result[] = [];

    if(data === ""){
        // If the data is empty, return an empty array (otherwise JSON.parse will throw an error)
        return finalData;
    }

    const parsed = JSON.parse(data);
    for (const key in parsed) {
        // match float form (otherwise latex)
        const format: ResultFormat = parsed[key].match(/^-*\d+.\d+$/)? "Float": "Latex";

        finalData.push({
            id: -1,
            name: key,
            value: parsed[key],
            valueType: "Real",
            dataType: "Value",
            format: format,
        });
    }
    return finalData;
}

function symcircParseErrors(result: ServerResponse): string {
    // TODO: implement more
    const match = result.stderr?.match(/\n(.*error:.*)\n/gi);
    if(match && match[0]){
        return match[0].trim();
    }

    return "";
}

export function symcircGetComponentOutputProperties(
    component: AnalysisComponent, type: BasicAnalysisType): ComponentOutputProperty[] {
    // reduce is map and filter function in one
    return component.outputProperties.reduce<ComponentOutputProperty[]>(
        (acc: ComponentOutputProperty[], outProp: ComponentOutputProperty) => {

            // If the analysis is not allowed, do not add the output property
            if (outProp.allowedAnalysis[type] === false) {
                return acc;
            }

            // If the expression is already set, do not change it
            if (outProp.expr !== "") {
                acc.push({ ...outProp });
                return acc;
            }

            let expr = "";
            switch (outProp.id) {
                case "r_voltage":
                case "l_voltage":
                case "c_voltage":
                case "v_voltage":
                case "i_voltage":
                {
                    expr = "v(@tag)";
                    break;
                }
                case "r_power":
                case "l_power":
                case "c_power":
                case "v_power":
                case "i_power":
                {
                    expr = "v(@tag)*i(@tag)";
                    break;
                }
                case "bjt_power":
                case "diode_power":
                {
                    // expr = "v(@tag)*i(@tag)";
                    // break;
                    // TODO: not supported yet
                    return acc;
                }
                case "r_current":
                case "v_current":
                case "c_current":
                case "l_current":
                {
                    expr = "i(@tag)";
                    break;
                }

                default: expr = outProp.expr;
            }
            acc.push({...outProp, expr: expr });

            return acc;
        }, []);
}