import { useRef, CSSProperties, useEffect } from "react";
import useResizeObserver from "use-resize-observer";

import { mdiTarget } from "@mdi/js";
import { Box } from "@mui/material";

import {
    clearCanvas, drawCircuitPoints, drawGrid, drawList, drawPoints, drawNodeNames, getCanvasCenter, setCanvasSize,
} from "../services/canvas";
import {
    getCursorPosition, getDragAreaPosition, handleMouseDown,
    handleMouseMove, handleMouseUp,
    handleMouseWheel,
} from "../services/mouse";

import { Icon } from ".";
import { useStore } from "./Store";
import { getSelectedList } from "../services/list";

import { Point } from "../types";

const styleFullScreen: CSSProperties = {
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100%",
};

export default function Canvas() {
    const { store, setStore } = useStore();
    const { project, action } = store;
    const canvas = project.canvas;

    const { ref, width = 200, height = 200 } = useResizeObserver<HTMLDivElement>({ round: Math.floor });

    const canvasGridRef = useRef<HTMLCanvasElement>(null);
    const canvasContentRef = useRef<HTMLCanvasElement>(null);

    function scalePoints(points: Point[]): Point[] {
        if (!canvasContentRef.current) return points;

        const { zoom, shift } = canvas;
        const center = getCanvasCenter(canvasContentRef.current);

        return points.map(point => ({
            x: (point.x + shift.x) * zoom + center.x,
            y: (point.y + shift.y) * zoom + center.y,
        }));
    }

    function handleDraw() {
        if (canvasGridRef.current)
            drawGrid(canvasGridRef.current, canvas);

        if (canvasContentRef.current) {
            const scaledPoints = scalePoints(project.points);
            clearCanvas(canvasContentRef.current);
            drawList(canvasContentRef.current, project.list, scaledPoints, canvas.zoom, project.show);
            drawCircuitPoints(canvasContentRef.current, project.list, scaledPoints, canvas.zoom);
            drawNodeNames(canvasContentRef.current, project.list, scaledPoints, canvas.zoom);

            if (action.drawing) {
                const { item, points } = action.drawing;
                const scaledPoints = scalePoints(points);
                drawList(canvasContentRef.current, [item], scaledPoints, canvas.zoom, project.show);
                drawPoints(canvasContentRef.current, scaledPoints.slice(-1), canvas.zoom);
            }

            if (action.paste) {
                const { list, points } = action.paste;
                const scaledPoints = scalePoints(points);
                drawList(canvasContentRef.current, list, scaledPoints, canvas.zoom, project.show);
                drawCircuitPoints(canvasContentRef.current, list, scaledPoints, canvas.zoom);
            }
        }
    }

    // resize canvas
    useEffect(() => {
        if (canvasGridRef.current)
            setCanvasSize(canvasGridRef.current, width, height);
        if (canvasContentRef.current)
            setCanvasSize(canvasContentRef.current, width, height);
        handleDraw();
    }, [width, height]);

    // draw on init and change
    useEffect(() => {
        handleDraw();
    }, [project.list, project.points, project.show, action.drawing, action.paste, canvas]);

    const cursorPosition = getCursorPosition(action.mouse, canvasContentRef.current, canvas, 24);
    // when is drag with selected item, it is moving without drag area
    const selectedList = getSelectedList(project.list);
    const dragAreaPosition = selectedList.length === 0 ?
        getDragAreaPosition(action.mouse, canvasContentRef.current, canvas) : null;


    return <div ref={ref} style={{ ...styleFullScreen, zIndex: 5, overflow: "hidden" }}>
        <Box sx={{ position: "absolute", bottom: 8, left: 8, color: "text.secondary" }}>
            {Object.values(action.keyboard).filter(v => Boolean(v)).length > 0 && <Box>
                {"Keys: "}
                {action.keyboard.ctrl && "Ctrl + "}
                {action.keyboard.cmd && "CMD + "}
                {action.keyboard.alt && "Alt + "}
                {action.keyboard.shift && "Shift + "}
                {action.keyboard.key && action.keyboard.key}
            </Box>}
            <Box>
                Mouse: {action.mouse.x}, {action.mouse.y}
                {action.mouse.mouseDown && <div style={{
                    display: "inline-block", flexDirection: "row", paddingLeft: "8px",
                }}>
                    <div style={{
                        display: "inline-block", margin: "0px 2px",
                        width: 8, height: 8, border: "1px solid #888", borderRadius: "4px",
                        backgroundColor: action.mouse.mouseDown.button === "left" ? "#888" : "transparent",
                    }} />
                    <div style={{
                        display: "inline-block", margin: "0px 2px",
                        width: 8, height: 8, border: "1px solid #888", borderRadius: "4px",
                        backgroundColor: action.mouse.mouseDown.button === "middle" ? "#888" : "transparent",
                    }} />
                    <div style={{
                        display: "inline-block", margin: "0px 2px",
                        width: 8, height: 8, border: "1px solid #888", borderRadius: "4px",
                        backgroundColor: action.mouse.mouseDown.button === "right" ? "#888" : "transparent",
                    }} />
                </div>}
                {action.mouse.isDrag && " (drag)"}
            </Box>
        </Box>
        {dragAreaPosition && <div
            style={{
                position: "absolute",
                ...dragAreaPosition,
                border: "2px dashed #888",
                boxSizing: "border-box",
                backgroundColor: "rgba(106, 173, 228, 0.1)",
            }}
        />}
        <div style={{
            position: "absolute", width: "24px", height: "24px",
            ...cursorPosition,
        }}>
            <Icon path={mdiTarget} size={1} />
        </div>
        <canvas ref={canvasGridRef} style={{ ...styleFullScreen, zIndex: 4 }} />
        <canvas
            ref={canvasContentRef}
            style={{ ...styleFullScreen, zIndex: 5 }}

            onContextMenu={e => e.preventDefault()}

            onMouseMove={e => setStore(s => handleMouseMove(e, s, canvasContentRef.current))}
            onMouseDown={e => setStore(s => handleMouseDown(e, s))}
            onMouseUp={e => setStore(s => handleMouseUp(e, s))}

            onWheel={e => setStore(s => handleMouseWheel(e, s, canvasContentRef.current))}

            onMouseEnter={() => setStore(s => ({ ...s, action: { ...s.action, activeCanvas: true } }))}
            onMouseLeave={() => setStore(s => ({ ...s, action: { ...s.action, activeCanvas: false } }))}
        />
    </div>;
}
