import { Result } from "../types";
import { ComplexValue } from "../types/analysis";
import { getReadableNumber } from "./expressions";

export function getRealFloatValue(id: number, name: string, value: string): Result {
    return { id: id, name: name, value: value, valueType: "Real", dataType: "Value", format: "Float" };
}

export function getRealExpValue(id: number, name: string, value: string): Result {
    return { id: id, name: name, value: value, valueType: "Real", dataType: "Value", format: "Exp" };
}

export function getRealSIValue(id: number, name: string, value: string): Result {
    return { id: id, name: name, value: value, valueType: "Real", dataType: "Value", format: "SI" };
}

export function getRealLatexValue(id: number, name: string, value: string): Result {
    return { id: id, name: name, value: value, valueType: "Real", dataType: "Value", format: "Latex" };
}

export function getReImExpValue(id: number, name: string, value: ComplexValue): Result {
    return { id: id, name: name, value: {...value}, valueType: "ReIm", dataType: "Value", format: "Exp" };
}

export function getReImSIValue(id: number, name: string, value: ComplexValue): Result {
    return { id: id, name: name, value: {...value}, valueType: "ReIm", dataType: "Value", format: "SI" };
}

export function getMagPhSIValue(id: number, name: string, value: ComplexValue): Result {
    return { id: id, name: name, value: {...value}, valueType: "ReIm", dataType: "Value", format: "SI" };
}

export function resultsToSI(rslts: Result[]): Result[] {
    return rslts.map(r => {
        switch (r.dataType) {
            case "Value":
                return resultValueToSI(r);
            case "Array":
            default:
                // TODO: implement
                throw new Error(`resultToSI: convert of ${r.dataType} not implemented.`);
        }
    });
}

export function resultsReImToMagPh(rslts: Result[]): Result[] {
    return rslts.map(r => {
        switch (r.dataType) {
            case "Value":
                return resultValueReImToMagPh(r);
            case "Array":
            default:
                // TODO: implement
                throw new Error(`resultToSI: convert of ${r.dataType} not implemented.`);
        }
    });
}

export function resultValueReImToMagPh(rslt: Result): Result {
    // sanity check
    if (rslt.valueType !== "ReIm") {
        throw new Error(`resultValueReImToMagPh: valueType ${rslt.valueType} is not ReIm.`);
    }

    switch (rslt.format) {
        case "Exp":
        case "Float":
        {
            const re = parseFloat((rslt.value as ComplexValue).value1);
            const im = parseFloat((rslt.value as ComplexValue).value2);
            const mag = Math.sqrt(re * re + im * im);
            const ph = Math.atan2(im, re);
    
            return {
                ...rslt,
                value: {
                    value1: (rslt.format === "Float") ? mag.toString(): mag.toExponential(),
                    value2: (rslt.format === "Float") ? ph.toString(): ph.toExponential(),
                },
            };
        }
        case "SI":
        default:
            throw new Error(`resultValueReImToMagPh: convert of ${rslt.format} not implemented.`);
    }
}

export function resultValueToSI(rslt: Result): Result {
    switch (rslt.valueType) {
        case "Real":
            return resultRealValueToSI(rslt);
        case "ReIm":
        case "MagPh":
            return resultComplexValueToSI(rslt);
        default:
            throw new Error(`resultValueToSI: convert of ${rslt.valueType} not implemented.`);
    }
}

function resultComplexValueToSI(rslt: Result): Result {
    switch (rslt.format) {
        case "Exp":
        case "Float":
        {
            const cmplxVal = rslt.value as ComplexValue;
            const value1 = getReadableNumber(parseFloat(cmplxVal.value1));
            const value2 = getReadableNumber(parseFloat(cmplxVal.value2));
            return getReImSIValue(rslt.id, rslt.name, {value1: value1, value2: value2});
        }
        case "SI":
            return getReImSIValue(rslt.id, rslt.name, rslt.value as ComplexValue);
        default:
            throw new Error(`resultReImValueToSI: convert of ${rslt.format} not implemented.`);
    }
}

/** Converts Result to "SI" format (only "Float" and "Exp" is supported) */
function resultRealValueToSI(rslt: Result): Result {
    switch (rslt.format) {
        case "Exp":
        case "Float":
            return getRealSIValue(rslt.id, rslt.name, getReadableNumber(parseFloat(rslt.value as string)));
        case "SI":
            return {...rslt};
        default:
            throw new Error(`resultRealValueToSI: convert of ${rslt.format} not implemented.`);
    }
}
