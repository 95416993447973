// Updated form source: https://github.com/LZMA-JS/LZMA-JS/blob/master/src/lzma.js

type Finish = (result: null | string | Uint8Array, error: Error) => void;
type Progress = (progress: number) => void;


type CB = {
    on_finish: Finish,
    on_progress: Progress,
}

// const lzma_worker = new Worker("/lzma_worker-min.js");
const lzma_worker = new Worker("/lzma_worker.js");

const callback_obj: Record<number, CB> = {};

const action_compress = 1;
const action_decompress = 2;
const action_progress = 3;

lzma_worker.onmessage = function onmessage(e) {
    if (e.data.action === action_progress) {
        if (callback_obj[e.data.cbn] && typeof callback_obj[e.data.cbn].on_progress === "function") {
            callback_obj[e.data.cbn].on_progress(e.data.result);
        }
    } else {
        if (callback_obj[e.data.cbn] && typeof callback_obj[e.data.cbn].on_finish === "function") {
            callback_obj[e.data.cbn].on_finish(e.data.result, e.data.error);

            /// Since the (de)compression is complete, the callbacks are no longer needed.
            delete callback_obj[e.data.cbn];
        }
    }
};

/// Very simple error handling.
lzma_worker.onerror = function (event) {
    const err = new Error(event.message + " (" + event.filename + ":" + event.lineno + ")");

    for (const cbn in callback_obj) {
        callback_obj[cbn].on_finish(null, err);
    }

    console.error("Uncaught error in lzma_worker", err);
};


function send_to_worker(
    action: number, data: Uint8Array | string, mode: false | number,
    on_finish: Finish, on_progress: Progress,
) {
    let cbn = 1;

    do {
        cbn = Math.floor(Math.random() * (10000000));
    } while (typeof callback_obj[cbn] !== "undefined");

    callback_obj[cbn] = {
        on_finish: on_finish,
        on_progress: on_progress,
    };

    lzma_worker.postMessage({
        action: action,
        cbn: cbn,    /// callback number
        data: data,
        mode: mode,
    });
}

export async function compress(data: string, mode: number): Promise<Uint8Array> {
    return new Promise((resolve, reject) => {
        send_to_worker(action_compress, data, mode, (result, error) => {
            if (error || result === null) {
                reject(error);
            } else {
                resolve(new Uint8Array(result as Uint8Array));
            }

        }, () => {
            // TODO: progress
        });
    });
}

export async function decompress(data: Uint8Array): Promise<string> {
    return new Promise((resolve, reject) => {
        send_to_worker(action_decompress, data, false, (result, error) => {
            if (error || result === null) {
                reject(error);
            } else {
                resolve(result as string);
            }
        }, () => {
            // TODO: progress
        });
    });
}