import { uniq } from "lodash";
import { getAllPointsFromItem, isItemAtPoint, isItemInArea, getCopyItem, isComponentItem } from "./item";

import { Area, ComponentItem, WireItem, ContactPointItem, List, ListItem, Point, Store, GenericList } from "../types";

export function mapList(list: List, cb: (item: ListItem) => ListItem) {
    return list.map(item => {
        if (item.name === "Component") {
            cb(item.tag);
            cb(item.value);
            item = { ...item, list: mapList(item.list, cb) } as ListItem;
        }
        // TODO: contact point name

        return cb(item);
    });
}

export function getUpdatedItem<T extends ListItem>(store: Store, item: T, newItem: T): Store {
    return {
        ...store,
        project: {
            ...store.project,
            list: store.project.list.map(i => i === item ? newItem : i),
        },
    };
}

export function getItemAtPoint(list: List, points: Point[], point: Point) {
    for (const item of list) {
        if (item.name === "Component") {
            if (isItemAtPoint(item.tag, points, point)) return item.tag;
            if (isItemAtPoint(item.value, points, point)) return item.value;
        }
        if (item.name === "ContactPoint") {
            // TODO: solve set select text for contact point
        }
        if (isItemAtPoint(item, points, point)) return item;
    }

    return null;
}

export function getItemsAtPoint(list: List, points: Point[], point: Point) {
    const items: List = [];
    for (const item of list) {
        if (isItemAtPoint(item, points, point)) items.push(item);
    }

    return items;
}

export function getItemsInArea(list: List, points: Point[], area: Area): List {
    return list.filter(item => isItemInArea(item, points, area));
}

export function getSelectedList<T extends GenericList>(list: T): T {
    const selectedList = [] as unknown as T;
    for (const item of list) {
        if (item.selected) selectedList.push(item);
        if (item.list) {
            selectedList.push(...getSelectedList(item.list));
        }
        if (isComponentItem(item)) {
            if (item.tag.selected) selectedList.push(item.tag);
            if (item.value.selected) selectedList.push(item.value);
        }
        // TODO: select text for contact point
        // if (isContactPointItem(item)) {
        //     if (item.) selectedList.push(item.nameObject);
        // }
    }

    return selectedList;
}

export function getDeselectedList(list: List): List {
    return list.map(item => {
        const { selected, ...rest } = item;
        if (isComponentItem(rest)) {
            rest.tag.selected = undefined;
            rest.value.selected = undefined;
        }
        // TODO: deselect contact point name
        return rest;
    });
}

export function removeFromList(list: List, items: List) {
    return list.filter(i => items.includes(i) === false);
}

export function getAllPointsFromList(list: List, points: Point[]) {
    const allPoints: Point[] = [];
    for (const item of list) {
        allPoints.push(...getAllPointsFromItem(item, points));
    }

    return uniq(allPoints);
}
export function getMainPoint(list: List, points: Point[]): Point {
    const cps = getContactPointsFromList(list);
    if (cps.length > 0)
        return points[cps[0].point];

    const ps = getAllPointsFromList(list, points);
    if (ps.length > 0)
        return ps[0];

    return { x: 0, y: 0 };
}

export function getComponentsFromList(list: List): ComponentItem[] {
    return list.filter(item => item.name === "Component") as ComponentItem[];
}
export function getContactPointsFromList(list: List): ContactPointItem[] {
    const cps: ContactPointItem[] = [];
    for (const item of list) {
        if (item.name === "ContactPoint")
            cps.push(item);
        else if (item.name === "Component")
            cps.push(...getContactPointsFromList(item.list));
    }
    return cps;
}
export function getWiresFromList(list: List): WireItem[] {
    return list.filter(item => item.name === "Wire") as WireItem[];
}

export function removeUnusedPoints(list: List, points: Point[]): { list: List, points: Point[] } {
    return getCopy(list, points, false);
}

export function getCopy(list: List, points: Point[], center = true): { list: List, points: Point[] } {
    const mainPoint = center ? getMainPoint(list, points) : { x: 0, y: 0 };

    const indexMap: number[] = [];
    const copyPoints: Point[] = [];

    function getPointIndex(pointIndex: number): number {
        if (typeof indexMap[pointIndex] === "number")
            return indexMap[pointIndex];

        const copyPoint = points[pointIndex];
        const copyPointWithShift: Point = { x: copyPoint.x - mainPoint.x, y: copyPoint.y - mainPoint.y };

        const copyIndex = copyPoints.push(copyPointWithShift) - 1;
        indexMap[pointIndex] = copyIndex;

        return copyIndex;
    }

    const copyList = list.map(item => getCopyItem(item, getPointIndex));

    return { list: copyList, points: copyPoints };
}

export function getTagNames(list: List): string[] {
    const tagNames: string[] = [];

    for (const item of list) {
        if (isComponentItem(item) && item.tag.text) {
            tagNames.push(item.tag.text);
        }
    }

    return tagNames;
}
export function getContactPointNames(list: List): string[] {
    const contactPointNames: string[] = [];

    for (const item of getContactPointsFromList(list)) {
        contactPointNames.push(item.namePoint);
    }

    return contactPointNames;
}
