import { useMemo } from "react";
import { enqueueSnackbar } from "notistack";

import { useStore } from "../components/Store";
import {
    getContactPointNames, getContactPointsFromList, getCopy, getDeselectedList, getMainPoint,
    getSelectedList, getTagNames, getWiresFromList, removeFromList,
    removeUnusedPoints,
} from "./list";
import { getMainContactPointByName, getMovedPoints, getNewContactPointName, getNewTagName } from "./common";
import {
    getCopyItem, isComponentItem, isContactPointItem, isItemAtPoint, isStyleDrawItem, isStyleTextItem,
} from "./item";
import { getContactPointsNodes, isConnected, reduceUnnecessaryWires } from "./wire";

import {
    Store, Action, Keyboard, ListItem, Mouse, Project, Copy, List, Paste,
    StyleDraw, StyleText, GenericListItem, ComponentItem,
    WireItem, Point,
    Component,
} from "../types";

import pkg from "../../package.json";


export function useAction() {
    const { store, setStore } = useStore();

    const action = store.action;
    const setAction = useMemo(() => (action: Action) => setStore(store => ({ ...store, action })), [setStore]);

    return {
        action,
        setAction,

        // inputs
        setMouse: (mouse: Mouse) => setAction({ ...action, mouse }),
        setKeyboard: (keyboard: Keyboard) => setAction({ ...action, keyboard }),
    };
}

export function clearAction(action: Action): Action {
    return {
        ...action,
        drawing: null,
        paste: null,
        renameNode: null,
    };
}

export function pasteActionToProject(action: Action, project: Project): Project {
    const projectPointsLength = project.points.length;

    if (action.drawing) {
        const pasteItem = getCopyItem(action.drawing.item, point => point + projectPointsLength);
        const points = [...project.points, ...action.drawing.points];

        let list = [...project.list, pasteItem];

        // connect pasted wires
        if (pasteItem.name === "Wire") {
            list = connectWire(pasteItem, list, points);
            list = reduceUnnecessaryWires(list, points);
            list = resolveNodeNames(list);
        }

        return {
            ...project,
            ...removeUnusedPoints(list, points),
        };

    } else if (action.paste) {
        const { paste } = action;

        let list = project.list;
        const pasteList = paste.list.map(item => getCopyItem(item, point => point + projectPointsLength));
        const points = [...project.points, ...paste.points];

        // connect pasted contact points
        const cps = getContactPointsFromList(list);
        const wires = getWiresFromList(list);

        const cpsPaste = getContactPointsFromList(pasteList);
        for (const cpPaste of cpsPaste) {
            const pointPaste = points[cpPaste.point];

            for (const cp of cps) {
                const point = points[cp.point];
                if (point.x === pointPaste.x && point.y === pointPaste.y) {
                    // connect with wire
                    pasteList.push({
                        name: "Wire",
                        direction: "x",
                        points: [cp.point, cpPaste.point],
                    });
                }
            }

            for (const wire of wires) {
                if (isItemAtPoint(wire, points, pointPaste)) {
                    const splitPointIndex = cpPaste.point;
                    list = removeFromList(list, [wire]);
                    pasteList.push({
                        ...wire,
                        points: [wire.points[0], splitPointIndex],
                    }, {
                        ...wire,
                        points: [splitPointIndex, wire.points[1]],
                    });
                }
            }
        }
        // TODO: connect pasted wires

        list = reduceUnnecessaryWires([...list, ...pasteList], points);
        list = resolveNodeNames(list);

        return {
            ...project,
            ...removeUnusedPoints(list, points),
        };
    } else {
        return project;
    }
}

function connectWire(pasteWire: WireItem, list: List, points: Point[]): List {
    const cps = getContactPointsFromList(list);

    const wirePoints = pasteWire.points.map(p => points[p]);

    // connect to connect point
    for (const cp of cps) {
        const point = points[cp.point];
        if (point.x === wirePoints[0].x && point.y === wirePoints[0].y) {
            // connected
            pasteWire.points[0] = cp.point;
        } else if (point.x === wirePoints[1].x && point.y === wirePoints[1].y) {
            pasteWire.points[1] = cp.point;
        }
    }

    // connect with wires
    for (const index in wirePoints) {
        const wirePoint = wirePoints[index];
        const wires = getWiresFromList(list);
        for (const wire of wires) {
            if (wire === pasteWire) continue;

            if (isItemAtPoint(wire, points, wirePoint)) {
                const splitPointIndex = pasteWire.points[index];
                list = [
                    ...removeFromList(list, [wire]),
                    {
                        ...wire,
                        points: [wire.points[0], splitPointIndex],
                    },
                    {
                        ...wire,
                        points: [splitPointIndex, wire.points[1]],
                    },
                ];
            }
        }
    }

    return list;
}

export function resolveNodeNames(list: List): List {
    const nodes = getContactPointsNodes(list);
    node: for (const node of nodes) {
        let mainContactPoint = node[0];
        let newNodeName = false;
        for (const cp of node) {
            if (cp.namePoint !== mainContactPoint.namePoint) {
                const contactPoint = getMainContactPointByName(cp, mainContactPoint);
                if (contactPoint === null) continue node; // 2 different set node names

                newNodeName = true;
                mainContactPoint = contactPoint;
            }
        }

        if (newNodeName) {
            list = list.map(c => {
                if (c.name === "Component") {
                    let hasCPInNode = false;
                    for (const item of c.list) {
                        if (item.name === "ContactPoint" && node.includes(item)) {
                            hasCPInNode = true;
                            break;
                        }
                    }
                    if (hasCPInNode) {
                        return {
                            ...c,
                            list: c.list.map(item => {
                                if (item.name === "ContactPoint" && node.includes(item)) {
                                    return { ...item, namePoint: mainContactPoint.namePoint };
                                }
                                return item;

                            }),
                        };
                    }
                }
                return c;
            });
        }
    }

    return list;
}

export function isSelectMove(action: Action): boolean {
    return action.drawing === null && action.paste === null;
}

export function selectItems(list: List, items: ListItem[]): List {
    return list.map(item => {
        item = { ...item, selected: items.includes(item) ? true : undefined };

        if (isComponentItem(item)) {
            item = { ...item, tag: { ...item.tag, selected: items.includes(item.tag) ? true : undefined } };
            item = { ...item, value: { ...item.value, selected: items.includes(item.value) ? true : undefined } };
        }
        // TODO: contact point name
        return item;
    });
}
export function deselectAllItems(project: Project): Project {
    return {
        ...project,
        list: getDeselectedList(project.list),
    };
}


export function copy(store: Store): Store {
    const selectedList = getSelectedList(store.project.list);

    if (selectedList.length === 0) {
        setTimeout(() => enqueueSnackbar("Nothing to copy", { variant: "warning" }), 100);
        return store;
    }

    const { list, points } = getCopy(selectedList, store.project.points);

    const copy: Copy = {
        program: "GEEC",
        version: pkg.version,
        list,
        points,
    };

    navigator.clipboard.writeText(JSON.stringify(copy));
    setTimeout(() => enqueueSnackbar("Copied to clipboard. Ready to paste!", { variant: "success" }), 100);

    return store;
}

export function paste(store: Store, clipboardData: DataTransfer): Store {
    const clipboardText = clipboardData.getData("text/plain");

    let paste: Copy;
    try {
        paste = JSON.parse(clipboardText);

        if (
            paste === null
            || typeof paste !== "object"
            || Array.isArray(paste)
            || typeof paste.program !== "string"
            || typeof paste.version !== "string"
            || !Array.isArray(paste.list)
            || !Array.isArray(paste.points)
            || paste.program !== "GEEC"
            || parseInt(paste.version, 10) < 6
        ) {
            throw new Error("Wrong format.");
        }
    } catch (e) {
        setTimeout(() => enqueueSnackbar(
            "The pasted content is not in the supported format",
            { variant: "warning" },
        ), 100);
        return store;
    }

    return pasteCircuit(store, paste);
}

function pasteCircuit(store: Store, paste: Paste): Store {
    // move to cursor
    const mainPoint = getMainPoint(paste.list, paste.points);
    const mouse = store.action.mouse;
    const move = { x: mainPoint.x - mouse.x, y: mainPoint.y - mouse.y };
    const points = getMovedPoints(paste.points, move);

    // rename tags on components and rename contact point names
    const tagNames = getTagNames(store.project.list);
    const cpNames = getContactPointNames(store.project.list);

    const wires = getWiresFromList(paste.list);
    const namedCpPoints: { index: number, name: string }[] = [];

    const list = paste.list.map(item => {
        if (isComponentItem(item)) {
            const newTagName = getNewTagName(item.tag.text || "", tagNames);
            item.tag.text = newTagName;
            tagNames.push(newTagName);

            item.list = item.list.map(item => {
                if (isContactPointItem(item)) {
                    // is connected
                    for (const namedCpPoint of namedCpPoints) {
                        if (isConnected(item.point, namedCpPoint.index, wires)) {
                            item.namePoint = namedCpPoint.name;
                            return item;
                        }
                    }

                    // new name
                    const newCPName = getNewContactPointName(item.namePoint, item.number, cpNames);
                    item.namePoint = newCPName;
                    cpNames.push(newCPName);
                    namedCpPoints.push({ index: item.point, name: newCPName });
                }
                return item;
            });
        }

        return item;
    });

    return {
        ...store,
        project: deselectAllItems(store.project),
        action: {
            ...clearAction(store.action),
            paste: {
                points,
                list,
            },
        },
    };
}

export function pasteComponent(store: Store, component: Component, points: Point[]): Action {
    const list: List = [{ ...component, name: "Component" }];

    const { action } = pasteCircuit(store, { list, points });
    return action;
}

// --------------- styles ---------------

export function changeStyle(store: Store, styleDraw: Partial<StyleDraw>, styleText: Partial<StyleText>): Store {
    // change style in style settings
    let { action } = store;
    action = {
        ...action,
        style: {
            draw: { ...action.style.draw, ...styleDraw },
            text: { ...action.style.text, ...styleText },
        },
    };


    if (action.drawing) {
        // change drawing
        return {
            ...store,
            action: {
                ...action,
                drawing: {
                    ...action.drawing,
                    item: changeStyleOfItem(action.drawing.item, styleDraw, styleText),
                },
            },
        };

    } else if (action.paste) {
        // change paste
        return {
            ...store,
            action: {
                ...action,
                paste: {
                    ...action.paste,
                    list: changeStyleInSelectedList(action.paste.list, styleDraw, styleText),
                },
            },
        };

    } else if (getSelectedList(store.project.list).length > 0) {
        // change all selected
        return {
            ...store,
            action,
            project: {
                ...store.project,
                list: changeStyleInSelectedList(store.project.list, styleDraw, styleText),
            },
            historyAction: "record",
        };
    }

    return { ...store, action };
}

function changeStyleInSelectedList(
    list: List, styleDraw: Partial<StyleDraw>, styleText: Partial<StyleText>, force = false,
): List {
    return list.map(item => {
        if (isComponentItem(item)) {
            return {
                ...item,
                tag: (force || item.selected) ? changeStyleOfItem(item.tag, styleDraw, styleText) : item.tag,
                value: (force || item.selected) ? changeStyleOfItem(item.value, styleDraw, styleText) : item.value,
                list: changeStyleInSelectedList(item.list, styleDraw, styleText, force || item.selected),
            } as ComponentItem;
        }
        if (force || item.selected) {
            return changeStyleOfItem(item, styleDraw, styleText);
        }
        return item;
    });
}

function changeStyleOfItem<T extends GenericListItem>(
    item: T, styleDraw: Partial<StyleDraw>, styleText: Partial<StyleText>,
): T {
    if (isStyleTextItem(item)) {
        return { ...item, style: { ...item.style, ...styleText } };
    } else if (isStyleDrawItem(item)) {
        return { ...item, style: { ...item.style, ...styleDraw } };
    }

    return item;
}