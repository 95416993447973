import { FunctionList, ParsedExpression } from "../types/expressions";

const SHORTCUTS = [
    { shortcut: "T", value: 1e+12 },
    { shortcut: "G", value: 1e+9 },
    { shortcut: "Meg", value: 1e+6 },
    { shortcut: "k", value: 1e+3 },
    { shortcut: "", value: 1 },
    { shortcut: "m", value: 1e-3 },
    { shortcut: "u", value: 1e-6 },
    { shortcut: "n", value: 1e-9 },
    { shortcut: "p", value: 1e-12 },
    { shortcut: "f", value: 1e-15 },
];

/** Parse a number as a string with SI prefix (1m, 1k, 1Meg, ...) */
export function parseNumberFromString(numberWithShortcut: string): number {
    const numberWithShortcutLowerCase = numberWithShortcut.toLocaleLowerCase();
    for (const { shortcut, value } of SHORTCUTS) {
        if (shortcut && numberWithShortcutLowerCase.endsWith(shortcut.toLocaleLowerCase())) {
            // G is before MEG
            if (numberWithShortcutLowerCase.endsWith("meg")) {
                return parseFloat(numberWithShortcut) * 1e+6;
            }
            return parseFloat(numberWithShortcut) * value;
        }
    }
    return parseFloat(numberWithShortcut);
}

/** TODO: review */
export function parseNumbersFromShortedString(numbersWithShortcut: string): string {
    const numberWithShortcut = numbersWithShortcut.split(",");
    const result: number[] = [];
    numberWithShortcut.forEach((item) => {
        result.push(parseNumberFromString(item));
    });
    return result.join(",");
}

/** TODO: review */
export function toPrecision(value: number, digits = 5): string {
    const str: string = value.toString();
    const arr: Array<string> = str.includes("e") ? [str] : str.split(".");
    let out = "";
    if (arr.length === 2 && arr[0].length < digits) {
        out = `${arr[0]}.${arr[1].substring(0, digits - arr[0].length)}`;
    } else {
        out = arr[0];
    }
    out = out.replace(/\.0+$/, "");
    return out.endsWith("0") && out.includes(".") ? out.replace(/0+$/, "") : out;
}

/** TODO: review */
export function isSymbolicValue(value: string): boolean {
    if (value.trim().search(/\d*.?\d+e[-|+]\d+/gi) == 0) { // exponential form
        return false;
    }
    if (value.search(/\+|\/|\*|-/gi) !== -1) {
        return true;
    }
    return Number.isNaN(parseNumberFromString(value));
}

/** TODO: review */
export function getSymbolicVariables(value: string, useExceptions = true): Array<string> {
    const tmpfunctions = value.match(/(\w*\s*\()/gi);
    const functions = (tmpfunctions ? tmpfunctions : []).map((item) => item.replace("(", ""));
    const result = value.split(/\s|,|\+|\/|\*|-|\(|\)|\[|\]|\{|\}|\^/gi);
    const exceptions = useExceptions ? ["s", "j", "t", "PI", "pi", "Pi"] : [];
    if (result) {
        return result.filter(item =>
            !exceptions.includes(item)
            && !functions.includes(item)
            && item !== ""
            && isSymbolicValue(item));
    } else {
        return [];
    }
}

/** TODO: review */
export function getReadableNumber(value: number, latex = false, digits = 5): string {
    for (const short of SHORTCUTS) {
        if (Math.abs(value / short.value) >= 1) {
            if (Number.isFinite(value / short.value)) {
                const unit = latex ? `\\,{\\rm ${short.shortcut}}` : short.shortcut;
                return `${toPrecision((value / short.value), digits)}${unit}`;
            } else {// infinity
                return `${toPrecision((value / short.value))}`;
            }

        }
    }

    return toPrecision(value);
}

/** TODO: review */
export function getNumberWithShortcut(num: number, shortcut = ""): string {
    if (shortcut) {
        for (const short of SHORTCUTS) {
            if (shortcut == short.shortcut) {
                num = num * short.value;
            }
        }
    }

    for (const short of SHORTCUTS) {
        if (Math.abs(num) >= short.value) {
            return `${num / short.value}${short.shortcut}`;
        }
    }

    return String(num);
}

export function isExpression(expr: string): boolean {
    const pattern = /[+\-*/:]/;
    return pattern.test(expr);
}

export function renderExpression(template: string, ...args: string[]): string {
    return template.replace(/\${(\d+)}/g, (match, index) => {
        return typeof args[index] !== "undefined" ? renderExpression(args[index], ...args) : match;
    });
}

export function convertFunction(func: string, functionDict: FunctionList): string {
    const match = func.match(/(.*)\((.*)\)/);

    if (!match) {
        throw new Error("Invalid function string format (convertFunction)");
    }

    const functionName = match[1];
    const params = match[2].split(",").map(param => param.trim());

    const found = functionDict.find(item => (item.name === functionName && item.paramCount === params.length));

    if (found) {
        return found.getFunction(found.name, ...params);
    }

    // if it is an unknown function, return it back
    return func;
}

// /** Basic operator are not parsed */
// export function parseExpression(expr: string): ParsedExpression {
//     // TODO: check variables
//     // TODO: check functions
//     // TODO: check operators like ^,...
//     // TODO: check numbers
//     return {template: "", args: []};
// }

export function parseExpression(expr: string): ParsedExpression {
    const args: string[] = [];
    let argIndex = 0;

    function _parse(expr: string): string {
        const regex = /([a-zA-Z_][a-zA-Z0-9_]*)\(([^\(\)]+)\)/;
        let match: RegExpExecArray | null;

        while ((match = regex.exec(expr)) !== null) {
            const [fullMatch, func, innerExpr] = match;
            const parsedInnerExpr = _parse(innerExpr);
            const argPlaceholder = `\${${argIndex++}}`;

            args.push(`${func}(${parsedInnerExpr})`);
            expr = expr.replace(fullMatch, argPlaceholder);
        }

        return expr.replace(/([a-zA-Z_][a-zA-Z0-9_]*)/g, (match) => {
            const argPlaceholder = `\${${argIndex++}}`;
            args.push(match);
            return argPlaceholder;
        });
    }

    const template = _parse(expr);

    return { template, args };
}

export function toSympy(expr: string): string {
    // TODO: add more rules
    return expr.replaceAll("^","**");
}