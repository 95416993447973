/* eslint-disable @typescript-eslint/no-unused-vars */
import { ServerResponse } from "../types";
import { AnalysisCircuit, AnalysisConfig, AnalysisType, Result, SimResultExpression, TODO } from "../types/analysis";
import { AnalysisHandler } from "./analysisHandler";
import { combineResults, mapComputedResults, SimulationRawData } from "./core_draft";
//import fftLib from "fft-js";


export function getFFTAnalysisHandler(): AnalysisHandler {
    return {
        //getNetlist: () => "",
        //getRequestData: FFTGetRequestData,
        getFinalResults: mapComputedResults,
        getDefaultResults: () => [{ id: -1, name: "freq", expr: "freq" }],
        getAvailableResults: () => [],
        generateScript: fftGenerateScript,
        parseData: fftParseData,
        parseErrors: (result: ServerResponse) => "",
        sendRequest: fftSendRequest,
    };
}

// function FFTGetRequestData(proj: AnalysisCircuit, simSettings: AnalysisConfig): SimResultExpression[]{
//     let defaultResults: SimResultExpression[] = FFTGetDefaultResults(proj, simSettings.analysisType);
//     return combineResults(simSettings.userResults, defaultResults);
// }

function fftGetDefaultResults(sett: AnalysisCircuit, analysisType: AnalysisType): SimResultExpression[] {
    return [{ id: -1, name: "freq", expr: "freq" }];
}

function fftGenerateScript(proj: AnalysisCircuit, simSettings: AnalysisConfig, reqRslt: SimResultExpression[]): string {
    const config: TODO = {
        requestedData: reqRslt,
    };

    return JSON.stringify(config);
}

function fftParseData(data: string): Result[] {
    const outputData: Result[] = [];
    const parsedData = JSON.parse(data);
    for (const key in parsedData) {
        outputData.push(
            {
                id: -1,
                name: key,
                value: parsedData[key],
                valueType: "ReIm",
                dataType: "Array",
                format: "Float",
            },
        );
    }
    return outputData;
}

function fftSendRequest(script: string): Promise<ServerResponse> {
    return new Promise((resolve) => {
        // TODO: define config
        const config = JSON.parse(script);

        const outputData: TODO = {};
        // frequency
        outputData["result_1"] = [
            { value1: "1", value2: "0" },
            { value1: "2", value2: "0" },
            { value1: "3", value2: "0" }];
        // v(1)
        outputData["result_0"] = [
            { value1: "0", value2: "0" },
            { value1: "1", value2: "0" },
            { value1: "0", value2: "0" }];

        resolve({
            status: 200,
            data: JSON.stringify(outputData),
        });
    });

}

export type FFTWinType = "none" | "blackman" | "hann" | "hamm" | "gauss" | "BlackmanHarris" | "triangle";

function createFFTWindow(type: FFTWinType, length: number): number[] {
    // Create an array [0, ..., N]
    const n = Array(length + 1).fill(0).map((_, i) => i);
    switch (type) {
        case "blackman":
            return n.map((item) => 0.42 - 0.5 * Math.cos(2 * Math.PI * item / length)
                + 0.08 * Math.cos(4 * Math.PI * item / length));
        case "BlackmanHarris":
            return n.map((item) => 0.35875 - 0.48829 * Math.cos(2 * Math.PI * item / length)
                + 0.14128 * Math.cos(4 * Math.PI * item / length)
                - 0.01168 * Math.cos(6 * Math.PI * item / length));
        case "gauss":
            return n.map((item) => Math.exp(-0.5 * Math.pow(((item - length / 2) / (0.2 * length / 2)), 2)));
        case "hamm":
            return n.map((item) => 0.54 - 0.46 * Math.cos(2 * Math.PI * item / length));
        case "hann":
            return n.map((item) => 0.5 * (1 - Math.cos(2 * Math.PI * item / length)));
        case "triangle":
            return n.map((item) => 1 - Math.abs((item - length / 2) / (length / 2)));
        default:
            return [...Array(length).fill(1)];
    }
}

// function runAndShow(analysis: GraphicAnalysis, window: GraphWindow, circuit: Circuit) {
//     console.log(window.tranAnalysis);
//     if (check(analysis)) {
//         circuitAnalysis.setDefaultConfig(circuit, analysis.config);
//         // ///////////////////////////////////////////////////
//         const signals: Array<string> = spiceResources.getSplittedExpressions(analysis.config.signal);
//         // console.log(signals);

//         analysis.isRunning = true;
//         const data = {};
//         try {
//             const step = getNumberFromShortedString(window.tranAnalysis.config.step);
//             signals.forEach((signal) => {
//                 const signalData = window.tranAnalysis.resultData.filter(
//                              (item) => item.name === signal).map((item) => item.y);
//                 if (signalData.length === 0) {
//                     throw new Error(`Undefined vector "${signal}"`);
//                 }
//                 let finalData = [];
//                 let nfft = 1;
//                 if (parseInt(analysis.config.availablesamples) > parseInt(analysis.config.samples)) {
//                     finalData = signalData.slice(0, analysis.config.samples);
//                     nfft = analysis.config.samples;
//                 } else {
//                     nfft = analysis.config.availablesamples;
//                     const diff = analysis.config.samples - analysis.config.availablesamples;
//                     finalData = [ ...signalData, ...Array(diff).fill(0) ];
//                 }
//                 console.log(finalData);
//                 const fftWindow = createFFTWindow(analysis.config.window, parseInt(analysis.config.samples));
//                 finalData = finalData.map((item, index) => item * fftWindow[index]);
//                 const phasors = fftLib.fft(finalData);
//                 const frequencies = fftLib.util.fftFreq(phasors, 1 / step); // sample rate
//                 // let mag = fftLib.util.fftMag(phasors);
//                 let mag = phasors.slice(0, phasors.length / 2).map(c => dataParser.complexMag(c[0], c[1]));
//                 const ph = phasors.slice(0, phasors.length / 2).map(c => dataParser.complexPh(c[0], c[1]));
//                 // magnitude correction
//                 mag = mag.map((item, index) => {
//                     if (index !== 0) {
//                         return 2 * item / nfft;
//                     }
//                     return item / nfft;
//                 });

//                 data["frequency"] = frequencies;
//                 data[signal] = mag.map((item, index) => dataParser.toComplex(item, ph[index]));
//             });
//             analysis.isRunning = false;
//         } catch (e) {
//             analysisMain.showError(analysis.resultElement, `Analysis error:<br/>${e}`);
//         }
//         if (!analysis.isRunning) {
//             analysis.outputMessage = JSON.stringify(data);
//             showResults(analysis, window);
//         } else {
//             analysis.isRunning = false;
//         }
//     }
// }