import {
    Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    FormControlLabel, IconButton, Paper,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField,
    Typography,
} from "@mui/material";
import {
    mdiClose, mdiCursorMove, mdiMouseLeftClick, mdiMouseRightClick, mdiMouseScrollWheel, mdiSelectionDrag,
} from "@mdi/js";
import { Icon } from ".";

import { isTextItem } from "../services/item";
import { useStore } from "./Store";
import React, { useEffect, useId, useMemo, useState } from "react";
import { cancelDrawing } from "../services/draw";
import { getComponentsFromList, getUpdatedItem, mapList } from "../services/list";

import { ComponentItem, DialogDraggable, Point } from "../types";
import { getCircuitFolderName, getCircuitName } from "../services/common";
import { AnalysisResultDialogComponentMemo, AnalysisResultDialogComponentProps } from "./AnalysisResultDialog";

const draggableDialogWidth = 800;

export function getShowDialog(): DialogDraggable {
    const windowWidth = window.innerWidth;
    // const windowHeight = window.innerHeight;

    return {
        show: true,
        type: "draggable",
        position: {
            x: windowWidth / 2 - draggableDialogWidth / 2,
            y: 100,
        },
    };
}

export default function Dialogs() {
    return <>
        <AnalysisDialogs />
        <ProjectSettingDialog />
        <ComponentDialogs />
        <TextDialogs />
        <NodeNameDialog />
        <HelpDialog />
    </>;
}


function AnalysisDialogs() {
    const { store, setStore } = useStore();

    function onClose(index: number) {
        // until there's no way to reopen, I'm deleting the entire record
        setStore(store => ({
            ...store,
            action: {
                ...store.action,
                analysisResults: store.action.analysisResults.filter((_, i) => i !== index),
            },
        }));
    }

    function onMove(x: number, y: number, index: number) {
        setStore(store => ({
            ...store,
            action: {
                ...store.action,
                analysisResults: store.action.analysisResults.map((a, i) => i !== index ? a : {
                    ...a,
                    dialog: {
                        show: true,
                        type: "draggable",
                        position: { x, y },
                    },
                }),
            },
        }));
    }

    return <>
        {store.action.analysisResults
            .map((ard, index) => <AnalysisResult
                key={index}
                {...ard}
                onMove={(x, y) => onMove(x, y, index)}
                onClose={() => onClose(index)}
            />)}
    </>;
}
/** Memoize callbacks for better performance */
function AnalysisResult({ analysis, dialog, onMove, onClose }: AnalysisResultDialogComponentProps) {
    const onMoveMemo = useMemo(() => onMove, []);
    const onCloseMemo = useMemo(() => onClose, []);

    return <AnalysisResultDialogComponentMemo
        dialog={dialog}
        analysis={analysis}
        onMove={onMoveMemo}
        onClose={onCloseMemo}
    />;
}

function ProjectSettingDialog() {
    const { store, setStore } = useStore();
    const { project } = store;

    function setTitle(text: string, type: "name" | "folder") {
        let title = text;

        if (project.type === "circuit") {
            const folder = getCircuitFolderName(project.title);
            const name = getCircuitName(project.title);
            switch (type) {
                case "name":
                    title = folder ? `${folder}/${text}` : text;
                    break;
                case "folder": title =
                    text ? `${text}/${name}` : name;
                    break;
            }
        }

        setStore(s => ({
            ...s,
            project: { ...s.project, title },
        }));
    }

    function onClose() {
        setStore(s => ({
            ...s,
            project: { ...s.project, dialog: { show: false } },
        }));
    }

    return <Dialog onClose={onClose}
        open={project.dialog.show}>
        <DialogTitle>{project.type === "circuit" ? "Circuit" : "Component"} settings</DialogTitle>
        <DialogContent>
            <TextField
                margin="dense"
                label="ID"
                fullWidth
                variant="standard"
                disabled
                value={project.id?.toString() || "Not assigned"}
            />
            {project.type === "circuit" && store.user.isAdmin && <>
                <FormControlLabel control={<Checkbox
                    value={project.privateId !== null}
                    onChange={() => setStore(s => ({
                        ...s, project: {
                            ...s.project,
                            privateId: project.privateId === null ? store.user.id : null,
                        },
                    }))}
                />} label="Private" />
            </>}
            <TextField
                autoFocus
                required
                margin="dense"
                label={`${project.type === "circuit" ? "Circuit" : "Component"} name`}
                fullWidth
                variant="standard"
                value={getCircuitName(project.title)}
                onChange={e => setTitle(e.target.value, "name")}
            />
            {project.type === "circuit" && project.privateId === null && <TextField
                margin="dense"
                label="Folder"
                fullWidth
                variant="standard"
                placeholder="Without folder"
                value={getCircuitFolderName(project.title) || ""}
                onChange={e => setTitle(e.target.value, "folder")}
            />}
            <TextField
                margin="dense"
                label="Description"
                fullWidth
                multiline
                variant="standard"
                value={project.description}
                onChange={(e) => setStore(s => ({ ...s, project: { ...s.project, description: e.target.value } }))}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>Close settings</Button>
        </DialogActions>
    </Dialog>;
}

function ComponentDialogs() {
    const { store, setStore } = useStore();
    const components = getComponentsFromList(store.project.list);

    return <>
        {components.filter(comp => comp.dialog.show).map((comp, i) => <ComponentDialog
            key={i}
            component={comp}
            onChange={c => setStore(getUpdatedItem(store, comp, c))}
        />)}
    </>;
}

function TextDialogs() {
    const { store, setStore } = useStore();
    const { action } = store;

    const drawing = action.drawing;
    const item = drawing?.item;
    const show = Boolean(drawing && item && isTextItem(item) && item.text === "");

    return <TextDialog
        title="Set text"
        label="Text"
        show={show}
        onSubmit={(text) => {
            if (text && drawing && item && isTextItem(item) && item.text === "") {
                setStore(store => ({
                    ...store,
                    action: {
                        ...action,
                        drawing: {
                            ...drawing,
                            item: {
                                ...item,
                                text,
                            },
                        },
                    },
                }));
            }
        }}
        onClose={() => {
            setStore(store => cancelDrawing(store));
        }}
    />;
}

function NodeNameDialog() {
    const { store, setStore } = useStore();
    const { renameNode } = store.action;

    const oldName = renameNode?.contactPoints[0].namePoint || "";
    return <TextDialog
        show={renameNode !== null}
        title={"Rename node"}
        text={`Previous name "${oldName}".`}
        label="Node name"
        onSubmit={(text) => {
            if (!text || !renameNode) return;
            setStore(store => ({
                ...store,
                project: {
                    ...store.project,
                    list: mapList(store.project.list, item => {
                        if (item.name === "ContactPoint" && renameNode.contactPoints.includes(item))
                            return { ...item, namePoint: text };
                        return item;
                    }),
                },
                action: {
                    ...store.action,
                    renameNode: null,
                },
                historyAction: "record",
            }));
        }}
        onClose={() => {
            setStore(store => ({ ...store, action: { ...store.action, renameNode: null } }));
        }}
    />;
}


type TextDialogProps = {
    title: string,
    text?: string,
    label: string,
    show: boolean,
    onSubmit: (value: string) => void,
    onClose: () => void,
};

export function TextDialog({ title, show, text, label, onSubmit, onClose }: TextDialogProps) {
    const id = useId();

    return <Dialog
        onClose={onClose}
        open={show}
        PaperProps={{
            component: "form",
            onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                event.preventDefault();
                const textField = document.getElementById(id) as HTMLInputElement;
                if (!textField) return;
                const value = textField.value;
                onSubmit(value);
            },
        }}
    >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            {text && <DialogContentText>{text}</DialogContentText>}
            <TextField
                autoFocus
                required
                margin="dense"
                id={id}
                name={id}
                label={label}
                fullWidth
                variant="standard"
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="submit">Submit</Button>
        </DialogActions>
    </ Dialog>;
}

export type DraggableDialogProps = {
    title: string,
    dialog: DialogDraggable,
    children: React.ReactNode,
    onClose: () => void,
    onMove: (x: number, y: number) => void,
}

export function DraggableDialog({ title, dialog, children, onClose, onMove }: DraggableDialogProps) {
    const [mouseDown, setMouseDown] = useState<null | Point>(null);

    useEffect(() => {
        if (dialog.show && mouseDown) {
            const handleMouseMove = (e: MouseEvent) => {
                const shift = { x: mouseDown.x - e.clientX, y: mouseDown.y - e.clientY };
                onMove(dialog.position.x - shift.x, dialog.position.y - shift.y);
            };
            const handleMouseUp = () => {
                setMouseDown(null);
            };

            document.addEventListener("mousemove", handleMouseMove);
            document.addEventListener("mouseup", handleMouseUp);

            return () => {
                document.removeEventListener("mousemove", handleMouseMove);
                document.removeEventListener("mouseup", handleMouseUp);
            };
        }
    }, [dialog.show, mouseDown]);

    return <Dialog
        open={dialog.show}
        hideBackdrop
        transitionDuration={0}
        scroll="paper"
        style={{ pointerEvents: "none" }}
        PaperProps={{
            style: !dialog.show ? {} : {
                pointerEvents: "auto",
                position: "absolute",
                top: dialog.position.y,
                left: dialog.position.x,
                width: draggableDialogWidth,
            },
        }}
        onClose={onClose}
    >
        <DialogTitle
            style={{
                cursor: "move", userSelect: "none",
                display: "flex", alignItems: "center", justifyContent: "space-between",
            }}
            onMouseDown={e => setMouseDown({ x: e.clientX, y: e.clientY })}
        >
            {title}
            <IconButton onClick={onClose}>
                <Icon path={mdiClose} />
            </IconButton>
        </DialogTitle>
        {children}
    </Dialog>;
}

type ComponentDialogProps = {
    component: ComponentItem,
    onChange: (component: ComponentItem) => void,
}

function ComponentDialog({ component, onChange }: ComponentDialogProps) {

    function onClose() {
        onChange({
            ...component,
            dialog: {
                show: false,
            },
        });
    }

    return <DraggableDialog
        dialog={component.dialog}
        title={component.componentName}
        onMove={(x, y) => onChange({
            ...component,
            dialog: {
                show: true,
                type: "draggable",
                position: { x, y },
            },
        })}
        onClose={onClose}
    >
        <DialogContent>
            <TextField
                margin="dense"
                label="Name"
                fullWidth
                variant="standard"
                value={component.componentName}
                onChange={(e) => onChange({
                    ...component,
                    componentName: e.target.value,
                })}
            />
            <TextField
                margin="dense"
                label="Tag"
                fullWidth
                variant="standard"
                value={component.tag.text}
                onChange={(e) => onChange({
                    ...component,
                    tag: { ...component.tag, text: e.target.value },
                })}
            />
            <FormControlLabel label="Show Tag" control={<Checkbox
                checked={component.showTag}
                onChange={() => onChange({
                    ...component,
                    showTag: !component.showTag,
                })} />}
            />

            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Property</TableCell>
                            <TableCell>Value</TableCell>
                            <TableCell>Used</TableCell>
                            <TableCell>Show</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {component.properties.map((prop, i) => <TableRow key={i}>
                            <TableCell>
                                <TextField
                                    margin="dense"
                                    fullWidth
                                    variant="standard"
                                    value={prop.description}
                                    onChange={(e) => {
                                        const properties = [...component.properties];
                                        properties[i] = { ...prop, description: e.target.value };
                                        onChange({ ...component, properties });
                                    }}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    margin="dense"
                                    fullWidth
                                    variant="standard"
                                    value={prop.value}
                                    onChange={(e) => {
                                        const properties = [...component.properties];
                                        properties[i] = { ...prop, value: e.target.value };
                                        onChange({ ...component, properties });
                                    }}
                                />
                            </TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={prop.used}
                                    onChange={() => {
                                        const properties = [...component.properties];
                                        properties[i] = { ...prop, used: !prop.used };
                                        onChange({ ...component, properties });
                                    }}
                                />
                            </TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={prop.show}
                                    onChange={() => {
                                        const properties = [...component.properties];
                                        properties[i] = { ...prop, show: !prop.show };
                                        onChange({ ...component, properties });
                                    }}
                                />
                            </TableCell>
                        </TableRow>)}
                    </TableBody>
                </Table>
            </TableContainer>

            <TextField
                margin="dense"
                label="NetList allowance"
                fullWidth
                multiline
                variant="standard"
                value={component.allowance}
                onChange={(e) => onChange({ ...component, allowance: e.target.value })}
            />
            <TextField
                margin="dense"
                label="Component description"
                fullWidth
                multiline
                variant="standard"
                value={component.description}
                onChange={(e) => onChange({ ...component, description: e.target.value })}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>Close</Button>
        </DialogActions>
    </DraggableDialog>;
}

function HelpDialog() {
    const { store, setStore } = useStore();

    const open = store.action.help.show;
    function onClose() {
        setStore(s => ({
            ...s,
            action: {
                ...s.action,
                help: { show: false },
            },
        }));
    }

    return <Dialog open={open} onClose={onClose}>
        <DialogTitle>Mouse control and keyboard shortcuts</DialogTitle>
        <DialogContent>
            <DialogContentText>
                This section will briefly introduce you to how to operate the GEEC editor using both your mouse
                and keyboard. You will learn the basic operations that will enable you to quickly and
                efficiently create and edit electrical circuits.
            </DialogContentText>

            <Typography variant="h6" component="h3" sx={{ mt: 2 }}>Mouse</Typography>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={2}>Key</TableCell>
                        <TableCell>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell width={22}><Icon path={mdiMouseLeftClick} /></TableCell>
                        <TableCell width={180}>Left click</TableCell>
                        <TableCell>
                            Select item
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell ><Icon path={mdiMouseRightClick} /></TableCell>
                        <TableCell>Right click</TableCell>
                        <TableCell>
                            Cancel action
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell ><Icon path={mdiMouseRightClick} /></TableCell>
                        <TableCell>Right click on selected item</TableCell>
                        <TableCell>
                            Item settings
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell ><Icon path={mdiMouseScrollWheel} /></TableCell>
                        <TableCell>Scroll wheel</TableCell>
                        <TableCell>
                            Zoom in / Zoom out
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell ><Icon path={mdiMouseScrollWheel} /></TableCell>
                        <TableCell>Scroll wheel drag</TableCell>
                        <TableCell>
                            Move canvas
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><Icon path={mdiSelectionDrag} /></TableCell>
                        <TableCell>
                            Left drag when not selected
                        </TableCell>
                        <TableCell>
                            Items within the area will be selected
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><Icon path={mdiCursorMove} /></TableCell>
                        <TableCell>
                            Left drag when on selected
                        </TableCell>
                        <TableCell>
                            Selected items will be moved
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>

            <Typography variant="h6" component="h3" sx={{ mt: 2 }}>Keyboard</Typography>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Keys</TableCell>
                        <TableCell>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell width={200}>F1</TableCell>
                        <TableCell>
                            Show this help
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width={200}>CTRL + C / CMD + C</TableCell>
                        <TableCell>
                            Copy selected items
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>CTRL + V / CMD + V</TableCell>
                        <TableCell>
                            Paste
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>CTRL + Z / CMD + Z</TableCell>
                        <TableCell>
                            Back in history
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>CTRL + Y / CMD + SHIFT + Z</TableCell>
                        <TableCell>
                            Front in history
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>ESC</TableCell>
                        <TableCell>
                            Cancel action (selection, drawing, copying)
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>BACKSPACE / DELETE</TableCell>
                        <TableCell>
                            Remove selected items
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>CTRL + Arrow / CMD + Arrow</TableCell>
                        <TableCell>
                            Move canvas
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>G</TableCell>
                        <TableCell>
                            Show / Hide grid
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>W</TableCell>
                        <TableCell>
                            Wire drawing
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>S</TableCell>
                        <TableCell>
                            Change font vertical align
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>A</TableCell>
                        <TableCell>
                            Change font horizontal align
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>

        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>Close</Button>
        </DialogActions>
    </Dialog>;
}