import {
    Area, ArrowItem, ContactPointItem, DrawList, DrawingList, GenericListItem,
    ListItem, Point, PointIndex, TextItem, ComponentItem,
    ArcDrawItem, DrawItem, WireItem,
} from "../types";
import { applyStyleText, getArcFromPoints } from "./canvas";
import { getWireMiddlePoint } from "./wire";


export function isTextItem(item: GenericListItem): item is TextItem {
    return item.name === "ComponentText" || item.name === "DrawToolText";
}
export function isComponentItem(item: GenericListItem): item is ComponentItem {
    return item.name === "Component";
}
export function isContactPointItem(item: GenericListItem): item is ContactPointItem {
    return item.name === "ContactPoint";
}
export function isDrawToolArc(item: GenericListItem): item is ArcDrawItem {
    return item.name === "DrawToolArc" || item.name === "DrawToolArcCenter";
}

export function isStyleDrawItem(item: GenericListItem): item is ArrowItem | DrawItem | WireItem {
    return item.name === "Draw" || item.name === "Wire" || item.name.includes("Arrow");
}
export function isStyleTextItem(item: GenericListItem): item is TextItem | ContactPointItem {
    return item.name === "ComponentText" || item.name === "DrawToolText" || item.name === "ContactPoint";
}

export function isWireItem(itemName: DrawingList["name"]): itemName is "Wire" {
    return itemName === "Wire";
}
export function isDrawItem(itemName: DrawingList["name"]): itemName is DrawList["name"] {
    switch (itemName) {
        case "DrawToolLine":
        case "DrawToolArc":
        case "DrawToolArcCenter":
        case "DrawToolBezier":
            return true;
        default: return false;
    }
}
export function isOnePointItem(itemName: DrawingList["name"]): itemName is TextItem["name"] | ContactPointItem["name"] {
    switch (itemName) {
        case "ComponentText":
        case "DrawToolText":
        case "ContactPoint":
            return true;
        default: return false;
    }
}
export function isSeveralPointsItem(itemName: DrawingList["name"]): itemName is ArrowItem["name"] {
    switch (itemName) {
        case "DrawToolVoltageArrow":
        case "DrawToolCurrentArrow":
        case "CurrentArrow":
            return true;
        default: return false;
    }
}


export function isPointInArea(point: Point, area: Area): boolean {
    return point.x >= area.x
        && point.x <= area.x + area.width
        && point.y >= area.y
        && point.y <= area.y + area.height;
}

function getPoint(itemPoint: number | undefined, points: Point[]): null | Point {
    if (typeof itemPoint === "number") {
        return points[itemPoint] || null;
    }
    return null;
}

export function getAllPointsFromItem(
    item: GenericListItem, points: Point[], areaPoints = false, parentItem?: GenericListItem,
): Point[] {
    const pointsFromItem: Point[] = [];

    const point = getPoint(item.point, points);
    if (point) pointsFromItem.push(point);

    if (item.points) {
        for (const pointIndex of item.points) {
            const point = getPoint(pointIndex, points);
            if (point) pointsFromItem.push(point);
        }
    }

    if (item.list) {
        for (const listItem of item.list) {
            pointsFromItem.push(...getAllPointsFromItem(listItem, points, areaPoints, item));
        }
    }

    if (!areaPoints) {
        // not include texts (tag/value/name point) to area, they are alone
        if (isComponentItem(item)) {
            const tagPoint = getPoint(item.tag.point, points);
            const valuePoint = getPoint(item.value.point, points);
            if (tagPoint) pointsFromItem.push(tagPoint);
            if (valuePoint) pointsFromItem.push(valuePoint);
        }
        if (isContactPointItem(item)) {
            const nameObjectPoint = getPoint(item.nameObject, points);
            if (nameObjectPoint) pointsFromItem.push(nameObjectPoint);
        }
    }

    if (areaPoints) {
        // Text
        if (isTextItem(item)) {
            const area = getTextArea(item, points);
            pointsFromItem.push(
                { x: area.x, y: area.y },
                { x: area.x, y: area.y + area.height },
                { x: area.x + area.width, y: area.y },
                { x: area.x + area.width, y: area.y + area.height },
            );
        }

        // Arc
        if (isDrawToolArc(item) && parentItem?.name === "Draw") {
            let startPoint = points[parentItem.point || 0];
            for (const itemDraw of parentItem.list || []) {
                if (itemDraw === item)
                    break;

                const itemDrawPoints = itemDraw.points || [];
                startPoint = points[itemDrawPoints[itemDrawPoints.length - 1]];
            }
            const arc = getArcFromPoints(item, item.name === "DrawToolArcCenter", startPoint, points);

            let start = Math.ceil(arc.startAngle / Math.PI * 2);
            let end = Math.floor(arc.endAngle / Math.PI * 2);
            if (arc.counterClockwise) {
                start = Math.ceil(arc.endAngle / Math.PI * 2);
                end = Math.floor(arc.startAngle / Math.PI * 2) + 4;
            }
            for (let i = start; i <= end; i++) {
                pointsFromItem.push({
                    x: arc.x + Math.cos(i * Math.PI / 2) * arc.radius,
                    y: arc.y + Math.sin(i * Math.PI / 2) * arc.radius,
                });
            }
        }
    }

    return pointsFromItem;
}

export function getItemArea(item: ListItem, points: Point[]): Area {
    const allPoints = getAllPointsFromItem(item, points, true);
    if (allPoints.length === 0) return { x: 0, y: 0, width: 0, height: 0 };
    if (allPoints.length === 1) return { x: allPoints[0].x - 4, y: allPoints[0].y - 4, width: 8, height: 8 };

    const minX = Math.min(...allPoints.map(p => p.x));
    const minY = Math.min(...allPoints.map(p => p.y));
    const maxX = Math.max(...allPoints.map(p => p.x));
    const maxY = Math.max(...allPoints.map(p => p.y));

    return {
        x: minX,
        y: minY,
        width: maxX - minX,
        height: maxY - minY,
    };
}

function getTextArea(item: TextItem, points: Point[]): Area {
    const size = getTextSize(item);
    const point = points[item.point];

    let x = 0;
    switch (item.style.fontAlignVertical) {
        case "left":
            x = point.x;
            break;
        case "center":
            x = point.x - size.width / 2;
            break;
        case "right":
            x = point.x - size.width;
            break;
    }

    let y = 0;
    switch (item.style.fontAlignHorizontal) {
        case "top":
            y = point.y;
            break;
        case "middle":
            y = point.y - size.height / 2;
            break;
        case "alphabetic":
            y = point.y - size.height - size.actualBoundingBoxDescent;
            break;
        case "bottom":
            y = point.y - size.height;
            break;
    }

    return { x, y, width: size.width, height: size.height };
}

export function isItemInArea(item: GenericListItem, points: Point[], area: Area): boolean {
    for (const point of getAllPointsFromItem(item, points, true)) {
        if (isPointInArea(point, area)) return true;
    }

    return false;
}

export function isItemAtPoint(item: ListItem, points: Point[], point: Point): boolean {
    if (item.name === "Wire") {
        const wirePoints = item.points.map(p => points[p]);
        const middlePoint = getWireMiddlePoint(item, points);
        return isBetweenPoints(point, wirePoints[0], middlePoint) || isBetweenPoints(point, wirePoints[1], middlePoint);
    } else {
        const itemArea = getItemArea(item, points);
        return isPointInArea(point, itemArea);
    }
}

function isBetweenPoints(point: Point, p1: Point, p2: Point): boolean {
    const area: Area = {
        x: Math.min(p1.x, p2.x),
        y: Math.min(p1.y, p2.y),
        width: Math.abs(p1.x - p2.x),
        height: Math.abs(p1.y - p2.y),
    };
    return isPointInArea(point, area);
}

export function getCopyItem<T extends GenericListItem>(
    item: T, getPointIndex: (pointIndex: PointIndex) => PointIndex,
): T {
    const copyItem = { ...item };

    if (typeof copyItem.point === "number")
        copyItem.point = getPointIndex(copyItem.point);

    if (copyItem.points)
        copyItem.points = copyItem.points.map(p => getPointIndex(p));

    if (copyItem.list)
        copyItem.list = copyItem.list.map(i => getCopyItem(i, getPointIndex));

    if (isComponentItem(copyItem)) {
        copyItem.tag = { ...copyItem.tag, point: getPointIndex(copyItem.tag.point) };
        copyItem.value = { ...copyItem.value, point: getPointIndex(copyItem.value.point) };
    }
    if (isContactPointItem(copyItem))
        copyItem.nameObject = getPointIndex(copyItem.nameObject);

    return copyItem;
}

// ------------ measuring -------------
const textSizeCanvas = document.createElement("canvas");
const textSizeCtx = textSizeCanvas.getContext("2d") as CanvasRenderingContext2D;
export function getTextSize(item: TextItem) {
    if (!item.text)
        return { width: 0, height: 0, actualBoundingBoxAscent: 0, actualBoundingBoxDescent: 0 };

    applyStyleText(textSizeCtx, item.style, 1);
    const size = textSizeCtx.measureText(item.text);

    return {
        width: size.width,
        height: size.actualBoundingBoxAscent - size.actualBoundingBoxDescent,
        actualBoundingBoxAscent: size.actualBoundingBoxAscent,
        actualBoundingBoxDescent: size.actualBoundingBoxDescent,
    };
}