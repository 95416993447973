import { createContext, useContext, useState, ReactNode, useMemo } from "react";
import { useContextDevTool } from "../services/common";

import { useProjectLoadWithURL } from "../services/project";
import { useKeyboard } from "../services/keyboard";

import { Store } from "../types";
import { getEmptyStore } from "../services/default";



const StoreContext = createContext<{ store: Store, setStore: (store: Store | ((store: Store) => Store)) => void }>({
    store: getEmptyStore(),
    setStore: () => null,
});

export function StoreProvider({ children }: { children: ReactNode }) {
    const [store, setStoreProvider] = useState<Store>(getEmptyStore());

    const setStore = useMemo(() => (store: Store | ((store: Store) => Store)) => {
        // TODO: check project settings and set headers here
        if (typeof store === "function") {
            setStoreProvider(currentStore => handleHistoryAction(store(currentStore)));
        } else {
            setStoreProvider(handleHistoryAction(store));
        }
    }, []);

    // load project from URL
    useProjectLoadWithURL(setStore);

    useKeyboard(setStore);

    // do not show mouse position in dev tools
    useContextDevTool("store", store, false);

    return <StoreContext.Provider value={{ store, setStore }}>{children}</StoreContext.Provider>;
}

export function useStore() {
    return useContext(StoreContext);
}

// ----------------- history ------------------

function handleHistoryAction(store: Store): Store {
    const { history, historyAction, project, ...others } = store;
    // default is "no-record"
    if (historyAction === "no-record") {
        return store;
    } else if (historyAction === "record") {
        // when user is back, remove backed projects
        let historyProjects = history.projects.slice(history.index);
        // add new record
        historyProjects = [project, ...historyProjects];
        // keep only 50 records
        historyProjects = historyProjects.slice(0, 50);

        return {
            ...others,
            project,
            history: {
                index: 0,
                projects: historyProjects,
            },
            historyAction: "no-record",
        };
    } else if (historyAction === "front" || historyAction === "back") {
        let newIndex = historyAction === "front" ? history.index - 1 : history.index + 1;
        if (newIndex < 0) newIndex = 0;
        if (newIndex > history.projects.length - 1) newIndex = history.projects.length - 1;

        if (newIndex === history.index) {
            // no change
            return {
                ...others,
                project,
                history,
                historyAction: "no-record",
            };
        } else {
            return {
                ...others,
                project: history.projects[newIndex],
                history: {
                    ...history,
                    index: newIndex,
                },
                historyAction: "no-record",
            };
        }
    } else {
        throw Error(`Unreachable, unknown history action: ${historyAction}.`);
    }
}