import { createTheme, ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";

import { StoreProvider } from "./components/Store";
import Layout from "./components/Layout";

import "katex/dist/katex.min.css";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0065BD", // CVUT main
        },
        secondary: {
            //   // This is green.A700 as hex.
            // main: "#A2AD00", // CVUT sec ondary
            main: "#6AADE4", // CVUT light
        },
        background: {
            paper: "#f5f5f6",
        },
    },
});


export default function App() {
    return <ThemeProvider theme={theme}>
        <SnackbarProvider preventDuplicate maxSnack={5} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
            <StoreProvider>
                <Layout />
            </StoreProvider>
        </SnackbarProvider>
    </ThemeProvider>;
}
