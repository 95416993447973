import { SimulationRawData } from "../core/core_draft";
import { AnalysisCircuit, AnalysisConfig } from "../types";
import { Result, SimResultExpression } from "../types/analysis";


export function symcircAdvGenerateScript(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    proj: AnalysisCircuit, simSettings: AnalysisConfig, reqRslt: SimResultExpression[]): string {

    const script = simSettings.config.script;

    // TODO:
    // script = replaceExportMacros(script);
    // script = replaceImportMacros(proj, GEEC_IMPORT_BY_ID_MACRO, script);
    // script = replaceImportMacros(proj, GEEC_IMPORT_BY_NAME_MACRO, script);

    return script;
}

export function symcircAdvGetFinalResults(
    requestedtRslt: SimResultExpression[], computedRslt: SimulationRawData[]): Result[] {
    return computedRslt.map((rslt, idx) => { return { id: idx, ...rslt, format: "Exp" }; });
}
