import { useState } from "react";
import { groupBy, sortBy } from "lodash";
import {
    mdiArrowTopRight,
    mdiArrowTopRightThin,
    mdiCableData, mdiChartLineVariant, mdiCircleMedium, mdiCircleOutline,
    mdiCursorDefaultOutline, mdiFormatText, mdiRecordCircleOutline, mdiVectorBezier,
} from "@mdi/js";
import { ListCollapse, ListItem, ListVertical } from ".";

import { dbSelect, getComponent, getComponentList } from "../services/api";
import { LinearProgress } from "@mui/material";

import { clearAction, isSelectMove, pasteComponent, useAction } from "../services/action";
import { useAsyncEffect } from "../services/common";
import { setNewDrawingItem } from "../services/draw";

import { ComponentPreview, DBCategory } from "../types";
import { useStore } from "./Store";

export default function LeftMenu() {
    const { store } = useStore();
    const { project } = store;
    const { action, setAction } = useAction();

    const [basicComponents, setBasicComponents] = useState<null | ComponentPreview[]>(null);
    const [categoryComponents, setCategoryComponents] = useState<null | Record<string, ComponentPreview[]>>(null);

    useAsyncEffect(async (isMountedRef) => {
        const categories = await dbSelect<DBCategory>("category");
        const components = await getComponentList();

        if (isMountedRef.current)
            setBasicComponents(sortBy(components.filter(c => c.basic), c => c.name));

        function getCategoryName(id: number) {
            for (const category of categories) {
                if (category.id === id) {
                    return category.name;
                }
            }
            return "Unknown category";
        }

        if (isMountedRef.current)
            setCategoryComponents(groupBy(components, c => getCategoryName(c.category)));
    }, []);

    async function setComponent(id: number) {
        const { component, points } = await getComponent(id);
        setAction(pasteComponent(store, component, points));
    }

    return <>
        <ListVertical title="General tools">
            <ListItem text="Select / Move" icon={mdiCursorDefaultOutline}
                selected={isSelectMove(action)}
                onClick={() => setAction(clearAction(action))} />
            <ListItem text="Wire (w)" icon={mdiCableData}
                selected={action.drawing?.itemName === "Wire"}
                onClick={() => setAction(setNewDrawingItem("Wire", action))}
            />
        </ListVertical>

        <ListVertical title="Components">
            {basicComponents === null && <LinearProgress />}
            {basicComponents !== null && basicComponents.map(component => <ListItem
                key={component.id}
                text={component.name}
                icon={component.icon}
                onClick={() => setComponent(component.id)}
            />)}
        </ListVertical>

        <ListVertical title="Categories">
            {categoryComponents === null && <LinearProgress />}
            {categoryComponents !== null && Object.entries(categoryComponents).map(([name, components]) => <ListCollapse
                key={name}
                text={name}
            >
                {components.map(component => <ListItem
                    key={component.id}
                    text={component.name}
                    icon={component.icon}
                    onClick={() => setComponent(component.id)}
                />)}
            </ListCollapse>)}
        </ListVertical>

        <ListVertical title="Drawing">
            <ListItem text="Line" icon={mdiChartLineVariant}
                selected={action.drawing?.itemName === "DrawToolLine"}
                onClick={() => setAction(setNewDrawingItem("DrawToolLine", action))} />
            <ListItem text="Bezier" icon={mdiVectorBezier}
                selected={action.drawing?.itemName === "DrawToolBezier"}
                onClick={() => setAction(setNewDrawingItem("DrawToolBezier", action))} />
            <ListItem text="Arc" icon={mdiCircleOutline}
                selected={action.drawing?.itemName === "DrawToolArc"}
                onClick={() => setAction(setNewDrawingItem("DrawToolArc", action))} />
            <ListItem text="Arc center" icon={mdiRecordCircleOutline}
                selected={action.drawing?.itemName === "DrawToolArcCenter"}
                onClick={() => setAction(setNewDrawingItem("DrawToolArcCenter", action))} />

            <ListItem text="Text" icon={mdiFormatText}
                selected={action.drawing?.itemName === "DrawToolText"}
                onClick={() => setAction(setNewDrawingItem("DrawToolText", action))} />

            {project.type === "component" && <ListItem text="Contact point" icon={mdiCircleMedium}
                selected={action.drawing?.itemName === "ContactPoint"}
                onClick={() => setAction(setNewDrawingItem("ContactPoint", action))} />}

            {project.type === "component" && <ListItem text="Current arrow" icon={mdiArrowTopRightThin}
                selected={action.drawing?.itemName === "CurrentArrow"}
                onClick={() => setAction(setNewDrawingItem("CurrentArrow", action))} />}
            {project.type === "circuit" && <ListItem text="Current arrow" icon={mdiArrowTopRightThin}
                selected={action.drawing?.itemName === "DrawToolCurrentArrow"}
                onClick={() => setAction(setNewDrawingItem("DrawToolCurrentArrow", action))} />}
            <ListItem text="Voltage arrow" icon={mdiArrowTopRight}
                selected={action.drawing?.itemName === "DrawToolVoltageArrow"}
                onClick={() => setAction(setNewDrawingItem("DrawToolVoltageArrow", action))} />
        </ListVertical>
    </>;
}
