import { 
    Component, ComponentListItem, ComponentProperty, 
    ContactPointItem, GEECAnalysis, Project, Store } from "../types";
import { 
    AnalysisCircuit, AnalysisComponent, AnalysisType, ComponentClass, ComponentInputProperty, 
    ComponentOutputProperty, Simulation } from "../analysis/types";
import { runSimulation } from "../analysis/analysis";

// import { runNgSpice } from "../simulations/ngspice";

// TODO: only for tests (wasm)
// async function handleNgSpice() {
//     console.log("NetList:");
//     const netlist = getNetlist(store.project.list, "spice");
//     console.log(netlist);
//     console.log("Start simulation...");
//     const analysis = await runNgSpice(netlist, line => console.log(line));
//     console.log(analysis);
//     console.log("End simulation...");
// }

export async function runAnalysis(proj: Project, simSettings: Simulation): Promise<GEECAnalysis> {
    const aproj = getAnalysisProject(proj);
    const sim = await runSimulation(aproj, simSettings);

    return {
        ...simSettings,
        results: sim.results,
        script: sim.script,
        output: sim.output,
        error: sim.error,
    };
}

export async function handleGeneralAnalysis(store: Store, type: AnalysisType) {
    const opSettings: Simulation = {
        name: type,
        analysisType: type,
        substitutions: [],
        assigns: [],
        userResults: [],
        noAdditionalResults: false,
        config: {},
    };
    const sim = await runAnalysis(store.project, opSettings);
    return sim;
}


// /** temporary for testing */
// export async function handleNGSpiceOP(store: Store) {
//     const opSettings: Simulation = {
//         name: "NGSpiceOP",
//         analysisType: "NGSpiceOP",
//         substitutions: [],
//         assigns: [],
//         userResults: [],
//         noAdditionalResults: false,
//         config: {},
//     };
//     const sim = await runAnalysis(store.project, opSettings);
//     // console.log(sim.results);
//     //e.g. Result 
//     //{"id": 1,"name": "v(V1)","value": "1.000000e+01",:valueType": "Real","dataType": "Value","format": "Exp"}
//     return sim;
// }

// /** temporary for testing */
// export async function handleNGSpiceAC(store: Store) {
//     const opSettings: Simulation = {
//         name: "NGSpiceAC",
//         analysisType: "NGSpiceAC",
//         substitutions: [],
//         assigns: [],
//         userResults: [],
//         noAdditionalResults: false,
//         config: {},
//     };
//     const sim = await runAnalysis(store.project, opSettings);
//     // console.log(sim.results);
//     return sim;
// }

// /** temporary for testing */
// export async function handleSymbolicOP(store: Store) {
//     const opSettings: Simulation = {
//         name: "PracanOPSym",
//         analysisType: "PracanOPSym",
//         substitutions: [],
//         assigns: [],
//         userResults: [],
//         noAdditionalResults: false,
//         config: {},
//     };
//     const sim = await runAnalysis(store.project, opSettings);
//     // console.log(sim.results);
//     //{"id": 7,"name": "i(R2)","value": "\\frac{V_{1}}{R_{1} + R_{2}}",
//     // "valueType": "Real","dataType": "Value","format": "Symbolic"}
//     return sim;
// }

// /** temporary for testing */
// export async function handleSycircOP(store: Store) {
//     const opSettings: Simulation = {
//         name: "SymcircOPSym",
//         analysisType: "SymcircOPSym",
//         substitutions: [],
//         assigns: [],
//         userResults: [],
//         noAdditionalResults: false,
//         config: {},
//     };
//     const sim = await runAnalysis(store.project, opSettings);
//     // console.log(sim.results);
//     return sim;
// }

/******************************************* Analysis deprecated ************************************************** */
// Helper functions: until component changes are properly tested and integrated into GEEC!!

import { 
    newBattery, newBJT, newCapacitor, newCCCS, newCCVS, newCurrentSource, newDiode, newIdealOA,
    newInductor, newMOS, newResistor, newSinCurrentSource, newSinVoltageSource,
    newVCCS, newVCVS, newVoltageSource, newXInteractiveShunt,
} from "../analysis/test/components";
import { getComponentsFromList } from "./list";


/** Converts GEEC V5 component to V6 analysis component */
export function getAnalysisComponentV6(component: Component): AnalysisComponent {
    const cp = component.list.filter((item: ComponentListItem) => item.name === "ContactPoint") as ContactPointItem[];
    const cpNew = cp.map(point => point.namePoint.toString());
    const { input, output, componentClass } = getNewComponentProperties(component, cpNew);

    return {
        name: component.componentName,
        class: componentClass,
        tag: component.tag.text || "",
        inputProperties: input,
        outputProperties: output,
        contactPoints: cpNew,
        allowance: component.allowance,
    };
}

/** Converts GEEC project to analysis project */
export function getAnalysisProject(project: Project): AnalysisCircuit {
    if (project.type === "circuit") {
        const components = getComponentsFromList(project.list);
        const componentsNew = components.map(item => getAnalysisComponentV6(item));

        return {
            title: project.title,
            components: componentsNew,
            designVariables: [],
            parametricRanges: [],
            analyses: [],
        };
    } else {
        throw Error("Invalid project type for analysis");
    }
}


/** Local helper function to get safely property by index */
function getPropByIndex(arr: ComponentProperty[], index: number): ComponentProperty {
    if (index < arr.length) {
        return arr[index];
    } else {
        return {
            description: "",
            label: "",
            param: false,
            value: "0",
            show: false,
            sign: false,
            used: true,
        };
    }
}


type NewComponentProperties = {
    input: ComponentInputProperty[], 
    output: ComponentOutputProperty[], 
    componentClass: ComponentClass,
}

/** Helper function to get new component properties definition
 * @param comp - component
 * @param cp - contact points
 * @returns new component properties { ComponentInputProperty[], ComponentOutputProperty[] }
*/
export function getNewComponentProperties(comp: Component, cp: string[]): NewComponentProperties {

    const tag = comp.tag.text || "";
    const inputProps = comp.properties;
    //const outputProps = comp.analysisProperties;

    let newComponent: AnalysisComponent = {
        class: "Undefined",
        tag: tag,
        inputProperties: [],
        outputProperties: [],
        contactPoints: [],
        allowance: "",
    };

    switch (comp.componentName) {
        case "Resistor":
        {
            newComponent = newResistor(tag, cp, getPropByIndex(inputProps, 0).value);
            break;
        }
        case "Capacitor":
        {
            newComponent = newCapacitor(tag, cp, getPropByIndex(inputProps, 0).value);
            break;
        }
        case "Inductor":
        {
            newComponent = newInductor(tag, cp, getPropByIndex(inputProps, 0).value);
            break;
        }
        case "Battery":
        {
            newComponent = newBattery(tag, cp, getPropByIndex(inputProps, 0).value);
            break;
        }
        case "Voltage source":
        {
            const dc = getPropByIndex(inputProps, 0).value;
            const ac = getPropByIndex(inputProps, 1).value;
            newComponent = newVoltageSource(tag, cp, dc, ac);
            break;
        }
        case "Current source":
        {
            const dc = getPropByIndex(inputProps, 0).value;
            const ac = getPropByIndex(inputProps, 1).value;
            newComponent = newCurrentSource(tag, cp, dc, ac);
            break;
        }
        case "Sinusoidal Voltage Source":
        {
            const va = getPropByIndex(inputProps, 4).value;
            const f = getPropByIndex(inputProps, 5).value;
            newComponent = newSinVoltageSource(tag, cp, va, f);
            break;
        }
        case "Sinusoidal Current Source":
        {
            const va = getPropByIndex(inputProps, 4).value;
            const f = getPropByIndex(inputProps, 5).value;
            newComponent = newSinCurrentSource(tag, cp, va, f);
            break;
        }
        case "CCS_user":
        {
            const val0 = getPropByIndex(inputProps, 0).value;
            const val1 = getPropByIndex(inputProps, 1).value;

            if (tag.toLocaleLowerCase().startsWith("g")) {
                const val2 = getPropByIndex(inputProps, 2).value;
                newComponent = newVCCS(tag, cp, val0, val1, val2);
                break;
            } else if (tag.toLocaleLowerCase().startsWith("f")) {
                newComponent = newCCCS(tag, cp, val0, val1);
                break;
            } else {
                throw new Error("Unsupported CCS_user");
            }
        }
        case "CVS_user":
        {
            const val0 = getPropByIndex(inputProps, 0).value;
            const val1 = getPropByIndex(inputProps, 1).value;

            if (tag.toLocaleLowerCase().startsWith("e")) {
                const val2 = getPropByIndex(inputProps, 2).value;
                newComponent = newVCVS(tag, cp, val0, val1, val2);
                break;
            } else if (tag.toLocaleLowerCase().startsWith("h")) {
                newComponent = newCCVS(tag, cp, val0, val1);
                break;
            } else {
                throw new Error("Unsupported CVS_user");
            }
        }
        case "Interactive Shunt":
        {
            const mod = getPropByIndex(inputProps, 0).value;
            newComponent = newXInteractiveShunt(tag, cp, mod);
            break;
        }
        case "Interactive Switch":
        {
            const mod = getPropByIndex(inputProps, 0).value;
            newComponent = newXInteractiveShunt(tag, cp, mod);
            break;
        }
        case "Diode":
        {
            newComponent = newDiode(tag, cp, getPropByIndex(inputProps, 0).value);
            break;
        }
        case "NPN":
        case "PNP":
        {
            newComponent = newBJT(tag, cp, getPropByIndex(inputProps, 0).value);
            break;
        }
        case "MOSFET N":
        case "MOSFET P":
        {
            const mod = getPropByIndex(inputProps, 0).value;
            const l = getPropByIndex(inputProps, 1).value;
            const w = getPropByIndex(inputProps, 2).value;
            newComponent = newMOS(tag, cp, mod, l, w);
            break;
        }
        case "Ideal OpAmp":
        {
            newComponent = newIdealOA(tag, cp);
            break;
        }
        default:
            break;
    }
    
    return { 
        input: newComponent.inputProperties, 
        output: newComponent.outputProperties,
        componentClass: newComponent.class,
    };
}
