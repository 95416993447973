/* eslint-disable @typescript-eslint/no-unused-vars */
import { AnalysisCircuit, AnalysisConfig, Result, SimResultExpression } from "../types/analysis";
import { getGlobalAnalysisData, parseScriptMacro } from "../utils/utilities";


const GEEC_INIT_MACRO = "GEEC_INIT";
const GEEC_EXPORT_MACRO = "GEEC_EXPORT";
const GEEC_IMPORT_BY_ID_MACRO = "GEEC_IMPORT_BY_ID";
const GEEC_IMPORT_BY_NAME_MACRO = "GEEC_IMPORT_BY_NAME";

export function ngspiceAdvGenerateScript(
    proj: AnalysisCircuit, simSettings: AnalysisConfig, reqRslt: SimResultExpression[]): string {

    let script = simSettings.config.script;

    script = replaceExportMacros(script);
    script = replaceImportMacros(proj, GEEC_IMPORT_BY_ID_MACRO, script);
    script = replaceImportMacros(proj, GEEC_IMPORT_BY_NAME_MACRO, script);

    return script;
}

function replaceExportMacros(script: string): string {
    const newLines = script.split("\n").map((line: string) => {
        if (line.startsWith("*")) { return line; }

        const args = parseScriptMacro(GEEC_EXPORT_MACRO, line);
        if (args !== null && args.length === 1) {
            const exportVariable = args[0];
            return `print line ${exportVariable} >> {{OUT}}`;
        } else {
            return line;
        }
    });

    return newLines.join("\n");
}

function replaceImportMacros(proj: AnalysisCircuit, macro: string, script: string): string {
    const newLines = script.split("\n").map((line: string) => {
        if (line.startsWith("*")) { return line; }

        const args = parseScriptMacro(macro, line);
        if (args !== null && args.length === 2) {
            const func = (macro === GEEC_IMPORT_BY_ID_MACRO) ? "getDataByID" : "getDataByName";
            const rslt = getGlobalAnalysisData(proj, `${func}(${args[0]},${args[1]})`);

            const reg = new RegExp(`^(.*)\\s*=\\s*@${macro}`);
            const match = line.match(reg);

            if (match) {
                const varName = match[1].trim();

                if (rslt.format !== "Exp" && rslt.format !== "Float") {
                    throw new Error("NGSpiceAdvanced: GEEC_IMPORT* of Exp or Float data not implemented");
                }

                if (rslt.dataType === "Value") {
                    return `let ${varName}=${rslt.value}`;

                } else if (rslt.dataType === "Array") {

                    if (rslt.valueType === "Real") {
                        return `compose ${varName} values ${(rslt.value as string[]).join(" ")}`;
                    } else if (rslt.valueType === "ReIm") {
                        throw new Error("NGSpiceAdvanced: GEEC_IMPORT* of ReIm data not implemented");
                    }
                }
            } else {
                throw new Error("NGSpiceAdvanced: GEEC_IMPORT* Parsing error");
            }
        } else {
            return line;
        }
    });

    return newLines.join("\n");
}

export function ngspiceAdvGetFinalResults(requestedtRslt: SimResultExpression[], computedRslt: Result[]): Result[] {
    return computedRslt.map((rslt, idx) => { return {  ...rslt, id: idx}; });
}
