import { getNGSpiceAnalysisHandler } from "./ngspice/ngspice_draft";
import { symcircGetAnalysisHandler } from "./symcirc/symcirc_draft";
import { pracanGetAnalysisHandler } from "./pracan/pracan_draft";
import { AnalysisHandler } from "./core/analysisHandler";
import { AnalysisType } from "./types/analysis";
import { getFFTAnalysisHandler } from "./core/fft";

/**
 * Global dispatch function for getting the correct AnalysisHandler.
 * @param analysisType
 * @returns
 */
export function getAnalysisHandler(analysisType: AnalysisType): AnalysisHandler {
    switch (analysisType) {
        case "NGSpiceOP":
        case "NGSpiceAC":
        case "NGSpiceTranGraph":
        case "NGSpiceACGraph":
        case "NGSpiceAdvanced":
            return getNGSpiceAnalysisHandler(analysisType);
        case "PracanOPSym":
        case "PracanOPSemi":
        case "PracanDCGraph":
        case "PracanACSym":
        case "PracanACSemi":
        case "PracanACGraph":
        case "PracanTranSym":
        case "PracanTranSemi":
        case "PracanTranGraph":
        case "PracanTFSym":
        case "PracanTFSemi":
        case "PracanTFGraph":
        case "PracanAdvanced":
            return pracanGetAnalysisHandler(analysisType);
        case "SymcircOPSym":
        case "SymcircOPSemi":
        case "SymcircACSym":
        case "SymcircACSemi":
        case "SymcircACGraph":
        case "SymcircTranSym":
        case "SymcircTranSemi":
        case "SymcircTranGraph":
        case "SymcircTFSym":
        case "SymcircTFSemi":
        case "SymcircTFGraph":
        case "SymcircAdvanced":
            return symcircGetAnalysisHandler(analysisType);
        case "GenericFFT":
            return getFFTAnalysisHandler();
        default:
            throw new Error(`Analysis type ${analysisType} not supported`);
    }
}
