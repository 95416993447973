import { useEffect } from "react";

import { changeStyle, clearAction, copy, deselectAllItems, paste } from "./action";
import { getSelectedList, removeFromList } from "./list";
import { setNewDrawingItem } from "./draw";

import { Store, StyleText } from "../types";


export function useKeyboard(setStore: (store: (prev: Store) => Store) => void) {
    useEffect(() => {
        function handleKeyDown(event: KeyboardEvent) {
            const key = event.key.toLowerCase();

            setStore(store => {
                const keyboard = { ...store.action.keyboard };
                if (key === "control") {
                    keyboard.ctrl = true;
                } else if (key === "meta") {
                    keyboard.cmd = true;
                } else if (key === "shift") {
                    keyboard.shift = true;
                } else if (key === "alt") {
                    keyboard.alt = true;
                } else {
                    keyboard.key = key;
                }

                return handleShortcuts(event, key, {
                    ...store,
                    action: {
                        ...store.action,
                        keyboard,
                    },
                });
            });
        }
        function handleKeyUp(event: KeyboardEvent) {
            const key = event.key.toLowerCase();

            setStore(store => {
                const keyboard = { ...store.action.keyboard };
                if (key === "control") {
                    keyboard.ctrl = false;
                } else if (key === "meta") {
                    keyboard.cmd = false;
                } else if (key === "shift") {
                    keyboard.shift = false;
                } else if (key === "alt") {
                    keyboard.alt = false;
                }
                keyboard.key = null;

                return {
                    ...store,
                    action: {
                        ...store.action,
                        keyboard,
                    },
                };
            });
        }
        function handlePaste(event: ClipboardEvent) {
            const clipboardData = event.clipboardData;
            if (!clipboardData) return;

            event.preventDefault();
            setStore(store => paste(store, clipboardData));
        }

        document.addEventListener("keydown", handleKeyDown, false);
        document.addEventListener("keyup", handleKeyUp, false);
        document.addEventListener("paste", handlePaste, false);

        return () => {
            document.removeEventListener("keydown", handleKeyDown, false);
            document.addEventListener("keyup", handleKeyUp, false);
            document.addEventListener("paste", handlePaste, false);
        };
    }, []);
}

function handleShortcuts(event: KeyboardEvent, key: string, store: Store): Store {
    const { activeCanvas } = store.action;

    if (store.action.keyboard.cmd || store.action.keyboard.ctrl) {
        // CTRL+Y for win/linux and CMD+SHIFT+Z for MAC
        if (key === "y" || (store.action.keyboard.shift && key === "z")) {
            event.preventDefault();
            return {
                ...store,
                historyAction: "front",
            };
        }
        if (key === "z") {
            event.preventDefault();
            return {
                ...store,
                historyAction: "back",
            };
        }

        if (activeCanvas && key === "c") {
            // copy
            event.preventDefault();
            return copy(store);
        }
        if (activeCanvas && key === "v") {
            // paste
            // handled with document addEventListener "paste"
        }

        if (activeCanvas && key.startsWith("arrow")) {
            event.preventDefault();
            const { canvas } = store.project;
            switch (key) {
                case "arrowup":
                    return {
                        ...store, project: {
                            ...store.project, canvas: {
                                ...canvas,
                                shift: { ...canvas.shift, y: canvas.shift.y - (canvas.zoom * 100) },
                            },
                        },
                    };
                case "arrowdown":
                    return {
                        ...store, project: {
                            ...store.project, canvas: {
                                ...canvas,
                                shift: { ...canvas.shift, y: canvas.shift.y + (canvas.zoom * 100) },
                            },
                        },
                    };
                case "arrowleft":
                    return {
                        ...store, project: {
                            ...store.project, canvas: {
                                ...canvas,
                                shift: { ...canvas.shift, x: canvas.shift.x - (canvas.zoom * 100) },
                            },
                        },
                    };
                case "arrowright":
                    return {
                        ...store, project: {
                            ...store.project, canvas: {
                                ...canvas,
                                shift: { ...canvas.shift, x: canvas.shift.x + (canvas.zoom * 100) },
                            },
                        },
                    };
            }
        }

    } else if (activeCanvas && key === "escape") {
        if (store.action.drawing || store.action.paste) {
            return {
                ...store,
                project: deselectAllItems(store.project),
                action: clearAction(store.action),
            };
        }

    } else if (activeCanvas && (key === "backspace" || key === "delete")) {
        const selectedList = getSelectedList(store.project.list);
        if (selectedList) {
            return {
                ...store,
                project: {
                    ...store.project,
                    list: removeFromList(store.project.list, selectedList),
                },
                historyAction: "record",
            };
        }
    } else if (activeCanvas && key === "w") {
        return {
            ...store,
            action: setNewDrawingItem("Wire", store.action),
        };
    } else if (activeCanvas && key === "g") {
        return {
            ...store, project: {
                ...store.project, canvas: {
                    ...store.project.canvas, grid: {
                        ...store.project.canvas.grid,
                        show: !store.project.canvas.grid.show,
                    },
                },
            },
        };

    } else if (activeCanvas && key === "s") {
        const value = store.action.style.text.fontAlignVertical;
        const values: StyleText["fontAlignVertical"][] = ["center", "left", "right"];
        return changeStyle(store, {}, { fontAlignVertical: values[(values.indexOf(value) + 1) % values.length] });

    } else if (activeCanvas && key === "a") {
        const value = store.action.style.text.fontAlignHorizontal;
        const values: StyleText["fontAlignHorizontal"][] = ["alphabetic", "bottom", "middle", "top"];
        return changeStyle(store, {}, { fontAlignHorizontal: values[(values.indexOf(value) + 1) % values.length] });

    } else if (key === "f1") {
        return {
            ...store,
            action: {
                ...store.action,
                help: { show: true, type: "classic" },
            },
        };
    }

    return store;
}