
import {
    Action, ArrowItem, ContactPointItem, DrawItem, DrawList, DrawingList, Point, PointIndex, Store, Style, TextItem,
    WireItem,
} from "../types";
import { clearAction, pasteActionToProject } from "./action";
import { isDrawItem, isOnePointItem, isSeveralPointsItem, isWireItem } from "./item";


export function setNewDrawingItem(itemName: DrawingList["name"], action: Action): Action {

    if (isWireItem(itemName)) {
        action = clearAction(action);
        const wire: WireItem = {
            name: "Wire",
            points: [0, 0],
            direction: "x",
            style: action.style.draw,
        };
        const newPoint: Point = {
            x: action.mouse.x,
            y: action.mouse.y,
        };
        return {
            ...action,
            drawing: {
                itemName,
                item: wire,
                activePoint: 0,
                itemPointIndex: 0,
                points: [newPoint],
                isEnd: false,
            },
        };

    } else if (isDrawItem(itemName)) {
        return setNewDrawItem(itemName, action);

    } else if (isOnePointItem(itemName)) {
        action = clearAction(action);

        const newPoint: Point = {
            x: action.mouse.x,
            y: action.mouse.y,
        };
        return {
            ...action,
            drawing: {
                itemName,
                item: drawingListFactory(itemName, 0, action.style) as TextItem | ContactPointItem,
                activePoint: 0,
                itemPointIndex: 0,
                points: [newPoint],
                isEnd: false,
            },
        };

    } else if (isSeveralPointsItem(itemName)) {
        action = clearAction(action);

        const newPoint: Point = {
            x: action.mouse.x,
            y: action.mouse.y,
        };
        return {
            ...action,
            drawing: {
                itemName,
                item: drawingListFactory(itemName, 0, action.style) as ArrowItem,
                activePoint: 0,
                itemPointIndex: 0,
                points: [newPoint],
                isEnd: false,
            },
        };

    } else {
        throw new Error(`Item ${itemName} is not an known drawing item!`);
    }
}

export function setDrawingPoint(x: number, y: number, action: Action): Action {
    // nothing is drawing
    if (!action.drawing) return action;
    const itemName = action.drawing.itemName;

    if (isWireItem(itemName)) {
        const item = action.drawing.item as WireItem;
        const { activePoint, itemPointIndex, points } = action.drawing;

        if (itemPointIndex === 0) {
            const newActivePoint = points.length;
            const newPoint: Point = {
                x: action.mouse.x,
                y: action.mouse.y,
            };
            return {
                ...action,
                drawing: {
                    itemName,
                    item: {
                        ...item,
                        points: [activePoint, newActivePoint],
                    },
                    activePoint: newActivePoint,
                    itemPointIndex: itemPointIndex + 1,
                    points: [{ x, y }, newPoint],
                    isEnd: false,
                },
            };
        } else {
            // wire done
            return {
                ...action,
                drawing: {
                    ...action.drawing,
                    isEnd: true,
                },
            };
        }

        return action;
    } else if (isDrawItem(itemName)) {
        return setDrawPoint(x, y, action);

    } else if (isOnePointItem(itemName)) {
        return {
            ...action,
            drawing: {
                ...action.drawing,
                points: [...action.drawing.points.map((p) => ({ x: p.x, y: p.y }))], // stop show points
                isEnd: true,
            },
        };

    } else if (isSeveralPointsItem(itemName)) {
        const item = action.drawing.item as ArrowItem;
        const { activePoint, itemPointIndex, points } = action.drawing;

        if (item.points.length - 1 > itemPointIndex) {
            // other point
            const newActivePoint = points.length;
            const newPoint: Point = {
                x: action.mouse.x,
                y: action.mouse.y,
            };
            const newItem = {
                ...item,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                points: item.points.map((p, i) => i <= itemPointIndex ? p : newActivePoint) as any,
            };

            return {
                ...action,
                drawing: {
                    itemName,
                    item: newItem,
                    activePoint: newActivePoint,
                    itemPointIndex: itemPointIndex + 1,
                    points: [...points.map((p, i) => i === activePoint ? { x, y } : p), newPoint],
                    isEnd: false,
                },
            };
        } else {
            // done
            return {
                ...action,
                drawing: {
                    ...action.drawing,
                    points: [...points.map((p) => ({ x: p.x, y: p.y }))], // stop show points
                    isEnd: true,
                },
            };
        }

    } else {
        throw new Error(`Item ${itemName} is not a drawing item!`);
    }
}

export function isDrawingEnd(action: Action): boolean {
    if (!action.drawing) return false;
    return action.drawing.isEnd;
}

export function cancelDrawing(store: Store): Store {
    if (!store.action.drawing) return store;

    if (isDrawItem(store.action.drawing.itemName)) {
        let action = cancelDraw(store.action);
        const project = pasteActionToProject(action, store.project);
        action = clearAction(action);
        return { ...store, action, project };
    } else {
        let project = store.project;
        if (isDrawingEnd(store.action)) {
            project = pasteActionToProject(store.action, store.project);
        }
        const action = clearAction(store.action);
        return { ...store, project, action };
    }
}

function setNewDrawItem(itemName: DrawList["name"], action: Action): Action {
    const { drawing } = action;

    if (drawing?.item.name === "Draw" && drawing.activePoint > 0) {
        // set new tool for draw
        const draw = drawing.item;
        const lastItem = draw.list[draw.list.length - 1];
        const points = drawing.points.filter((_, i) => !lastItem?.points.includes(i));
        const activePoint = points.length;

        const newPoint: Point = {
            x: action.mouse.x,
            y: action.mouse.y,
        };
        const newDraw = {
            ...draw,
            list: [...draw.list.filter((i: unknown) => i !== lastItem), drawListFactory(itemName, activePoint)],
        };

        return {
            ...action,
            drawing: {
                itemName,
                item: newDraw,
                activePoint,
                itemPointIndex: 0,
                points: [...points, newPoint],
                isEnd: false,
            },
        };

    } else {
        // cancel actual drawing and set new Draw
        const activePoint = 0;
        const newDraw: DrawItem = {
            name: "Draw",
            point: activePoint,
            style: action.style.draw,
            list: [],
        };

        const newPoint: Point = {
            x: action.mouse.x,
            y: action.mouse.y,
        };

        return {
            ...action,
            drawing: {
                itemName,
                item: newDraw,
                activePoint,
                itemPointIndex: 0,
                points: [newPoint],
                isEnd: false,
            },
        };
    }
}

function setDrawPoint(x: number, y: number, action: Action): Action {
    // nothing is drawing
    if (!action.drawing) return action;
    const { drawing } = action;

    if (!isDrawItem(drawing.itemName)) throw new Error(`Item ${drawing.itemName} is not a draw item!`);
    const { itemName, itemPointIndex, activePoint, points } = drawing;

    const draw = drawing.item as DrawItem;
    if (!draw) throw new Error("Draw item not found!");

    const editedItem = draw.list.find(item => item.points.includes(activePoint));
    if (!editedItem) {
        // just draw
        const newActivePoint = points.length;
        const newPoint: Point = {
            x: action.mouse.x,
            y: action.mouse.y,
        };
        const newDraw: DrawItem = {
            ...draw,
            list: [drawListFactory(itemName, newActivePoint)],
        };

        return {
            ...action,
            drawing: {
                itemName,
                item: newDraw,
                activePoint: newActivePoint,
                itemPointIndex: 0,
                points: [...points.map((p, i) => i === activePoint ? { x, y } : p), newPoint],
                isEnd: false,
            },
        };

    } else if (editedItem.points.length - 1 > itemPointIndex) {
        // is drawing item
        const newActivePoint = points.length;
        const newPoint: Point = {
            x: action.mouse.x,
            y: action.mouse.y,
        };
        const newItem: DrawList = {
            ...editedItem,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            points: editedItem.points.map((p, i) => i <= itemPointIndex ? p : newActivePoint) as any,
        };
        const newDraw: DrawItem = {
            ...draw,
            list: [...draw.list.filter(i => i !== editedItem), newItem],
        };

        return {
            ...action,
            drawing: {
                itemName,
                item: newDraw,
                activePoint: newActivePoint,
                itemPointIndex: itemPointIndex + 1,
                points: [...points.map((p, i) => i === activePoint ? { x, y } : p), newPoint],
                isEnd: false,
            },
        };
    } else {
        // item is done
        const newActivePoint = points.length;
        const newPoint: Point = {
            x: action.mouse.x,
            y: action.mouse.y,
        };
        const newDraw: DrawItem = {
            ...draw,
            list: [...draw.list, drawListFactory(itemName, newActivePoint)],
        };

        return {
            ...action,
            drawing: {
                itemName,
                item: newDraw,
                activePoint: newActivePoint,
                itemPointIndex: 0,
                points: [...points.map((p, i) => i === activePoint ? { x, y } : p), newPoint],
                isEnd: false,
            },
        };
    }
}

function cancelDraw(action: Action): Action {
    // nothing is drawing
    if (!action.drawing) return action;
    const { drawing } = action;

    if (!isDrawItem(drawing.itemName)) throw new Error(`Item ${drawing.itemName} is not a draw item!`);
    const { activePoint, points, itemName } = drawing;

    const draw = drawing.item as DrawItem;
    if (draw.name !== "Draw") throw new Error("Draw item not found!");

    const editedItem = draw.list.find(item => item.points.includes(activePoint));
    if (!editedItem) {
        // empty draw
        return {
            ...action,
            drawing: null,
        };

    } else {
        const newDraw: DrawItem = {
            ...draw,
            list: [...draw.list.filter(i => i !== editedItem)],
        };
        return {
            ...action,
            drawing: {
                itemName,
                item: newDraw,
                activePoint: draw.point,
                itemPointIndex: 0,
                points: points.filter((_, i) => !editedItem.points.includes(i)),
                isEnd: true,
            },
        };
    }
}


function drawListFactory(itemName: DrawList["name"], point: PointIndex): DrawList {
    switch (itemName) {
        case "DrawToolLine":
            return {
                name: "DrawToolLine",
                points: [point],
            };
        case "DrawToolBezier":
            return {
                name: "DrawToolBezier",
                points: [point, point, point],
            };
        case "DrawToolArc":
        case "DrawToolArcCenter":
            return {
                name: itemName,
                points: [point, point],
            };
    }

    throw new Error(`Draw item: ${itemName} not implemented in factory!`);
}

export function drawingListFactory(itemName: DrawingList["name"], point: PointIndex, style: Style): DrawingList {
    switch (itemName) {
        case "ComponentText":
        case "DrawToolText":
            return {
                name: itemName,
                point,
                rotate: 0,
                style: style.text,
                text: "",
            };
        case "DrawToolLine":
            return {
                name: "DrawToolLine",
                points: [point],
            };
        case "DrawToolBezier":
            return {
                name: "DrawToolBezier",
                points: [point, point, point],
            };
        case "DrawToolArc":
        case "DrawToolArcCenter":
            return {
                name: itemName,
                points: [point, point],
            };
        case "CurrentArrow":
        case "DrawToolCurrentArrow":
        case "DrawToolVoltageArrow":
            return {
                name: itemName,
                points: [point, point],
                style: style.draw,
            };
    }

    throw new Error(`Drawing item: ${itemName} not implemented in factory!`);
}
