import { ContactPointItem, List, Point, PointIndex, WireItem } from "../types";
import { getContactPointsFromList, getWiresFromList, mapList, removeFromList } from "./list";

export function isConnected(point1: PointIndex, point2: PointIndex, wires: WireItem[]) {
    if (point1 === point2) return true;

    const wiresWithPoint1: WireItem[] = [];
    const wiresOther: WireItem[] = [];

    for (const wire of wires) {
        if (wire.points[0] === point1 || wire.points[1] === point1) {
            wiresWithPoint1.push(wire);
        } else {
            wiresOther.push(wire);
        }
    }

    for (const wire of wiresWithPoint1) {
        const secondWirePoint = wire.points[0] === point1 ? wire.points[1] : wire.points[0];
        if (secondWirePoint === point2) {
            return true;
        } else {
            if (isConnected(secondWirePoint, point2, wiresOther))
                return true;
        }
    }

    return false;
}

export function getNewWireDirection(wire: WireItem, points: Point[]): WireItem {
    const beforeDirection = wire.direction;
    const ps = [points[wire.points[0]], points[wire.points[1]]];

    if (beforeDirection === "x" && ps[0].y === ps[1].y) {
        return {
            ...wire,
            direction: "y",
        };
    } else if (beforeDirection === "y" && ps[0].x === ps[1].x) {
        return {
            ...wire,
            direction: "x",
        };
    } else {
        return wire;
    }
}

export function getWireMiddlePoint(wire: WireItem, points: Point[]): Point {
    const wirePoints = wire.points.map(p => points[p]);
    return wire.direction === "x" ?
        { x: wirePoints[0].x, y: wirePoints[1].y } :
        { x: wirePoints[1].x, y: wirePoints[0].y };
}

export function getContactPointsNodes(list: List): ContactPointItem[][] {
    const nodes: ContactPointItem[][] = [];

    const wires = getWiresFromList(list);
    const cps = getContactPointsFromList(list);

    cp: for (const cp of cps) {
        const point = cp.point;
        for (const nodeIndex in nodes) {
            const nodePoint = nodes[nodeIndex][0].point;
            if (isConnected(point, nodePoint, wires)) {
                nodes[nodeIndex].push(cp);
                continue cp;
            }
        }
        nodes.push([cp]);
    }

    return nodes;
}

export function getNodeWithPoint(list: List, point: PointIndex): ContactPointItem[] {
    const node: ContactPointItem[] = [];
    const wires = getWiresFromList(list);
    const cps = getContactPointsFromList(list);

    for (const cp of cps) {
        if (isConnected(point, cp.point, wires))
            node.push(cp);
    }

    return node;
}

export function reduceUnnecessaryWires(list: List, points: Point[]): List {
    const wires = getWiresFromList(list);
    const cps = getContactPointsFromList(list).map(cp => cp.point);

    // remove wires with length 0 
    for (const wire of wires) {
        const wirePoints = wire.points.map(p => points[p]);
        if (wirePoints[0].x === wirePoints[1].x && wirePoints[0].y === wirePoints[1].y) {
            const pointFrom = wire.points[0];
            const pointTo = wire.points[1];
            // if is connection between two contact points it is right
            if (!(cps.includes(pointFrom) && cps.includes(pointTo))) {
                // connect directly and remove from list

                list = removeFromList(list, [wire]);
                list = mapList(list, item => {
                    if (item.name === "ContactPoint" && item.point === pointFrom)
                        return { ...item, point: pointTo };
                    if (item.name === "Wire") {
                        if (item.points[0] === pointFrom)
                            return { ...item, points: [pointTo, item.points[1]] };
                        if (item.points[1] === pointFrom)
                            return { ...item, points: [item.points[0], pointTo] };
                    }
                    return item;
                });
            }
        }
    }


    // TODO: remove strait wires next to other

    return list;
}