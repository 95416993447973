import { getCanvasCenter } from "./canvas";

import { cancelDrawing, isDrawingEnd, setDrawingPoint, setNewDrawingItem } from "./draw";
import { clearAction, deselectAllItems, isSelectMove, pasteActionToProject, selectItems } from "./action";
import {
    getDeselectedList, getItemAtPoint, getItemsAtPoint, getItemsInArea, getSelectedList,
} from "./list";
import { getNewWireDirection, getNodeWithPoint } from "./wire";
import { getShowDialog } from "../components/Dialogs";
import { getMovedPoints } from "./common";

import { Area, CanvasSetting, HistoryAction, List, Mouse, MouseButton, Point, Store } from "../types";
import { getAllPointsFromItem } from "./item";



export function getCursorPosition(
    mouse: Mouse, canvas: null | HTMLCanvasElement, canvasSetting: CanvasSetting, cursorSize: number,
) {
    if (!canvas) return { top: "0px", left: "0px" };

    const { zoom, shift } = canvasSetting;
    const center = getCanvasCenter(canvas);

    const top = (mouse.y + shift.y) * zoom + center.y;
    const left = (mouse.x + shift.x) * zoom + center.x;

    const offset = cursorSize / 2;

    return { top: `${Math.round(top - offset)}px`, left: `${Math.round(left - offset)}px` };
}

export function getDragAreaPosition(
    mouse: Mouse, canvas: null | HTMLCanvasElement, canvasSetting: CanvasSetting,
) {
    if (!canvas || !mouse.isDrag || !mouse.mouseDown) return null;

    const { zoom, shift } = canvasSetting;
    const center = getCanvasCenter(canvas);

    const top = (mouse.y + shift.y) * zoom + center.y;
    const left = (mouse.x + shift.x) * zoom + center.x;

    const top2 = (mouse.mouseDown.y + shift.y) * zoom + center.y;
    const left2 = (mouse.mouseDown.x + shift.x) * zoom + center.x;

    return {
        top: `${Math.round(Math.min(top, top2))}px`,
        left: `${Math.round(Math.min(left, left2))}px`,
        height: `${Math.round(Math.abs(top - top2))}px`,
        width: `${Math.round(Math.abs(left - left2))}px`,
    };
}

export function handleMouseMove(
    event: React.MouseEvent<HTMLCanvasElement, MouseEvent>,
    store: Store,
    canvas: null | HTMLCanvasElement,
): Store {
    if (!canvas) return store;

    event.preventDefault();

    const { action, project } = store;
    const { zoom, grid } = project.canvas;

    const center = getCanvasCenter(canvas);

    const offset = canvas.getBoundingClientRect();

    let x = (event.pageX - offset.left - center.x) / zoom - project.canvas.shift.x;
    let y = (event.pageY - offset.top - center.y) / zoom - project.canvas.shift.y;

    if (grid.show === true) {
        let dx = Math.abs((x) % (grid.size));
        let dy = Math.abs((y) % (grid.size));

        if (dx > (grid.size / 2))
            dx -= grid.size;
        if (dy > (grid.size / 2))
            dy -= grid.size;

        if (x > 0) {
            x -= dx;
        } else {
            x += dx;
        }
        if (y > 0) {
            y -= dy;
        } else {
            y += dy;
        }
    }

    // let variables
    let isDrag = action.mouse.isDrag;
    let shift = project.canvas.shift;
    let points = project.points;
    // tricky - when is moving, mouseDown is changing relatively with mowing items!
    let mouseDown = action.mouse.mouseDown;

    let list = project.list;


    // drag
    if (isSelectMove(action) && mouseDown) {
        if (mouseDown.button === "left") {
            let selectedList = getSelectedList(project.list);

            // start dragging
            if (isDrag === false && mouseDown.x !== x || mouseDown.y !== y) {
                isDrag = true;

                // is mouse up to selected item
                const items = getItemsAtPoint(selectedList, points, mouseDown);
                if (items.length === 0) {
                    // start selecting area
                    list = getDeselectedList(list);
                    selectedList = [];
                }
            }

            // move items
            if (isDrag && selectedList.length > 0) {
                const dX = x - mouseDown.x;
                const dY = y - mouseDown.y;

                const selectedPoints = getAllMovablePointsFromList(list, selectedList, points);
                points = points.map((p) => {
                    if (selectedPoints.includes(p)) {
                        return { x: p.x + dX, y: p.y + dY };
                    }
                    return p;
                });

                // TODO: set new direction wires

                // update mouseDown relatively with moved element
                mouseDown = { ...mouseDown, x, y };
            }

        } else if (mouseDown.button === "middle") {
            // shift canvas
            shift = {
                x: shift.x + (event.movementX * 1 / zoom),
                y: shift.y + (event.movementY * 1 / zoom),
            };
        }
    }

    // drawing
    let { drawing } = action;
    if (drawing) {
        // move point by cursor while drawing
        const activePoint = drawing.activePoint;
        drawing = {
            ...drawing,
            points: drawing.points.map((p, i) => i === activePoint ? { x, y } : p),
        };

        if (drawing.item.name === "Wire") {
            drawing = {
                ...drawing,
                item: getNewWireDirection(drawing.item, drawing.points),
            };
        }
    }

    // paste
    let { paste } = action;
    if (paste) {
        // move all points with cursor
        const move = { x: store.action.mouse.x - x, y: store.action.mouse.y - y };

        if (move.x !== 0 || move.y !== 0) {
            paste = {
                ...paste,
                points: getMovedPoints(paste.points, move),
            };
        }
    }

    return {
        ...store,
        // TODO: not change project and canvas when is not changing
        project: {
            ...store.project,
            points,
            list,
            canvas: {
                ...store.project.canvas,
                shift,
            },
        },
        action: {
            ...action,
            drawing,
            paste,
            mouse: { ...action.mouse, mouseDown, x, y, isDrag },
        },
    };
    /*

    if (this.cursor && (this.x !== mouseX || this.y !== mouseY)) {

        this.cursor.style.left = `${mouseX * display.zoom - (this.cursor.width / 2) }px`;
        this.cursor.style.top = `${mouseY * display.zoom - (this.cursor.height / 2) }px`;

        this.x = mouseX;
        this.y = mouseY;

        if (tools.isMouse()) {
            if (
                !this.isTrail &&
                this.downButton !== null &&
                (this.x !== this.mouseDown.x || this.y !== this.mouseDown.y)
            ) {
                this.startTrail();
            }

            if (this.isTrail) {
                this.trail();
            }
        } else {
            list.shift(this.x, this.y, false);
        }

        this.showPosition();
    }
    */
}

export function handleMouseDown(
    event: React.MouseEvent<HTMLCanvasElement, MouseEvent>,
    store: Store,
): Store {
    event.preventDefault();

    const button = getMouseButton(event.button);

    return {
        ...store,
        action: {
            ...store.action,
            mouse: {
                ...store.action.mouse,
                mouseDown: { button, x: store.action.mouse.x, y: store.action.mouse.y },
            },
        },
    };
}

export function handleMouseUp(
    event: React.MouseEvent<HTMLCanvasElement, MouseEvent>,
    store: Store,
): Store {
    event.preventDefault();
    let { action, project } = store;

    const button = getMouseButton(event.button);

    const { x, y, mouseDown, isDrag } = action.mouse;

    let historyAction: HistoryAction = "no-record";

    action = {
        ...action,
        mouse: {
            ...action.mouse,
            mouseDown: null,
            isDrag: false,
        },
    };

    if (button === "left") {
        if (!isDrag) {
            // left click
            if (isSelectMove(action)) {
                // select one item
                const item = getItemAtPoint(project.list, project.points, { x, y });
                if (item) {
                    project = { ...project, list: selectItems(project.list, [item]) };
                } else {
                    project = deselectAllItems(project);
                }
            } else if (action.drawing) {
                // drawing
                const itemName = action.drawing.itemName;
                action = setDrawingPoint(x, y, action);
                if (isDrawingEnd(action)) {
                    const change = cancelDrawing({ ...store, project, action });
                    project = change.project;
                    action = setNewDrawingItem(itemName, change.action);

                    historyAction = "record";
                }
            } else if (action.paste) {
                // put paste
                project = pasteActionToProject(action, project);
                action = clearAction(action);

                // TODO: connect contact points

                historyAction = "record";
            }

        } else {
            // left drag
            const selectedList = getSelectedList(project.list);
            if (selectedList.length > 0) {
                // after moving
                historyAction = "record";
            } else {
                // select items under select rectangle
                const area = getArea({ x, y, mouseDown, isDrag });
                const itemsInArea = getItemsInArea(project.list, project.points, area);
                project = { ...project, list: selectItems(project.list, itemsInArea) };
            }
        }
    } else if (button === "middle") {
        // TOOD: impolement
    } else if (button === "right") {
        if (!isDrag) {
            // right click
            if (action.drawing) {
                // if is drawing, cancel drawing and continue with the same tool
                const itemName = action.drawing.itemName;
                const change = cancelDrawing({ ...store, project, action });
                project = change.project;
                action = change.action;
                if (itemName !== "Wire")
                    action = setNewDrawingItem(itemName, change.action);

                historyAction = "record";
            } else {

                action = clearAction(action);
                const items = getItemsAtPoint(getSelectedList(project.list), project.points, { x, y });
                if (items.length === 1) {
                    const item = items[0];
                    // configure item
                    if (item.name === "Component") {
                        project = {
                            ...project,
                            list: project.list.map(i => i !== item ? i : {
                                ...i,
                                dialog: getShowDialog(),
                            }),
                        };
                    } else if (item.name === "Wire" || item.name === "ContactPoint") {
                        // rename node
                        const point = item.name === "Wire" ? item.points[0] : item.point;
                        const contactPoints = getNodeWithPoint(project.list, point);

                        action = {
                            ...action,
                            renameNode: {
                                contactPoints,
                                name: "",
                            },
                        };
                    } else {
                        // TODO: configure others
                    }
                }
            }
        } else {
            // not event for right drag
        }
    }

    return {
        ...store,
        project,
        action,
        historyAction,
    };
}

export function handleMouseWheel(
    event: React.WheelEvent<HTMLCanvasElement>,
    store: Store,
    canvas: null | HTMLCanvasElement,
): Store {
    if (!canvas) return store;

    event.stopPropagation();

    const d = event.deltaY;
    const { zoom } = store.project.canvas;
    // TODO: make move of center
    // const { x, y } = store.action.mouse;

    let newZoom = zoom * (d < 0 ? Math.sqrt(2) : 1 / Math.sqrt(2));
    if (newZoom < 0.25) newZoom = 0.25;
    if (newZoom > 8) newZoom = 8;

    return {
        ...store,
        project: {
            ...store.project,
            canvas: {
                ...store.project.canvas,
                zoom: newZoom,
            },
        },
    };
}

function getMouseButton(buttonNumber: number): MouseButton {
    switch (buttonNumber) {
        case 0: return "left";
        case 1: return "middle";
        case 2: return "right";
        default: return "unknown";
    }
}

function getArea(mouse: Mouse): Area {
    const { x, y } = mouse;
    const down = mouse.mouseDown || { x, y };

    return {
        x: Math.min(x, down.x),
        y: Math.min(y, down.y),
        width: Math.abs(x - down.x),
        height: Math.abs(y - down.y),
    };
}

function getAllMovablePointsFromList(fullList: List, selectedList: List, points: Point[]): Point[] {
    const notMovableIndexPoints = getMotMovableIndexPoints(fullList, []);

    const movablePoints: Point[] = [];

    for (const item of selectedList) {
        if (item.name === "Wire") {
            if (!notMovableIndexPoints[item.points[0]]) movablePoints.push(points[item.points[0]]);
            if (!notMovableIndexPoints[item.points[1]]) movablePoints.push(points[item.points[1]]);
        } else {
            movablePoints.push(...getAllPointsFromItem(item, points));
        }
    }

    return movablePoints;
}

/** index is the PointIndex, value true means, is not movable */
function getMotMovableIndexPoints(fullList: List, mutableNotMovableIndexPoints: boolean[]): boolean[] {
    for (const item of fullList) {
        if (!item.selected) {
            if (item.name === "Wire") {
                mutableNotMovableIndexPoints[item.points[0]] = true;
                mutableNotMovableIndexPoints[item.points[1]] = true;
            }
            if (item.name === "ContactPoint") {
                mutableNotMovableIndexPoints[item.point] = true;
            }
            if (item.name === "Component") {
                mutableNotMovableIndexPoints = getMotMovableIndexPoints(item.list, mutableNotMovableIndexPoints);
            }
        }
    }

    return mutableNotMovableIndexPoints;
}