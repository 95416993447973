import { useState, ReactNode, CSSProperties } from "react";
import MdiIcon from "@mdi/react";
import {
    Typography, useTheme,
    BottomNavigation, BottomNavigationAction, Divider, Box, Popover,
} from "@mui/material";

import {
    Collapse, List, ListItem as ListItemMUI, ListItemButton, ListItemIcon, ListItemText, ListSubheader,
} from "@mui/material";
import { mdiChevronDown, mdiChevronRight } from "@mdi/js";

import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { enqueueSnackbar, VariantType } from "notistack";

export const Grid = Grid2;

export function Icon({ path, size = 1, color }: { path: string, size?: number, color?: string }) {
    return <MdiIcon path={path} size={size} color={color} />;
}

const COLORS = [
    "#000000",
    ...Array.from({ length: 8 }).map((_, i) => {
        const ch = Math.ceil((i + 1) * 256 / 9).toString(16);
        return `#${ch}${ch}${ch}`;
    }),
    "#ffffff",
    "#980000",
    "#ff0000",
    "#ff9900",
    "#ffff00",
    "#01ff00",
    "#38761d",
    "#01ffff",
    "#4a86e8",
    "#0000ff",
    "#9900ff",
    "#ff00ff",
];

export function ColorPicker({ color, onChange }: { color: string, onChange: (color: string) => void }) {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    function handleClick(event: React.MouseEvent<HTMLElement>) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    return <>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }} onClick={handleClick}>
            <Box sx={{
                display: "flex", alignItems: "end", justifyContent: "center",
                width: "48px", height: "28px",
                backgroundColor: color, boxShadow: "0px 0px 4px rgba(0,0,0,0.8)", borderRadius: "4px",
                marginBottom: "2px",
            }}>
                <Typography variant="caption" fontSize={9} color={"#FFFFFF"}>{color}</Typography>
            </Box>
        </div>
        <Popover
            open={anchorEl !== null}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
        >
            <Box sx={{ display: "flex", flexWrap: "wrap", width: 250, gap: 1, p: 2 }}>
                {COLORS.map(c => <button key={c} style={{
                    border: "1px solid rgba(0,0,0,0.3)", borderRadius: "4px",
                    backgroundColor: c,
                    width: "24px", height: "24px",
                    cursor: "pointer",
                }} onClick={() => {
                    onChange(c);
                    handleClose();
                }} />)}
            </Box>
        </Popover>
    </>;
}

// ------------------- LIST -----------------------
export function ListVertical({ title, children }: { title?: string, children: ReactNode }) {
    return <List
        component="nav"
        subheader={title ? <ListSubheader>{title}</ListSubheader> : undefined}
    >
        {children}
    </List>;
}

type ListCollapseProps = {
    defaultOpen?: boolean,
    text: string,
    children: ReactNode,
};

export function ListCollapse({ defaultOpen = false, text, children }: ListCollapseProps) {
    const [open, setOpen] = useState(defaultOpen);

    return <>
        <ListItem text={text} icon={open ? mdiChevronDown : mdiChevronRight} onClick={() => setOpen(!open)} />
        <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding sx={{ pl: 4 }}>
                {children}
            </List>
        </Collapse>
    </>;
}

type ListItemProps = {
    text: string,
    textSecondary?: string,
    icon: string,
    selected?: boolean,
    onClick?: () => void,
    secondaryAction?: ReactNode,
};
export function ListItem({ text, textSecondary, icon, selected, secondaryAction, onClick }: ListItemProps) {
    const isBase64 = icon.startsWith("data:image");
    return <ListItemMUI dense disablePadding selected={selected}>
        <ListItemButton onClick={onClick && onClick}>
            <ListItemIcon sx={{ minWidth: "32px" }}>
                {!isBase64 && <Icon size={1} path={icon} />}
                {isBase64 && <img style={{ width: "24px", height: "24px" }} src={icon} />}
            </ListItemIcon>
            <ListItemText primary={text} secondary={textSecondary} />
        </ListItemButton>
        {secondaryAction}
    </ListItemMUI>;
}

// ------------------- NAV -----------------------
export function NavSection({
    title, children, bottomNavigation = true,
}: { title: string, children: React.ReactNode, bottomNavigation?: boolean }) {
    const theme = useTheme();

    return <Grid>
        <Typography fontSize={14} color={theme.palette.text.secondary} paddingBottom={0.5}>{title}</Typography>
        {bottomNavigation && <BottomNavigation showLabels sx={{ backgroundColor: "transparent" }}>
            {children}
        </BottomNavigation>}
        {!bottomNavigation && children}
    </Grid>;
}

export type NavItemProps = {
    label: string,
    icon?: string,
    style?: CSSProperties,
    disabled?: boolean,
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
    children?: ReactNode,
};
export function NavItem({ label, icon, style, disabled, onClick, children }: NavItemProps) {
    if (disabled === true)
        style = { ...style, opacity: 0.5 };
    return <BottomNavigationAction
        showLabel
        label={label}
        icon={icon ? <Icon path={icon} /> : children}
        style={style}
        disabled={disabled}
        onClick={onClick}
    />;
}

export function NavDivider() {
    return <Divider orientation="vertical" flexItem style={{ marginRight: 8 }} />;
}

// ------------------- NOTIFICATIONS -----------------
export function showNotification(message: string, variant: VariantType) {
    setTimeout(() => enqueueSnackbar(message, { variant }), 100);
}