/* eslint-disable @typescript-eslint/no-unused-vars */
import { AnalysisHandler } from "../core/analysisHandler";
import { mapComputedResults } from "../core/core_draft";
import { sendNGSpiceRequest } from "../settings";
import { ServerResponse } from "../types";
import {
    AnalysisComponent, AnalysisCircuit, ComplexValue, ComponentOutputProperty, Result,
    ResultDataType, ResultValue, ResultValueType, SimResultExpression, TODO,
    BasicAnalysisType,
    AnalysisConfig,
    TransientSimConfig,
    AnalysisType,
    Substitution,
    DesignVariable,
} from "../types/analysis";
import { isExpression } from "../utils/expressions";
import { mergeSubstitutions } from "../utils/substitutions";
import { getContactPoints, mangleNode, renderComponentProperty } from "../utils/utilities";
import { ngspiceAdvGenerateScript, ngspiceAdvGetFinalResults } from "./ngspiceAdvanced";
import { NGSpiceAnalysisType } from "./types";
import { getSpiceGenerator, spiceGetNetlist } from "../spice/netlist";

export interface AnalysisNGSpiceHandler extends AnalysisHandler {
    initCmd: string;
    getDefaultParameters: () => string;
    getSimulatorSettings: (sett: TODO) => string;
    getSimulationCmd: (settings: AnalysisConfig) => string;
    getSaveVectors: (
        proj: AnalysisCircuit, analysisType: NGSpiceAnalysisType, reqRslt: SimResultExpression[]) => string;
    getOutputVectors: (sett: TODO, reqRslt: SimResultExpression[]) => string;
    getGlobalSubstitutions: (proj: AnalysisCircuit, simSettings: AnalysisConfig) => Substitution[];
}

function getDefaultHandler(): AnalysisNGSpiceHandler {
    return {
        initCmd: "geecinit",
        getDefaultParameters: () => `.param pi=${Math.PI.toString()}`,
        getSimulatorSettings: (sett: TODO) => "",
        //getNetlist: getNGspiceNetlist,
        getSimulationCmd: (sett: TODO) => "",
        getSaveVectors: ngspiceGetSaveVectors,
        getOutputVectors: (sett: TODO, reqRslt: SimResultExpression[]) => {
            const defineResults = reqRslt.map((rslt: SimResultExpression) => `let result_${rslt.id} = ${rslt.expr}`);
            const printResults = reqRslt.map((rslt: SimResultExpression) => `print line result_${rslt.id} >> {{OUT}}`);
            return [...defineResults, ...printResults].join("\n");
        },
        getDefaultResults: () => [],
        getAvailableResults: ngspiceGetAvailableResults,
        //getRequestData: getRequestDataGeneric,
        getFinalResults: mapComputedResults,
        generateScript: ngspiceGenerateScript,
        getGlobalSubstitutions: ngspiceGetGlobalSubstitutions,
        parseData: ngspiceParseData,
        parseErrors: (result: ServerResponse) => "",
        sendRequest: sendNGSpiceRequest,
        //prepareRequest: NGSpicePrepareRequest,
    };
}

function getAnalysisBasicType(analysisType: NGSpiceAnalysisType): BasicAnalysisType {
    switch (analysisType) {
        case "NGSpiceAC":
        case "NGSpiceACGraph":
            return "ac";
        case "NGSpiceTranGraph":
            return "tran";
        case "NGSpiceOP":
        default:
            return "dc";
    }
}


export function getNGSpiceAnalysisHandler(analysisType: NGSpiceAnalysisType): AnalysisNGSpiceHandler {
    switch (analysisType) {
        case "NGSpiceOP":
            return getOPHandler();
        case "NGSpiceAC":
            return ngspiceGetACHandler();
        case "NGSpiceTranGraph":
            return getTranHandler();
        case "NGSpiceAdvanced":
            return getAdvHandler();
        default:
            throw new Error(`AnalysisHandler for ${analysisType} not implemented`);
    }
}

function getOPHandler(): AnalysisNGSpiceHandler {
    return {
        ...getDefaultHandler(),
        getSimulationCmd: (sett: AnalysisConfig) => "op",
    };
}

function ngspiceGetACHandler(): AnalysisNGSpiceHandler {
    return {
        ...getDefaultHandler(),
        getDefaultResults: () => { return [{ id: -1, name: "freq", expr: "frequency" }]; },
        // TODO: use settings
        getSimulationCmd: (sett: AnalysisConfig) => "set frequency=1k\nac lin 1 $frequency $frequency",
    };
}

function getTranHandler(): AnalysisNGSpiceHandler {
    return {
        ...getDefaultHandler(),
        getDefaultResults: () => { return [{ id: -1, name: "time", expr: "time" }]; },
        getSimulationCmd: (sett: AnalysisConfig) => {
            const simcCfg = (sett.config as TransientSimConfig);
            return `tran ${simcCfg.step} ${simcCfg.to} ${simcCfg.from} ${simcCfg.uic ? "uic" : ""}`;
        },
        //getSaveVectors:ngspiceGetSaveVectors, now it is in default handler
    };
}

function getAdvHandler(): AnalysisNGSpiceHandler {
    return {
        ...getDefaultHandler(),
        generateScript: ngspiceAdvGenerateScript,
        getDefaultResults: () => [],
        getAvailableResults: () => [],
        getFinalResults: ngspiceAdvGetFinalResults,
    };
}

/**
 *
 * @param proj
 * @param analysisType
 * @param reqRslt Requested results in simulator specific format
 * @returns
 */
function ngspiceGetSaveVectors(
    proj: AnalysisCircuit, analysisType: NGSpiceAnalysisType, reqRslt: SimResultExpression[]): string {
    const defaultResults: string[] = [];
    const reqExpr = reqRslt.map(r => r.expr);
    proj.components.forEach((component: AnalysisComponent) => {
        const outputProperties = ngspiceGetComponentOutputProperties(component, getAnalysisBasicType(analysisType));
        const saveIDs: string[] = ngspiceGetOutputPropsSaveIDs(component, getAnalysisBasicType(analysisType));
        outputProperties.forEach((outProp: ComponentOutputProperty) => {
            // check if the output property should be saved
            if (!saveIDs.includes(outProp.id)) {
                return;
            }

            // render the output property expression and convert it to lowercase (reqRslt shall be in lowercase already)
            const renderedOutExpr = renderComponentProperty(component, outProp.expr).toLowerCase();

            // check if the output property is used in the requested results
            if (reqExpr.some(name => name.includes(renderedOutExpr))) {
                defaultResults.push(renderedOutExpr);
            }
        });
    });

    return ".save all " + defaultResults.join(" ");
}

function _getOutputVectors(handler: AnalysisNGSpiceHandler, sett: TODO, reqRslt: SimResultExpression[]): string {
    const defineResults = reqRslt.map((rslt: SimResultExpression) => `let result_${rslt.id} = ${rslt.expr}`);
    const printResults = reqRslt.map((rslt: SimResultExpression) => `print line result_${rslt.id} >> {{OUT}}`);
    return [...defineResults, ...printResults].join("\n");
}

function _getOutputVector(handler: AnalysisNGSpiceHandler, sett: TODO, reqRslt: SimResultExpression[]): string {
    // TODO: set substitutions
    // TODO: compute assigns
    // TODO: compute result
    // TODO: print the result

    // handler.getGlobalSubstitutions(proj, simSettings)
    // handler.getSimulationCmd(sett);
    // handler.getOutputVectors(simSettings, reqRslt)

    return "";
}

/**
 * Substitution priorities (1 - the highest):
 * 1. Assign substitution
 * 2. Custom result substitution / Graph substitution
 * 3. Slider
 * 4. Global Substitution
 * 5. Component value
 */
function ngspiceGetGlobalSubstitutions(proj: AnalysisCircuit, simSettings: AnalysisConfig): Substitution[] {
    // TODO

    return mergeSubstitutions(simSettings.substitutions, []);
}

function applySubstitutions(subs: Substitution[]): string {
    /* let set = [];
     let alter = [];
     let altermod = [];
     let alterparam = [];

     subs.forEach((sub: Substitution) => {
         const { params, netlist, model } = getComponentNetlist(component);
         finalComponents.push(netlist);
         finalParams.push(params);
         finalModels.push(model);
     });

     let finalScript = finalParams.filter(p => p !== "").join("\n") + "\n";
     finalScript += finalComponents.filter(p => p !== "").join("\n") + "\n";
     finalScript += finalModels.filter(p => p !== "").join("\n") + "\n";

     return finalScript;
     */
    return "";
}

function convertSubstitution(substitution: Substitution): string {
    // TODO

    return "";
}

function ngspiceGetComplexNumberData(data: string): ComplexValue {
    const match = data.match("^\s*(.*)\s*,\s*(.*)$");
    if (match) {
        return { value1: match[1], value2: match[2] };
    } else {
        throw new Error("Invalid input value (ngspiceGetComplexNumberData)");
    }
}

function ngspiceGetNodeVoltages(sett: AnalysisCircuit): SimResultExpression[] {
    return getContactPoints(sett).map(n => {
        return {
            id: -1,
            name: `v(${n})`,
            expr: `v(${mangleNode(n)})`,
        };
    });
}

function ngspiceGetAvailableResults(sett: AnalysisCircuit, analysisType: AnalysisType): SimResultExpression[] {
    const defaultResults: SimResultExpression[] = ngspiceGetNodeVoltages(sett);
    const _analysisType = analysisType as NGSpiceAnalysisType;

    sett.components.forEach((component: AnalysisComponent) => {
        const outputProperties = ngspiceGetComponentOutputProperties(component, getAnalysisBasicType(_analysisType));
        outputProperties.forEach((outProp: ComponentOutputProperty) => {
            const renderedOutput = {
                id: -1,
                name: renderComponentProperty(component, outProp.name),
                expr: renderComponentProperty(component, outProp.expr),
            };

            const finalResultNames = defaultResults.map(r => r.name);

            // check autogenerated "v(0)" node
            renderedOutput.expr = renderedOutput.expr.replaceAll("v(0)", "0");


            if (isExpression(renderedOutput.expr) ||
                finalResultNames.some(name => renderedOutput.expr.includes(name))) {
                defaultResults.forEach((def: SimResultExpression) => {
                    renderedOutput.expr = renderedOutput.expr.replaceAll(def.name, def.expr);
                });
            }

            defaultResults.push(renderedOutput);
        });
    });

    return defaultResults.map((rslt: SimResultExpression) => {
        return {
            ...rslt,
            expr: rslt.expr.toLowerCase(),
        };
    });
    // [{ id: -1, name: "i(r1)", expr: "@r1[i]" },
    //{ id: -1, name: "v(1)", expr: "v(1)" }];//, { id: 2, name: "p(r1)", expr: "v(1)*i(r1)" }
}

// TODO: parametric
function ngspiceGenerateScript(
    proj: AnalysisCircuit, simSettings: AnalysisConfig, reqRslt: SimResultExpression[]): string {

    const analysisType = simSettings.analysisType as NGSpiceAnalysisType;
    const analysisHandler: AnalysisNGSpiceHandler = getNGSpiceAnalysisHandler(analysisType);

    //const defaultResults: SimResultExpression[] = analysisHandler.getDefaultResults(proj, simSettings.analysisType);
    //const reqRsltTransformed = transformUserResults(defaultResults, simSettings.userResults);

    const netlistBlock = [];
    const controlBlock = [];

    // populate netlist block
    netlistBlock.push(proj.title);
    netlistBlock.push(analysisHandler.getDefaultParameters());
    netlistBlock.push(analysisHandler.getSimulatorSettings(simSettings));
    netlistBlock.push(ngspiceDefineDesignVariables(proj.designVariables));
    netlistBlock.push(ngspiceGetNetlist(proj));
    netlistBlock.push(analysisHandler.getSaveVectors(proj, simSettings.analysisType as NGSpiceAnalysisType, reqRslt));

    // populate control block
    //controlBlock.push(analysisHandler.initCmd);
    controlBlock.push("set width=100000"); // TODO
    controlBlock.push("define ir(v,r) v/r");
    controlBlock.push("define ic(v,cap,f) v*2*const.pi*f*cap*const.i");
    //controlBlock.push(analysisHandler.getGlobalSubstitutions(proj, simSettings));
    controlBlock.push(analysisHandler.getSimulationCmd(simSettings));
    controlBlock.push(analysisHandler.getOutputVectors(simSettings, reqRslt));

    // The cript for WASM should be in form: "netlist"\n.control\n"cmds"\n.endc\n.end
    return `${netlistBlock.join("\n")}\n\n.control\n${controlBlock.join("\n")}\n.endc\n.end`;
}

function ngspiceParseData(data: string): Result[] {
    const dataArr: Result[] = [];
    const lines = data.split("\n");

    lines.forEach((line: string) => {
        const match = line.match(/^\s*(.*)=\s*(.*)$/);
        if (match) {
            const rhs = match[2];
            const matchVector = rhs.match(/^\s*\(\s*(.*)\s*\)\s*$/);
            const dataType: ResultDataType = matchVector ? "Array" : "Value";
            const valueType: ResultValueType = rhs.includes(",") ? "ReIm" : "Real";
            let resultValue: ResultValue = null;

            try {
                if (matchVector) {
                    // split string by space or tab
                    const values: string[] = matchVector[1].split(/[\s\t]+/).filter(value => value !== "");

                    if (valueType === "ReIm") {
                        // complex numbers
                        resultValue = values.map(val => ngspiceGetComplexNumberData(val));
                    } else {
                        // real values
                        resultValue = values.map(val => val.trim());
                    }
                } else {
                    if (valueType === "ReIm") {
                        // complex number
                        resultValue = ngspiceGetComplexNumberData(rhs);
                    } else {
                        // real value
                        resultValue = rhs.trim();
                    }
                }
            } catch {
                // An error occurred during parsing -> invalidate data
                resultValue = null;
            }

            dataArr.push({
                id: -1,
                name: match[1].trim(),
                value: resultValue,
                valueType: valueType,
                dataType: dataType,
                format: "Exp",
            });
        }
    });

    return dataArr;
}

export function getUserResultsOnly(
    defaultRslt: SimResultExpression[], userRslt: SimResultExpression[]): SimResultExpression[] {

    userRslt.forEach((rslt: SimResultExpression) => {
        if (isExpression(rslt.expr)) {
            defaultRslt.forEach((def: SimResultExpression) => {
                rslt.expr = rslt.expr.replaceAll(def.name, def.expr);
            });
        }
    });

    const mergedResults = [...userRslt];
    return mergedResults;
}

export function ngspiceGetComponentOutputProperties(
    component: AnalysisComponent, type: BasicAnalysisType): ComponentOutputProperty[] {

    // reduce is map and filter function in one
    return component.outputProperties.reduce<ComponentOutputProperty[]>(
        (acc: ComponentOutputProperty[], outProp: ComponentOutputProperty) => {
        // If the analysis is not allowed, do not add the output property
            if (outProp.allowedAnalysis[type] === false) {
                return acc;
            }

            // If the expression is already set, do not change it
            if (outProp.expr !== "") {
                acc.push({ ...outProp });
                return acc;
            }

            let expr = "";
            switch (outProp.id) {
                case "r_voltage":
                case "l_voltage":
                case "c_voltage":
                case "v_voltage":
                case "i_voltage":
                case "diode_voltage":
                case "sp_voltage":
                {
                    expr = "(v(@node[0])-v(@node[1]))";
                    break;
                }
                case "r_power":
                case "l_power":
                case "c_power":
                case "v_power":
                case "i_power":
                case "bjt_power":
                case "diode_power":
                {
                //expr = "v(@tag)*i(@tag)";
                    expr = "@@tag[p]";
                    break;
                }
                case "r_current": expr = type === "ac" ? "ir((v(@node[0])-v(@node[1])),@@tag[r])" : "@@tag[i]"; break;
                case "v_current": expr = "i(@tag)"; break;
                case "c_current": 
                {
                    expr = type === "ac" ? "ic((v(@node[0])-v(@node[1])),@@tag[c],frequency)" : "@@tag[i]";
                    break;
                }
                case "l_current": expr = "i(@tag)"; break;
                case "bjt_icollector": expr = "@@tag[ic]"; break;
                case "bjt_ibase": expr = "@@tag[ib]"; break;
                case "bjt_iemitter": expr = "@@tag[ie]"; break;
                case "vcvs_iout":
                case "vccs_iout":
                case "cccs_iout":
                case "ccvs_iout":
                    expr = "@@tag[i]"; break; // TODO: probably doesnt work for AC
                default: expr = outProp.expr;
            }
            acc.push({
                ...outProp,
                expr: expr,
            });
            return acc;
        }, []);
}

/** Returns IDs of a component output properties that should be saved using save command */
export function ngspiceGetOutputPropsSaveIDs(component: AnalysisComponent, type: BasicAnalysisType): string[] {
    const outputProperties = ngspiceGetComponentOutputProperties(component, type);
    const filteredProps = outputProperties.filter((outProp: ComponentOutputProperty) => {
        switch (outProp.id) {
            case "r_current": return type !== "ac";
            case "c_current": return type !== "ac";
            case "r_power": return true;
            case "l_power": return true;
            case "c_power": return true;
            case "v_power": return true;
            case "i_power": return true;
            case "bjt_power": return true;
            case "diode_power": return true;
            case "bjt_icollector": return true;
            case "bjt_ibase": return true;
            case "bjt_iemitter": return true;
            default: return false;
        }
    });

    return filteredProps.map((outProp: ComponentOutputProperty) => outProp.id);
}

export function ngspiceDefineDesignVariables(dvs: DesignVariable[]): string {
    if(dvs.length !== 0){
        const dvAssign = dvs.map((dv: DesignVariable) => `${dv.name.toLowerCase()}=${dv.value}`);
        return `.param ${dvAssign}`;
    } else {
        return "";
    }
}

/**
 * Returns netlist for the NGspice.
 * @param proj
 * @returns
 */
export function ngspiceGetNetlist(proj: AnalysisCircuit): string {
    return spiceGetNetlist(proj, getSpiceGenerator());
}