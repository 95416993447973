
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

import TopBar from "./TopBar";
import LeftMenu from "./LeftMenu";
import RightMenu from "./RightMenu";
import BottomMenu from "./BottomMenu";
import Canvas from "./Canvas";
import Dialogs from "./Dialogs";

export default function Layout() {

    return <Grid container flexDirection="column" height="100vh">
        <TopBar />
        <Grid container flexGrow={1}>
            <Grid width="200px" sx={{ position: "relative", bgcolor: "background.paper" }}>
                <Box sx={{ position: "absolute", top: 0, left: 0, overflow: "auto", width: "100%", height: "100%" }}>
                    <LeftMenu />
                </Box>
            </Grid>
            <Grid container flexDirection="column" flexGrow={1}>
                <Grid container flexGrow={1} alignItems="center" justifyContent="center" position="relative">
                    <Canvas />

                    <Grid position="absolute" zIndex={10} bottom="0px" left="0px" width="100%"
                        sx={{ pointerEvents: "none" }}
                    >
                        <BottomMenu />
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                display="flex" flexDirection="column" position="relative"
                width="280px" sx={{ bgcolor: "background.paper" }}
            >
                <RightMenu />
            </Grid>
        </Grid>
        <Dialogs />
    </Grid>;
}
