import {
    AnalysisCircuit, TODO, AnalysisConfig, SimulationOutput, SimResultExpression,
    ResultValue, ResultValueType, ResultDataType, Result, Simulation } from "../types/analysis";
import { ServerResponse } from "../types";
import { AnalysisHandler, RequestResources } from "./analysisHandler";
import { isExpression } from "../utils/expressions";
import { getAnalysisHandler } from "../common";
import { trace } from "../settings";

export type SimulationRawData = {
    name: string,
    value: ResultValue,
    valueType: ResultValueType,
    dataType: ResultDataType,
}

export async function runSimulation(proj: AnalysisCircuit, simSettings: Simulation): Promise<SimulationOutput> {
    let finalData: Result[] = [];
    let finalScript = "";
    let error = "";
    let rawData = "";

    try {
        // Pickup corresponding AnalysisHandler.
        const analysisHandler: AnalysisHandler = getAnalysisHandler(simSettings.analysisType);

        // Define request data.
        //const requestData: SimResultExpression[] = analysisHandler.getRequestData(proj, simSettings);
        //console.log("request data:");
        //console.log(requestData);

        // Generate request script.
        //const script: string = analysisHandler.generateScript(proj, simSettings, requestData);
        const { script, requestedData } = prepareRequest(proj, simSettings);
        finalScript = script;
        trace(finalScript);

        //TODO: script secutity check here??

        // Send the request
        const result: ServerResponse = await analysisHandler.sendRequest(script);
        trace(result);

        if (result.status === 200) {
            // First assign output data to rawData.
            rawData = result.data;

            // Second parse erors from the ServerResponse (stderr and stdout)
            error = analysisHandler.parseErrors(result);

            // Third parse the output data.
            const data: Result[] = analysisHandler.parseData(result.data);
            trace("parsed data:");
            trace(data);

            // Match requested data with returned data (use correct names, ...).
            finalData = analysisHandler.getFinalResults(requestedData, data);
            trace("final data:");
            trace(finalData);
        }else{
            error = `Error: ServerResponse status is ${result.status}\n`;
        }
    } catch (err: Error | unknown) {
        if (err instanceof Error) {
            const stack = err.stack?.split("\n") || [];
            const errAt = stack.length > 1 ? stack[1].trim() : "";
            error += `${err.message} (${errAt})\n`;
        } else {
            error += err?.toString() + "\n";
        }
    }

    // TODO: just temporary create variable Simulation
    return {
        inProgress: false,
        results: finalData,
        script: finalScript,
        output: rawData,
        error: error,
    };
}

export async function runSimulationAsync(
    proj: AnalysisCircuit, simSettings: Simulation, onData: TODO): Promise<SimulationOutput> {
    const finalOutput: SimulationOutput = {
        inProgress: false,
        results: [],
        script: "",
        output: "",
        error: "",
    };

    // Pickup corresponding AnalysisHandler.
    //const analysisHandler: AnalysisHandler = getAnalysisHandler(simSettings.analysisType);

    // TODO: sim partitioning here
    // partitioning by one parameter - use subtitution instead
    // if we do partitioning we lose the input script??
    const steps: AnalysisConfig[] = [simSettings];// TODO: analysisHandler.partializeSimulation(...)

    for (const step of steps) {

        const output = await runSimulation(proj, step);
        finalOutput.script = output.script;
        finalOutput.output = output.output;

        // TODO: if an error occurs break the loop!

        onData();// TODO params: data, status in %?
    }

    return finalOutput;
}

/**
 * Set the ID for each defaultRslt and combine userRslt with defaultRslt.
 * The ID for userRslt shall be fixed (from zero to N-1), defaultRslt id range shall continue (from N to M)
 *
 * @param userRslt
 * @param defaultRslt
 * @returns
 */
export function combineResults(
    userRslt: SimResultExpression[], defaultRslt: SimResultExpression[]): SimResultExpression[] {
    const userMaxID = Math.max(0, ...userRslt.map(r => r.id)) + 1;
    defaultRslt.forEach((rslt, index) => {
        rslt.id = userMaxID + index;
    });

    const mergedResults = [...defaultRslt, ...userRslt];
    return mergedResults;
}

/**
 * Convert user result expressions to the form that can be used in the simulation.
 *
 * @param defaultRslt
 * @param userRslt
 * @returns
 */
export function transformUserResults(
    defaultRslt: SimResultExpression[], userRslt: SimResultExpression[]): SimResultExpression[] {
    return userRslt.map((rslt: SimResultExpression) => {
        if (isExpression(rslt.expr)) {
            let newExpr = rslt.expr;
            defaultRslt.forEach((def: SimResultExpression) => {
                newExpr = newExpr.replaceAll(def.name, def.expr);
            });
            return {...rslt, expr: newExpr};
        }else {
            return {...rslt};
        }
    });
}


// export function getRequestDataGeneric(proj: AnalysisCircuit, simSettings: AnalysisConfig): SimResultExpression[]{
//     // const analysisHandler: AnalysisHandler = getAnalysisHandler(simSettings.analysisType);
//     // let defaultResults: SimResultExpression[] = analysisHandler.getDefaultResults(proj, simSettings.analysisType);
//     // const transformedResults = transformUserResults(defaultResults, simSettings.userResults);

//     // if(simSettings.noAdditionalResults){
//     //     return [...transformedResults];// TODO!!!!!!!!!!!!!!
//     // }else{
//     //     return combineResults(transformedResults, defaultResults);
//     // }

//     return [];
// }

/**
 * TODO: maybe can be used as a generic function?
 *
 * @param proj
 * @param simSettings
 * @returns
 */
export function prepareRequest(proj: AnalysisCircuit, simSettings: AnalysisConfig): RequestResources {
    const analysisHandler: AnalysisHandler = getAnalysisHandler(simSettings.analysisType);

    const defRslts: SimResultExpression[] = analysisHandler.getDefaultResults();
    const availRslts: SimResultExpression[] = analysisHandler.getAvailableResults(proj, simSettings.analysisType);

    const allDefaultResults = (simSettings?.noAdditionalResults) ? defRslts : [...defRslts, ...availRslts];

    const userResultsTransformed = transformUserResults([...defRslts, ...availRslts], simSettings.userResults);

    const requestedData: SimResultExpression[] = combineResults(userResultsTransformed, allDefaultResults);

    const script: string = analysisHandler.generateScript(proj, simSettings, requestedData);

    return {script, requestedData};
}

/**
 * Loops through requested results and matches them with computed results.
 *
 * @param requestedtRslt
 * @param computedRslt
 * @returns
 */
export function mapComputedResults(requestedtRslt: SimResultExpression[], computedRslt: Result[]): Result[] {

    return requestedtRslt.map((reqRslt: SimResultExpression) => {
        const resultName = `result_${reqRslt.id}`;
        let found = computedRslt.find(item => item.name === resultName);
        if (found === undefined) {
            found = {
                id: -1,
                name: "",
                value: null,
                valueType: "Real",
                dataType: "Value",
                format: "Exp",
            };
        }

        return {
            id: reqRslt.id,
            name: reqRslt.name,
            value: found.value,
            valueType: found.valueType,
            dataType: found.dataType,
            format: found.format,
        };
    });
}