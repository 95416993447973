import { CSSProperties } from "react";
import { mdiCheckboxBlankOutline, mdiCheckboxOutline } from "@mdi/js";
import { ColorPicker, NavDivider, NavItem, NavSection } from ".";

import { StyleDraw, StyleText } from "../types";
import { Input, MenuItem, Select } from "@mui/material";
import { changeStyle } from "../services/action";
import { useStore } from "./Store";

type ItemType = null | "check" | "color" | "number" | string[];

const drawNav: { [Name in keyof StyleDraw]: { type: ItemType, style: CSSProperties } } = {
    color: { type: "color", style: {} },
    width: { type: "number", style: { width: "60px" } },
    cap: { type: ["butt", "round", "square"], style: { width: "80px" } },
    dash: { type: ["none", "1, 15", "3, 3", "3, 15", "6, 6", "15, 3", "15, 15"], style: { width: "80px" } },
    lineJoin: { type: ["bevel", "miter", "round"], style: { width: "80px" } },
    closePath: { type: "check", style: {} },
    fill: { type: "check", style: {} },
    fillColor: { type: "color", style: {} },
};

const textNav: { [Name in keyof StyleText]: { type: ItemType, style: CSSProperties } } = {
    color: { type: "color", style: {} },
    font: { type: ["Arial", "Courier New", "Georgia", "Times New Roman", "Verdana"], style: { width: "180px" } },
    fontAlignHorizontal: {
        type: ["top", "middle", "alphabetic", "bottom"],
        style: { width: "110px" },
    },
    fontAlignVertical: { type: ["left", "center", "right"], style: { width: "100px" } },
    fontSize: { type: "number", style: { width: "60px" } },
};


export function StyleNav() {
    const { store, setStore } = useStore();
    const { action } = store;

    return <>
        <NavSection title="Draw">
            {Object.entries(drawNav).map(([name, ts]) => <Item
                key={name}
                name={name}
                type={ts.type}
                style={ts.style}
                value={action.style.draw[name as keyof StyleDraw]}
                onChangeValue={value => setStore(store => changeStyle(store, { [name]: value }, {}))}
            />)}
        </NavSection>
        <NavDivider />

        <NavSection title="Text">
            {Object.entries(textNav).map(([name, ts]) => <Item
                key={name}
                name={name}
                type={ts.type}
                style={ts.style}
                value={action.style.text[name as keyof StyleText]}
                onChangeValue={value => setStore(store => changeStyle(store, {}, { [name]: value }))}
            />)}
        </NavSection>
    </>;
}

type ItemProps<T> = {
    name: string,
    type: ItemType,
    style: CSSProperties,
    value: T,
    onChangeValue: (value: T) => void,
}

function Item<T>({ name, type, style, value, onChangeValue }: ItemProps<T>) {

    let label = toCapitalizedWords(name);
    if (label !== "Font") label = label.replace("Font ", "");

    if (type === null) {
        return null;

    } else if (type === "color" && typeof value === "string") {
        return <NavItem
            label={label}
            style={{ minWidth: "48px", paddingLeft: "0px", paddingRight: "4px" }}
            onClick={() => null}
        >
            <ColorPicker
                color={value}
                onChange={color => onChangeValue(color as T)}
            />
        </NavItem>;

    } else if (type === "number" && typeof value === "number") {
        return <NavItem label={label} style={style} onClick={() => null} >
            <Input
                sx={{ width: 45, marginLeft: 1, marginRight: 1, textAlign: "center" }}
                type="number"
                value={value}
                style={style}
                onChange={e => onChangeValue(parseInt(e.target.value) as T)}
            />
        </NavItem>;

    } else if (type === "check" && typeof value === "boolean") {
        return <NavItem
            style={style}
            label={label}
            icon={value ? mdiCheckboxOutline : mdiCheckboxBlankOutline}
            onClick={() => onChangeValue(!value as T)}
        />;

    } else if (Array.isArray(type) && typeof value === "string") {
        let minWidth = undefined;
        if (typeof style.width === "string") {
            minWidth = `${parseInt(style.width) + 8}px`;
        }
        return <NavItem label={label} style={{ minWidth }} onClick={() => null} >
            <Select
                variant="standard"
                style={style}
                value={value}
                onChange={e => onChangeValue(e.target.value as T)}
            >
                {type.map((item, index) => <MenuItem key={index} value={item}>{item}</MenuItem>)}
            </Select>
        </NavItem>;
    } else {
        throw new Error(`Invalid style for ${name} with value ${value} of type "${type}". `);
    }
}

function toCapitalizedWords(name: string) {
    const words = name.match(/[A-Za-z][a-z]*/g) || [];

    return words.map(capitalize).join(" ");
}
function capitalize(word: string) {
    return word.charAt(0).toUpperCase() + word.substring(1);
}