import { FunctionList, ParsedExpression } from "../types/expressions";

export const identityFunction = (name: string, ...p: string[]) => `${name}(${p.join(",")})`;

/**
 *
 * @param expr expects a string in the format of "name(arg1, arg2, ...)"
 * @returns object: {template: "name(${0},${1},...)", args: ["arg1", "arg2", ...]}
 */
export function parseFunction(expr: string): ParsedExpression {
    try {
        const idx = expr.indexOf("(");
        const name = expr.slice(0, idx).trim();
        const params = expr.slice((idx + 1), -1).split(",").map(p => p.trim());
        let argTemplate = "";
        params.forEach((param, index) => {
            argTemplate += `\${${index}}`;
            if (index < params.length - 1) {
                argTemplate += ",";
            }
        });
        return {template: `${name}(${argTemplate})`, args: params};
    } catch {
        throw new Error("Invalid function string format (parseFunction)");
    }
}

export const functionsCommon: FunctionList = [
    {name: "test", paramCount: 3, getFunction: (name, ...p) => `test(${p[0]},${p[2]},${p[1]})` },
    {name: "log", paramCount: 1, getFunction: (name, ...p) => `log(${p[0]}, 10)`},
    {name: "sin", paramCount: 1, getFunction: identityFunction},
];
