import { memo } from "react";
import {
    Button, DialogActions, DialogContent, DialogContentText, Paper,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from "@mui/material";
import { BlockMath } from "react-katex";

import { DraggableDialog } from "./Dialogs";

import { ComplexValue, Result } from "../analysis/types/analysis";
import { AnalysisResultDialog } from "../types";
import { resultValueReImToMagPh, resultValueToSI } from "../analysis/analysis";

export type AnalysisResultDialogComponentProps = AnalysisResultDialog & {
    onMove: (x: number, y: number) => void,
    onClose: () => void,
};

export const AnalysisResultDialogComponentMemo = memo(AnalysisResultDialogComponent);
export default function AnalysisResultDialogComponent({
    analysis, dialog, onMove, onClose,
}: AnalysisResultDialogComponentProps) {

    return <DraggableDialog
        dialog={dialog}
        title={`Results of ${analysis.analysisType} analysis`}
        onMove={onMove}
        onClose={onClose}
    >
        <DialogContent>
            <DialogContentText>
                TODO: detailed information about the analysis
            </DialogContentText>

            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {analysis.results.map((res, i) => <TableRow key={i}>
                            <TableCell>
                                {res.name}
                            </TableCell>
                            <TableCell>
                                <Value result={res} />
                            </TableCell>
                        </TableRow>)}
                    </TableBody>
                </Table>
            </TableContainer>

        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>Close</Button>
        </DialogActions>
    </DraggableDialog>;
}

function Value({ result }: { result: Result }) {
    if(result.dataType === "Value"){
        let showValue = "NaN";

        if(result.value !== null){
            switch(result.valueType){
                case "Real":
                {
                    switch (result.format) {
                        case "Exp":
                        case "Float":
                        case "SI":
                            showValue = resultValueToSI(result).value as string;
                            break;
                        default:
                            showValue = result.value as string;
                    }
                    break;
                }
                case "ReIm":
                case "MagPh":
                {
                    const rslt = (result.valueType === "ReIm") ? resultValueReImToMagPh(result) : result;
                    const rsltSI = resultValueToSI(rslt);
                    const mag = (rsltSI.value as ComplexValue).value1;
                    const ph = (rsltSI.value as ComplexValue).value2;
                    showValue = `${mag} ∠ ${[ph]}°`;
                    break;
                }
            }
        }

        return <BlockMath>{showValue}</BlockMath>;
    } else if (result.dataType === "Array"){
        return <>{(result.value as string[]).join(",")}</>;
    } else {
        throw new Error(`Unsupported data type ${result.dataType}`);
    }
}
