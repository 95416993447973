import { useMemo } from "react";
import moment from "moment";

import { useStore } from "../components/Store";
import { getCircuitName, useAsyncEffect } from "./common";
import { dbSelectRow, insertCircuit, updateCircuit } from "./api";
import { getNewCircuitDefinition } from "./deprecated";

import { DBCircuit, FileCircuit, Project, ProjectCircuit, Store, User } from "../types";
import { getEmptyAction, getEmptyCircuitProject, getEmptyComponentProject } from "./default";
import { showNotification } from "../components";

import packageJSON from "../../package.json";

export function useProject() {
    const { store, setStore } = useStore();

    const project = store.project;
    const setProject = useMemo(() => (project: Project, historyRecord?: boolean) => setStore(store => ({
        ...store,
        project,
        historyAction: historyRecord ? "record" : store.historyAction,
    })), [setStore]);

    return { project, setProject };
}

export function useProjectLoadWithURL(setStore: (store: (prev: Store) => Store) => void) {

    // load page with url
    useAsyncEffect(async (isMountedRef) => {
        const path = window.location.pathname.split("/");
        const idString = path[path.length - 1];
        const id = parseInt(idString);

        // is id in path?
        if (String(id) !== idString) return;

        const project = await loadProjectFormDB(id);
        if (!project) {
            // TODO: show error to user
            return;
        }

        if (isMountedRef.current)
            setStore(store => ({
                ...store,
                project,
                historyAction: "record",
            }));
    }, []);
}

export function useProjectLoad() {
    const { setStore } = useStore();

    return useMemo(() => async (id: number) => {
        const project = await loadProjectFormDB(id);
        if (!project) {
            // TODO: show error to user
            return;
        }

        setStore(store => ({
            ...store,
            project,
            historyAction: "record",
        }));
    }, []);
}

export function setProjectHeaders(project: Project) {
    const id = project.id || null;
    const circuitName = getCircuitName(project.title);
    const title = `${circuitName} | GEEC`;
    const path = id ? `/${id}` : "/";
    history.replaceState(`Circuit ${id}`, title, path);
    document.title = title;

    showNotification(`Loaded circuit: "${circuitName}" ${id ? `(${id})` : ""}`, "success");
}

export function getProjectFromCircuitLoad(dbCircuit: DBCircuit): Project {
    const id = dbCircuit.id || null;
    const circuit = getNewCircuitDefinition(dbCircuit.circuit);

    return {
        id,
        privateId: dbCircuit.privateId || null,
        type: "circuit",
        title: dbCircuit.title,
        description: dbCircuit.description,
        dialog: circuit.dialog,
        canvas: circuit.canvas,
        show: circuit.show,
        points: circuit.points,
        list: circuit.list,
        analysis: circuit.analysis,
    };
}

export function getProjectFromCircuitFile(circuitFile: FileCircuit): Project {
    // TODO: check version
    const circuit = getNewCircuitDefinition(circuitFile);

    // v3 - v5 definition
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const oldFile = circuitFile as any;
    if (typeof oldFile?.circuit?.name === "string")
        circuitFile.title = oldFile.circuit.name;
    if (typeof oldFile?.circuit?.description === "string")
        circuitFile.description = oldFile.circuit.description;

    return {
        id: null,
        privateId: null,
        type: "circuit",
        title: circuitFile.title,
        description: circuitFile.description,
        dialog: circuit.dialog,
        canvas: circuit.canvas,
        show: circuit.show,
        points: circuit.points,
        list: circuit.list,
        analysis: circuit.analysis,
    };
}

export async function loadProjectFormDB(id: number): Promise<null | Project> {
    const dbCircuit = await dbSelectRow<DBCircuit>("circuit", id);
    if (!dbCircuit) return null;

    const project = getProjectFromCircuitLoad(dbCircuit);
    setProjectHeaders(project);

    return project;
}

export function getCircuitFile(project: ProjectCircuit): FileCircuit {
    const { id, privateId, type, ...circuit } = project;
    return {
        program: "GEEC",
        version: packageJSON.version,
        ...circuit,
    };
}

export function getCircuitToSave(project: ProjectCircuit, user: User): Omit<DBCircuit, "id"> {
    const { title, description, privateId, ...circuit } = project;

    const circuitToSave: Omit<DBCircuit, "id"> = {
        user: user.name,
        date: moment().format("YYYY-MM-DD HH:mm:ss"), // MySQL format
        title,
        description,
        privateId: privateId || 0,
        circuit,
    };

    return circuitToSave;
}

export async function saveProjectToDB(project: Project, user: User): Promise<Project> {
    if (project.type === "circuit") {
        const circuitToSave = getCircuitToSave(project, user);

        if (project.id) {
            await updateCircuit({ ...circuitToSave, id: project.id }, user);
            showNotification("Project saved to the server.", "success");
            return project;
        } else {

            const id = await insertCircuit(circuitToSave, user);
            if (id instanceof Error) {
                showNotification(id.message, "error");
                return project;
            } else {
                showNotification("Project saved to the server with id: " + id + ".", "success");
                return { ...project, id };
            }
        }
    } else {
        // TODO: implement save component
        throw new Error("Not implemented");
    }
}


export function getNewProject(type: Project["type"], store: Store): Store {
    if (type === "component") {
        return {
            ...store,
            action: getEmptyAction(),
            project: getEmptyComponentProject(),
        };
    } else {
        return {
            ...store,
            action: getEmptyAction(),
            project: getEmptyCircuitProject(),
        };
    }
}
