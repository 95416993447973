import { Button, ButtonGroup, Input, InputAdornment, Tooltip, useTheme } from "@mui/material";
import { mdiArrowExpandHorizontal, mdiBorderInside, mdiMagnifyMinusOutline, mdiMagnifyPlusOutline } from "@mdi/js";

import { useProject } from "../services/project";
import { Icon, Grid } from ".";
import { CanvasSetting } from "../types";

export default function BottomMenu() {
    const theme = useTheme();
    const { project, setProject } = useProject();

    const { canvas } = project;
    function setCanvas(canvas: CanvasSetting) {
        setProject({ ...project, canvas });
    }

    function handleZoom(zoom: number) {
        if (zoom < 0.25) zoom = 0.25;
        if (zoom > 8) zoom = 8;

        setCanvas({ ...canvas, zoom });
    }

    function handleGrid() {
        setCanvas({ ...canvas, grid: { ...canvas.grid, show: !canvas.grid.show } });
    }

    function handleGridSize(size: number) {
        if (size < 1) size = 1;
        if (size > 100) size = 100;

        setCanvas({ ...canvas, grid: { ...canvas.grid, size } });
    }
    function handleGridEachLine(eachLine: number) {
        if (eachLine < 1) eachLine = 1;
        if (eachLine > 100) eachLine = 100;

        setCanvas({ ...canvas, grid: { ...canvas.grid, eachLine } });
    }

    function handleShift(direction: "up" | "down" | "left" | "right") {
        switch (direction) {
            case "up":
                setCanvas({ ...canvas, shift: { ...canvas.shift, y: canvas.shift.y - (canvas.zoom * 100) } });
                break;
            case "down":
                setCanvas({ ...canvas, shift: { ...canvas.shift, y: canvas.shift.y + (canvas.zoom * 100) } });
                break;
            case "left":
                setCanvas({ ...canvas, shift: { ...canvas.shift, x: canvas.shift.x - (canvas.zoom * 100) } });
                break;
            case "right":
                setCanvas({ ...canvas, shift: { ...canvas.shift, x: canvas.shift.x + (canvas.zoom * 100) } });
                break;
        }
    }

    return <Grid container columnSpacing={3} flexDirection="row" alignItems="center" justifyContent="flex-end" mr={0}>
        <Grid sx={{ pointerEvents: "all" }}>
            <ButtonGroup size="small" variant="outlined" sx={{ bgcolor: theme.palette.background.default }}>
                <Button onClick={() => handleZoom(canvas.zoom * 2)}><Icon path={mdiMagnifyPlusOutline} /></Button>
                <Button>{(canvas.zoom * 100).toFixed(0)} %</Button>
                <Button onClick={() => handleZoom(canvas.zoom / 2)}><Icon path={mdiMagnifyMinusOutline} /></Button>
            </ButtonGroup>
        </Grid>
        <Grid sx={{ pointerEvents: "all" }}>
            <ButtonGroup size="small" variant="outlined" sx={{ bgcolor: theme.palette.background.default }}>
                <Button
                    sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                    variant={canvas.grid.show ? "contained" : "outlined"}
                    onClick={handleGrid}>
                    Grid
                </Button>
                <Tooltip title="Grid size">
                    <Input
                        sx={{ width: 80, marginLeft: 1, marginRight: 1 }}
                        type="number"
                        value={canvas.grid.size}
                        onChange={(e) => handleGridSize(Number(e.target.value))}
                        startAdornment={
                            <InputAdornment position="start">
                                <Icon path={mdiArrowExpandHorizontal} />
                            </InputAdornment>
                        }
                    />
                </Tooltip>
                <Tooltip title="Show each">
                    <Input
                        sx={{ width: 80, marginLeft: 1, marginRight: 1 }}
                        type="number"
                        value={canvas.grid.eachLine}
                        onChange={(e) => handleGridEachLine(Number(e.target.value))}
                        startAdornment={
                            <InputAdornment position="start">
                                <Icon path={mdiBorderInside} />
                            </InputAdornment>
                        }
                    />
                </Tooltip>
            </ButtonGroup>
        </Grid>

        <Grid sx={{ pointerEvents: "all" }}>
            {/* eslint-disable max-len */}
            <svg version={"1.1"} id={"canvas-shift"} xmlns={"http://www.w3.org/2000/svg"} x={"0px"} y={"0px"} width={"60px"} height={"60px"} viewBox={"0 0 60 60"} enableBackground={"new 0 0 60 60"} >
                <g transform={"rotate(0), translate(0,15)"} onClick={() => handleShift("left")}>
                    <polygon fill={"#FFFFFF"} points={"1.108,14.174 13.687,1.595 13.687,7.874 22.658,7.874 28.958,14.175 22.658,20.475 13.687,20.475 13.688,26.755 	"} />
                    <path fill={theme.palette.primary.main} opacity={0.5} d={"M13.187,2.802v4.572v1h1h8.264l5.8,5.801l-5.8,5.8h-8.264h-1v1l0,4.572L1.815,14.174L13.187,2.802 M14.187,0.388L0.401,14.174l13.787,13.788l-0.001-6.987h8.678l6.8-6.8l-6.8-6.801h-8.678V0.388L14.187,0.388z"} />
                </g>
                <g transform={"rotate(90), translate(-1,-44)"} onClick={() => handleShift("up")}>
                    <polygon fill={"#FFFFFF"} points={"1.108,14.174 13.687,1.595 13.687,7.874 22.658,7.874 28.958,14.175 22.658,20.475 13.687,20.475 13.688,26.755 	"} />
                    <path fill={theme.palette.primary.main} opacity={0.5} d={"M13.187,2.802v4.572v1h1h8.264l5.8,5.801l-5.8,5.8h-8.264h-1v1l0,4.572L1.815,14.174L13.187,2.802 M14.187,0.388L0.401,14.174l13.787,13.788l-0.001-6.987h8.678l6.8-6.8l-6.8-6.801h-8.678V0.388L14.187,0.388z"} />
                </g>
                <g transform={"rotate(180), translate(-60,-43)"} onClick={() => handleShift("right")}>
                    <polygon fill={"#FFFFFF"} points={"1.108,14.174 13.687,1.595 13.687,7.874 22.658,7.874 28.958,14.175 22.658,20.475 13.687,20.475 13.688,26.755 	"} />
                    <path fill={theme.palette.primary.main} opacity={0.5} d={"M13.187,2.802v4.572v1h1h8.264l5.8,5.801l-5.8,5.8h-8.264h-1v1l0,4.572L1.815,14.174L13.187,2.802 M14.187,0.388L0.401,14.174l13.787,13.788l-0.001-6.987h8.678l6.8-6.8l-6.8-6.801h-8.678V0.388L14.187,0.388z"} />
                </g>
                <g transform={"rotate(-90), translate(-59,16)"} onClick={() => handleShift("down")}>
                    <polygon fill={"#FFFFFF"} points={"1.108,14.174 13.687,1.595 13.687,7.874 22.658,7.874 28.958,14.175 22.658,20.475 13.687,20.475 13.688,26.755 	"} />
                    <path fill={theme.palette.primary.main} opacity={0.5} d={"M13.187,2.802v4.572v1h1h8.264l5.8,5.801l-5.8,5.8h-8.264h-1v1l0,4.572L1.815,14.174L13.187,2.802 M14.187,0.388L0.401,14.174l13.787,13.788l-0.001-6.987h8.678l6.8-6.8l-6.8-6.801h-8.678V0.388L14.187,0.388z"} />
                </g>
            </svg>
        </Grid>
    </Grid>;
}