import { USE_NODE_MANGLING } from "../settings";
import { AnalysisComponent, AnalysisCircuit, BasicAnalysisType,
    InOutDict, Result, Analysis, ComponentInputProperty } from "../types/analysis";
import { ComponentInputPropertyID } from "../types/ComponentsInputPropertyID";
import { parseExpression } from "./expressions";
import { parseFunction } from "./functions";

/** Get contact points without contact point "0" */
export function getContactPoints(proj: AnalysisCircuit): string[] {
    const nodes = proj.components.map(c => c.contactPoints).flat(1);
    const uniqueValues = new Set(nodes);
    const uniqueArray = Array.from(uniqueValues).filter(p => p !== "0");
    uniqueArray.sort();
    return uniqueArray;
}

export function getOutputDict(proj: AnalysisCircuit, analysisType: BasicAnalysisType): InOutDict {
    const componentOutputs = proj.components.map(c => {
        const analysisRelevantProps = c.outputProperties.filter(p => p.allowedAnalysis[analysisType]);
        return { name: c.tag, values: analysisRelevantProps.map(p => renderComponentProperty(c, p.name)) };
    });

    const componentOutputsFiltered = componentOutputs.filter(p => p.values.length !== 0);
    componentOutputsFiltered.sort((a, b) => a.name.localeCompare(b.name));

    return [{ name: "Nodes", values: getContactPoints(proj).map(n => `v(${n})`) }, ...componentOutputsFiltered];
}

export function getInputDict(proj: AnalysisCircuit, analysisType: BasicAnalysisType): InOutDict {
    const componentInputs = proj.components.map(c => {
        const analysisRelevantProps = c.inputProperties.filter(p => p.allowedAnalysis[analysisType]);
        return { name: c.tag, values: analysisRelevantProps.map(p => renderComponentProperty(c, p.name)) };
    });

    const componentInputsFiltered = componentInputs.filter(p => p.values.length !== 0);
    componentInputsFiltered.sort((a, b) => a.name.localeCompare(b.name));

    return componentInputsFiltered;
}

export function renderComponentProperty(component: AnalysisComponent, expr: string): string {
    let newExpr = textReplaceAll("@tag", component.tag, expr);
    component.contactPoints.forEach((node, index) => {
        newExpr = textReplaceAll(`@node[${index}]`, mangleNode(node), newExpr);
    });

    return newExpr;
}

export function _renderNetListAllowance(component: AnalysisComponent): string {
    let newAllowance = "";
    if (component.allowance !== "") {
        //newAllowance = textReplaceAll("@name", component.name, component.allowance);
        newAllowance = textReplaceAll("@tag", component.tag, component.allowance);

        component.contactPoints.forEach((node, index) => {
            newAllowance = textReplaceAll(`@node[${index}]`, mangleNode(node), newAllowance);
        });

        component.inputProperties.forEach((prop, index) => {
            newAllowance = textReplaceAll(`@label[${index}]`, prop.label, newAllowance);
            newAllowance = textReplaceAll(`@value[${index}]`, prop.value, newAllowance);
            newAllowance = textReplaceAll(`@used[${index}]`, prop.used.toString(), newAllowance);
        });

        newAllowance = _replaceEvalAllowance(newAllowance);

        return newAllowance;
    }

    return newAllowance;
}

function _replaceEvalAllowance(allowance: string): string {
    const re = new RegExp(/(?=\$\{).*(\})/, "g");
    return allowance.replace(re, d => eval(d.substring(2, d.length - 1)));
}

export function textReplaceAll(find: string, replace: string, str: string): string {
    return str.replace(new RegExp(find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1"), "g"), replace);
}

// export function textReplaceAllReg(reg: string, replace: string, str: string): string {
//     return str.replace(new RegExp(reg, "g"), replace);
// }

export function getInputPropertyByID(
    components: AnalysisComponent, id: ComponentInputPropertyID): ComponentInputProperty | null {
    return components.inputProperties.find(p => p.id === id) || null;
}

export function getAllInputProps(proj: AnalysisCircuit): string[] {
    const props = proj.components.map(c => c.inputProperties.map((p) => renderComponentProperty(c, p.name)));
    return props.flat(1);
}

export function getAllOutputProps(proj: AnalysisCircuit): string[] {
    const props = proj.components.map(c => c.outputProperties.map((p) => renderComponentProperty(c, p.name)));
    return props.flat(1);
}

export function getUsedInputProps(expr: string, proj: AnalysisCircuit): string[] {
    const props = getAllInputProps(proj);
    const { args } = parseExpression(expr);
    return args.filter(item => props.includes(item));
}

export function getUsedOutputProps(expr: string, proj: AnalysisCircuit): string[] {
    const props = getAllOutputProps(proj);
    const { args } = parseExpression(expr);
    return args.filter(item => props.includes(item));
}

type Variable = "NodeVoltage" | "InputProperty" | "OutputProperty";

export function identifyVariable(variable: string, proj: AnalysisCircuit): Variable {
    // TODO: check NodeVoltage
    // TODO: check InputProperty
    // TODO: check OutputProperty
    // TODO: check DV
    // TODO: check global vars like: freq, temp, time, ...
    // TODO: check constants infinity, pi, ...

    if (getAllInputProps(proj).includes(variable)) {
        // return InputProperty
    } else if (getAllOutputProps(proj).includes(variable)) {
        // return OutputProperty
    }
    // TODO: add all
    return "InputProperty";
}

/**
 * Wrapper function for getDataByID and getDataByName
 *
 * @param proj
 * @param expr
 * @returns
 */
export function getGlobalAnalysisData(proj: AnalysisCircuit, expr: string): Result {
    const { template, args } = parseFunction(expr);

    if (args.length < 2) {
        throw new Error(`Invalid function syntax "${expr}" (getGlobalAnalysisData)`);
    }

    const analysisName = args[0].replaceAll("\"", "");
    const analysis = getAnalysisByName(proj, analysisName);

    if (template.startsWith("getDataByID")) {
        const id = Number(args[1]);
        return getResultByID(analysis, id);
    } else if (template.startsWith("getDataByName")) {
        const name = args[1];
        return getResultByName(analysis, name);
    } else {
        throw new Error(`Invalid function name "${expr}" (getGlobalAnalysisData)`);
    }
}

export function getAnalysisByName(proj: AnalysisCircuit, name: string): Analysis {
    const found: Analysis | null = proj.analyses.find((analysis) => analysis.name === name) || null;

    if (!found) {
        throw new Error(`Invalid analysis name "${name}" (getAnalysisByName)`);
    }

    return found;
}

export function getResultByName(analysis: Analysis, name: string): Result {
    const found = analysis.results.find((rslt) => rslt.name === name);

    if (!found) {
        throw new Error(`Invalid result name "${name}" (getResultByName)`);
    }

    return found;
}

export function getResultByID(analysis: Analysis, id: number): Result {
    const found = analysis.results.find((rslt) => rslt.id === id);

    if (!found) {
        throw new Error(`Invalid result id "${id}" (getResultByID)`);
    }

    return found;
}

export function parseScriptMacro(macro: string, line: string): string[] | null {
    const reg = new RegExp(`@${macro}(.*)\$`);
    const match = line.match(reg);
    if (match) {
        const argsRaw = match[1];
        const matchArgs = argsRaw.match(/\[(.*)\]$/);

        if (matchArgs) {
            const params = matchArgs[1].split(",").map(p => p.trim());
            return params;
        } else {
            return [];
        }
    } else {
        return null;
    }
}

/** Mangle node names except node "0" */
export function mangleNode(name: string): string {
    if(USE_NODE_MANGLING){
        return name !== "0" ? `node_${name}` : name;
    } else {
        return name;
    }
}