/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import { AnalysisHandler } from "../core/analysisHandler";
import { mapComputedResults } from "../core/core_draft";
import { sendPracanRequest } from "../settings";
import { getSpiceGenerator, spiceGetNetlist, SpiceNetlistGenerator } from "../spice/netlist";
import { ServerResponse } from "../types";
import {
    AnalysisCircuit, AnalysisComponent, AnalysisConfig, AnalysisType, BasicAnalysisType,
    ComponentOutputProperty, Result, ResultDataType, ResultFormat, ResultValue, ResultValueType, SimResultExpression,
    Simulation} from "../types/analysis";
import { isExpression } from "../utils/expressions";
import { getContactPoints, mangleNode, renderComponentProperty } from "../utils/utilities";
import { PracanAnalysisType } from "./types";

type SimulationCMD = "dc" | "ac" | "tran" | "tf"

export interface PracanAnalysisHandler extends AnalysisHandler {
    getSimulationCmd: () => string;
    /** TODO: again, when to run it?? */
    prerunOPAnalysis: (proj: AnalysisCircuit) => string;
    getOutputDict: (sett: Simulation, reqRslt: SimResultExpression[]) => string;
    saveOutput: (sett: Simulation) => string;
}

function pracanDefaultHandler(): PracanAnalysisHandler {
    return {
        //getNetlist: getPracanNetlist,
        //getRequestData: getRequestDataGeneric,
        getFinalResults: mapComputedResults,
        getDefaultResults: () => [],
        getAvailableResults: pracanGetDefaultResults,
        generateScript: pracanGenerateScript,
        parseData: pracanParseData,
        parseErrors: (result: ServerResponse) => "",
        sendRequest: sendPracanRequest,
        getSimulationCmd: () => "",
        prerunOPAnalysis: (proj: AnalysisCircuit) => "",
        saveOutput: (sett: Simulation) => pracanExportOutput(
            "map(r -> fprintf(fp, \"%s:=%s\\n\",convert(lhs(r),string),latex(rhs(r),output=string)), output_results):"),
        //"map(r -> fprintf(fp, \"%s:=%a\\n\",convert(lhs(r),string),latex(rhs(r),output=string)), output_results):"),
        getOutputDict: pracanGetOutputDict,
        //prepareRequest: () => { return {script: "", requestData: []} },
    };
}

export function pracanDCHandler(): PracanAnalysisHandler {
    return {
        ...pracanDefaultHandler(),
        getSimulationCmd: () => "SetAnalysis(dc, symbolic, SAVE=all):",
    };
}

export function pracanDCSemiHandler(): PracanAnalysisHandler {
    return {
        ...pracanDefaultHandler(),
        getSimulationCmd: () => "SetAnalysis(dc, numeric, SAVE=all):",
        saveOutput: pracanSaveOutput,
    };
}

export function pracanACHandler(): PracanAnalysisHandler {
    return {
        ...pracanDefaultHandler(),
        getSimulationCmd: () => "SetAnalysis(ac, symbolic, SAVE=all):",
        prerunOPAnalysis: pracanPrerunOP,
    };
}

export function pracanTFHandler(): PracanAnalysisHandler {
    return {
        ...pracanDefaultHandler(),
        getSimulationCmd: () => "SetAnalysis(tf, symbolic, SAVE=all):",
        prerunOPAnalysis: pracanPrerunOP,
    };
}

export function pracanTransientHandler(): PracanAnalysisHandler {
    return {
        ...pracanDefaultHandler(),
        getSimulationCmd: () => "SetAnalysis(tran, symbolic, SAVE=all):",
        prerunOPAnalysis: pracanPrerunOP,
    };
}

export function pracanTranSemiHandler(): PracanAnalysisHandler {
    return {
        ...pracanDefaultHandler(),
        getSimulationCmd: () => "SetAnalysis(tran, numeric, SAVE=all):",
        prerunOPAnalysis: pracanPrerunOP,
    };
}

export function pracanGetAnalysisHandler(analysisType: PracanAnalysisType): PracanAnalysisHandler {
    switch (analysisType) {
        case "PracanOPSym":
            return pracanDCHandler();
        case "PracanOPSemi":
            return pracanDCSemiHandler();
        case "PracanACSym":
        case "PracanACSemi":
        case "PracanACGraph":
            return pracanACHandler();
        case "PracanTranSemi":
            return pracanTranSemiHandler();
        case "PracanTranSym":
        case "PracanTranGraph":
            return pracanTransientHandler();
        case "PracanTFSym":
        case "PracanTFSemi":
        case "PracanTFGraph":
            return pracanTFHandler();
        default:
            throw new Error(`AnalysisHandler for ${analysisType} not implemented`);
    }
}

function pracanGetSimulationType(analysisType: PracanAnalysisType): SimulationCMD {
    switch (analysisType) {
        case "PracanOPSym":
        case "PracanOPSemi":
            return "dc";
        case "PracanACSym":
        case "PracanACSemi":
        case "PracanACGraph":
            return "ac";
        case "PracanTranSym":
        case "PracanTranSemi":
        case "PracanTranGraph":
            return "tran";
        case "PracanTFSym":
        case "PracanTFSemi":
        case "PracanTFGraph":
            return "tf";
        default:
            throw new Error(`Conversion Analysis type ${analysisType} to SimulationType not supported`);
    }
}

function pracanGetAnalysisBasicType(analysisType: PracanAnalysisType): BasicAnalysisType {
    switch (analysisType) {
        case "PracanTranSym":
        case "PracanTranSemi":
        case "PracanTranGraph":
            return "tran";
        case "PracanACSym":
        case "PracanACSemi":
        case "PracanACGraph":
        case "PracanTFSym":
        case "PracanTFSemi":
        case "PracanTFGraph":
            return "ac";
        case "PracanOPSym":
        case "PracanOPSemi":
        default:
            return "dc";
    }
}

function pracanSaveOutput(sett: Simulation): string {
    const format: ResultFormat = sett.format || "Latex";

    switch(format){
        case "Exp":
            return pracanExportOutput(
                "map(r -> fprintf(fp, \"%s:=%e\\n\",convert(lhs(r),string),rhs(r)), output_results):");
        case "Float":
            return pracanExportOutput(
                "map(r -> fprintf(fp, \"%s:=%f\\n\",convert(lhs(r),string),rhs(r)), output_results):");
        case "Latex":
        default:
            return pracanExportOutput(
                "map(r -> fprintf(fp, \"%s:=%s\\n\",convert(lhs(r),string),latex(rhs(r),output=string)), output_results):");
    }
}

/**
 * If nonlinear components are present, the OP analysis shall be run
 * before the AC and TF semisymbolic analysis.
 * (Pracan supports only Diode, BJT, MOSFET)
 * @param proj AnalysisCircuit
 * @returns Commands to run OP (DC) analysis
 */
function pracanPrerunOP(proj: AnalysisCircuit): string {
    // TODO: check also if subcircuits contain nonlinear components
    if(proj.components.some(c => ["Diode", "BJT", "MOSFET"].includes(c.class))) {
        return "SetAnalysis(dc):\nAnalyze():\n";
    }else{
        return "";
    }
}

function pracanExportOutput(fprintf: string): string {
    let out = "";
    out += "fp:=fopen(\"{{OUT}}\", WRITE, TEXT):\n";
    out += `${fprintf}\n`;
    out += "fclose(fp);\n";
    return out;
}

function pracanGetOutputDict(sett: Simulation, reqRslt: SimResultExpression[]): string {
    if(reqRslt.length !== 0){
        let out = "output_results := {\n";
        const rsltAssign = reqRslt.map(r => `result_${r.id} = subs(pracanResults,${r.expr})`);
        out += rsltAssign.join(",\n");
        out += "\n}:\n";
        return out;
    }else {
        return "output_results := {}:\n";
    }
}

function pracanScriptImports(): string {
    let imports = "";
    imports += "with(PraCAn):\n";
    imports += "with(StringTools):\n";
    imports += "with(JSON):\n";
    imports += "with(GEECUtilities):\n";
    return imports;
}

function pracanGenerateScript(
    proj: AnalysisCircuit, simSettings: AnalysisConfig, reqRslt: SimResultExpression[]): string {
    const analysisType = simSettings.analysisType as PracanAnalysisType;
    const analysisHandler: PracanAnalysisHandler = pracanGetAnalysisHandler(analysisType);
    const simType: SimulationCMD = pracanGetSimulationType(analysisType);
    return `#${proj.title}
restart;

${pracanScriptImports()}

interface(imaginaryunit=j):
locImagUnt := interface(imaginaryunit):

# temporary function to convert dictionary to string (TODO: include to GEECUtilities)
convert_dict_to_string := proc(dict)
    local entries, keys, values, i, n, result;

    n := nops(dict):

    result := "{";
    for i from 1 to n do
        blah := substring(ToString(substring(rhs(dict[i]), 2..-2)), 2..-2);
        result := cat(result, sprintf(\`"%s": %a\`, convert(lhs(dict[i]), string), blah));

        if i < n then
            result := cat(result, ", ");
        end if;
    end do;
    result := cat(result, "}");

    return result;
end proc:

_netlist := "
${getPracanNetlist(proj)}
.end":

all:=fixAllForCoupledInductors(_netlist,${simType}):
ParseCircuit(_netlist):
${analysisHandler.prerunOPAnalysis(proj)}

${analysisHandler.getSimulationCmd()}

pracanResults:=Analyze()[2]:
#pracanResults:=NameModification(pracanResults):

#_exportObject := {seq(lhs(pracanResults[g]) = latex(rhs(pracanResults[g]),output=string), g = 1 .. nops([pracanResults[]]))}:

${analysisHandler.getOutputDict(simSettings, reqRslt)}

${analysisHandler.saveOutput(simSettings)}
`;
}

export function getPracanNetlist(proj: AnalysisCircuit): string {
    const defaultGenerator: SpiceNetlistGenerator = getSpiceGenerator();

    const pracanGenerator: SpiceNetlistGenerator = {
        ...defaultGenerator,
        transferFunction: defaultGenerator.default,
    };

    return spiceGetNetlist(proj, pracanGenerator);
}

function pracanGetNodeVoltages(sett: AnalysisCircuit): SimResultExpression[] {
    return getContactPoints(sett).map(n => {
        return {
            id: -1,
            name: `v(${n})`,
            expr: `v("${mangleNode(n)}")`,
        };
    });
}

function pracanGetDefaultResults(sett: AnalysisCircuit, analysisType: AnalysisType): SimResultExpression[] {
    const defaultResults: SimResultExpression[] = pracanGetNodeVoltages(sett);

    sett.components.forEach((component: AnalysisComponent) => {
        const outputProperties: ComponentOutputProperty[] = pracanGetComponentOutputProperties(component, pracanGetAnalysisBasicType(analysisType as PracanAnalysisType));
        outputProperties.forEach((outProp: ComponentOutputProperty) => {
            const renderedOutput = {
                id: -1,
                name: renderComponentProperty(component, outProp.name),
                expr: renderComponentProperty(component, outProp.expr),
            };

            const finalResultNames = defaultResults.map(r => r.name);

            // check autogenerated "v(0)" node
            renderedOutput.expr = renderedOutput.expr.replaceAll("v(\"0\")", "0");


            if (isExpression(renderedOutput.expr) || finalResultNames.some(name => renderedOutput.expr.includes(name))) {
                defaultResults.forEach((def: SimResultExpression) => {
                    renderedOutput.expr = renderedOutput.expr.replaceAll(def.name, def.expr);
                });
            }

            defaultResults.push(renderedOutput);
        });
    });

    return defaultResults;
    // [{ id: -1, name: "i(r1)", expr: "@r1[i]" }, { id: -1, name: "v(1)", expr: "v(1)" }];//, { id: 2, name: "p(r1)", expr: "v(1)*i(r1)" }
}


export function pracanParseData(data: string): Result[] {
    // const finalData: SimulationRawData[] = [];
    // const parsed = JSON.parse(data);
    // for (const key in parsed) {
    //     finalData.push({
    //         name: key,
    //         value: parsed[key],
    //         valueType: "Real",
    //         dataType: "Value",
    //     });
    // }
    // return finalData;
    const dataArr: Result[] = [];
    const lines = data.split("\n");

    lines.forEach((line: string) => {
        const match = line.match(/^\s*(.*):=\s*(.*)$/);
        if (match) {
            const rhs = match[2];
            // match exponetial form (TODO: regex add ^...$??)
            const format: ResultFormat = rhs.match(/\d+.\d+e[+-]\d+/)? "Exp": "Latex";
            const dataType: ResultDataType = "Value";// : "Array";
            const valueType: ResultValueType = "Real";
            let resultValue: ResultValue = null;

            try {
                // if (matchLatex) {
                //     resultValue = matchLatex[1].trim();
                // } else if (JSON.parse(rhs)) {
                resultValue = rhs;
                //   }
            } catch {
                // An error occurred during parsing -> invalidate data
                resultValue = null;
            }

            dataArr.push({
                id: -1,
                name: match[1].trim(),
                value: resultValue,
                valueType: valueType,
                dataType: dataType,
                format: format,
            });
        }
    });

    return dataArr;
}

export function pracanGetComponentOutputProperties(component: AnalysisComponent, type: BasicAnalysisType): ComponentOutputProperty[] {
    // reduce is map and filter function in one
    return component.outputProperties.reduce<ComponentOutputProperty[]>((acc: ComponentOutputProperty[], outProp: ComponentOutputProperty) => {
        // If the analysis is not allowed, do not add the output property
        if (outProp.allowedAnalysis[type] === false) {
            return acc;
        }

        // If the expression is already set, do not change it
        if (outProp.expr !== "") {
            acc.push({ ...outProp });
            return acc;
        }

        let expr = "";
        switch (outProp.id) {
            case "r_voltage":
            case "l_voltage":
            case "c_voltage":
            case "v_voltage":
            case "i_voltage":
            case "diode_voltage":
            case "sp_voltage":
            {
                expr = "v(\"@tag\")";
                break;
            }
            case "r_power":
            case "l_power":
            case "c_power":
            case "v_power":
            case "i_power":
            case "bjt_power":
            case "diode_power":
            {
                expr = "v(\"@tag\")*i(\"@tag\")";
                //expr = "@@tag[p]";
                break;
            }
            case "r_current":
            case "v_current":
            case "c_current":
            case "l_current":
            {
                expr = "i(\"@tag\")";
                break;
            }
            case "bjt_icollector": expr = "i(\"@tag:C\")"; break;
            case "bjt_ibase": expr = "i(\"@tag:B\")"; break;
            case "bjt_iemitter": expr = "i(\"@tag:E\")"; break;
            default: expr = outProp.expr;
        }
        acc.push({
            ...outProp,
            expr: expr,
        });
        return acc;
    }, []);
}