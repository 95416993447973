import {
    Action, AnalysisProject, Component, Keyboard, Mouse, Project, Store, StyleDraw, StyleText, User, ShowSetting,
    DialogNotShow,
} from "../types";

export function getDefaultMouse(): Mouse {
    return {
        x: 0,
        y: 0,
        mouseDown: null,
        isDrag: false,
    };
}

export function getDefaultKeyboard(): Keyboard {
    return {
        ctrl: false,
        cmd: false,
        shift: false,
        alt: false,
        key: null,
    };
}

export function getDefaultStyleDraw(): StyleDraw {
    return {
        width: 2,
        color: "#000000",
        cap: "round",
        dash: "none",
        lineJoin: "round",
        closePath: false,
        fill: false,
        fillColor: "#0000FF",
    };
}
export function getDefaultStyleText(): StyleText {
    return {
        font: "Arial",
        fontSize: 24,
        color: "#000000",
        fontAlignVertical: "left",
        fontAlignHorizontal: "bottom",
    };
}

export function getDefaultUser(): User {
    return {
        id: 0,
        name: "Not logged in",
        login: "",
        isLogged: false,
        isAdmin: false,
    };
}

export function getDefaultDialog(): DialogNotShow {
    return { show: false };
}

export function getEmptyStore(): Store {
    const emptyCircuitProject = getEmptyCircuitProject();
    return {
        user: getDefaultUser(),
        action: getEmptyAction(),
        project: emptyCircuitProject,
        history: {
            index: 0,
            projects: [emptyCircuitProject],
        },
        historyAction: "no-record",
    };
}

export function getEmptyAction(): Action {
    return {
        activeCanvas: false,
        style: {
            draw: getDefaultStyleDraw(),
            text: getDefaultStyleText(),
        },
        mouse: getDefaultMouse(),
        keyboard: getDefaultKeyboard(),
        help: getDefaultDialog(),
        drawing: null,
        paste: null,
        renameNode: null,
        analysisResults: [],
    };
}

export function getEmptyAnalysis(): AnalysisProject {
    return {
        parametricRanges: [],
        designVariables: [],
        fastAnalysis: [],
        analyses: [],
    };
}

export function getEmptyComponent(): Component {
    return {
        id: 0,
        allowance: "",
        basic: false,
        category: 0,
        componentName: "New component",
        alternations: {},
        netlistContactPoints: [],
        model: "",
        description: "",

        allowAnalysis: {
            nAC: true,
            nDC: true,
            nOP: true,
            nPZ: true,
            nTran: true,
            sAC: true,
            sGraphAC: true,
            sGraphPZ: true,
            sGraphTF: true,
            sGraphTran: true,
            sOP: true,
            sSemiAC: true,
            sSemiOP: true,
            sSemiTF: true,
            sSemiTran: true,
            sTF: true,
            sTran: true,
        },
        properties: [],
        analysisProperties: [],

        useModel: false,
        showTag: true,
        tag: {
            name: "ComponentText",
            point: 0,
            rotate: 0,
            style: getDefaultStyleText(),
            text: "X",
        },
        value: {
            name: "ComponentText",
            point: 1,
            rotate: 0,
            style: getDefaultStyleText(),
        },

        dialog: getDefaultDialog(),

        list: [],
    };
}

export function getDefaultShowSetting(isComponentProject: boolean): ShowSetting {
    return {
        tag: true,
        value: true,
        currentArrow: isComponentProject,
    };
}

export function getEmptyComponentProject(): Project {
    return {
        id: null,
        title: "New component",
        description: "",
        privateId: null,
        dialog: getDefaultDialog(),
        canvas: {
            zoom: 1,
            shift: { x: 0, y: 0 },
            grid: {
                show: true,
                size: 24,
                eachLine: 1,
            },
        },
        show: getDefaultShowSetting(true),
        points: [],
        list: [],
        type: "component",
        component: getEmptyComponent(),
    };
}

export function getEmptyCircuitProject(): Project {
    return {
        id: null,
        privateId: null,
        title: "New circuit",
        description: "",
        dialog: getDefaultDialog(),
        canvas: {
            zoom: 1,
            shift: { x: 0, y: 0 },
            grid: {
                show: true,
                size: 24,
                eachLine: 1,
            },
        },
        show: getDefaultShowSetting(false),
        points: [],
        list: [],
        type: "circuit",
        analysis: getEmptyAnalysis(),
    };
}