/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */

// analysis imports
import { RequestFunction } from "./types";

// application imports
import { GEECMapleRequest, GEECNGSpiceRequest, GEECPythonRequest } from "../services/api";

// export for analysis core
export const sendNGSpiceRequest: RequestFunction = GEECNGSpiceRequest;
export const sendSymcircRequest: RequestFunction = GEECPythonRequest;
export const sendPracanRequest: RequestFunction = GEECMapleRequest;

// version information
export const VERSION_NGSPICE = 38;
export const VERSION_SYMCIRC = "0.0.15";

// core settings
export const USE_NODE_MANGLING = false;
export const LATEX_PATCH_IM_UNIT = true;

// debug settings
export const DEBUG = false;


// trace function (controled logging)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const trace = DEBUG ? console.log : (...data: any[]) => {};