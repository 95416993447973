import { memo, useMemo, useState } from "react";
import { Box, IconButton, Tab, Tabs, Typography } from "@mui/material";
import {
    mdiArrowTopRight, mdiArrowTopRightThin, mdiCableData, mdiChip, mdiCircleMedium, mdiCog, mdiDelete,
    mdiFormatText, mdiPlay, mdiPlus, mdiVectorBezier,
} from "@mdi/js";

import { getCircuitFolderName, getCircuitName, useAsyncEffect } from "../services/common";
import { Grid, Icon, ListItem, ListVertical } from ".";
import { getComponentList } from "../services/api";
import { useProject } from "../services/project";
import { selectItems } from "../services/action";
import { removeFromList } from "../services/list";
import { getShowDialog } from "./Dialogs";

import { List } from "../types";

export default function RightMenu() {
    const { project, setProject } = useProject();
    const [tab, setTab] = useState(1);

    const setList = useMemo(() => (list: List, historyRecord?: boolean) => setProject(
        { ...project, list }, historyRecord,
    ), [project.list]);

    const name = getCircuitName(project.title);
    const folder = getCircuitFolderName(project.title);

    return <>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", pt: 1, pl: 2 }}>
            <Box>
                <Typography>{name || "New project"}</Typography>
                {(folder || project.id) && <Typography color="text.secondary" fontSize={12}>
                    {folder} ({project.id})
                </Typography>}
            </Box>
            <IconButton onClick={() => setProject({ ...project, dialog: { show: true, type: "classic" } })}>
                <Icon path={mdiCog} />
            </IconButton>
        </Box>
        <Tabs
            value={tab}
            onChange={(_, nav) => setTab(nav)}
            variant="fullWidth"
        >
            <Tab label="Analysis" />
            <Tab label="Layers" />
        </Tabs>

        <Box sx={{ position: "relative", flexGrow: 1 }}>
            <Box sx={{ position: "absolute", top: 0, left: 0, overflow: "auto", width: "100%", height: "100%" }}>
                {tab === 0 && <>
                    <ListVertical>
                        <ListItem text="Run all (F9)" icon={mdiPlay} onClick={() => console.log("TODO")} />
                        <ListItem text="Delete all" icon={mdiDelete} onClick={() => console.log("TODO")} />
                        <ListItem text="Add section" icon={mdiPlus} onClick={() => console.log("TODO")} />
                    </ListVertical>
                </>}
                {tab === 1 && <LayersMemo list={project.list} setList={setList} />}
            </Box>
        </Box>
        <Grid
            width="100%" padding={1} bgcolor="white"
            sx={{ borderLeftStyle: "solid", borderLeftColor: "background.paper" }}
        >
            <img style={{ width: "100%", maxWidth: "180px" }} src="./images/fel.png" />
        </Grid>
    </>;
}

const LayersMemo = memo(Layers);
function Layers(
    { list, setList }: { list: List, setList: (list: List, historyRecord?: boolean) => void },
) {

    const [componentIcons, setComponentIcons] = useState<Record<number, string>>({});
    useAsyncEffect(async (isMountedRef) => {
        const components = await getComponentList();
        const componentIcons: Record<number, string> = {};
        components.forEach(c => componentIcons[c.id] = c.icon);

        if (isMountedRef.current)
            setComponentIcons(componentIcons);
    }, []);

    return <ListVertical>
        {list.map((item, i) => {
            const onClick = () => setList(selectItems(list, [item]));
            const onRemove = () => setList(removeFromList(list, [item]), true);

            switch (item.name) {
                case "Component":
                    return <ListItem
                        key={i}
                        selected={item.selected}
                        text={`${item.componentName} ${item.tag.text || ""}`}
                        textSecondary="Component"
                        icon={componentIcons[item.id] || mdiChip}
                        onClick={onClick}
                        secondaryAction={<>
                            <IconButton onClick={() => setList(list.map(i => i !== item ? i : {
                                ...i,
                                dialog: getShowDialog(),
                            }))}>
                                <Icon path={mdiCog} />
                            </IconButton>
                            <IconButton onClick={onRemove}>
                                <Icon path={mdiDelete} />
                            </IconButton>
                        </>}
                    />;
                case "ComponentText":
                case "DrawToolText":
                    return <ListItem
                        key={i}
                        selected={item.selected}
                        text={item.text || "Empty"}
                        textSecondary="Text"
                        icon={mdiFormatText}
                        onClick={onClick}
                        secondaryAction={<IconButton onClick={onRemove}>
                            <Icon path={mdiDelete} />
                        </IconButton>}
                    />;
                case "ContactPoint":
                    return <ListItem
                        key={i}
                        selected={item.selected}
                        text={item.namePoint}
                        textSecondary="Contact point"
                        icon={mdiCircleMedium}
                        onClick={onClick}
                        secondaryAction={<IconButton onClick={onRemove}>
                            <Icon path={mdiDelete} />
                        </IconButton>}
                    />;
                case "Draw":
                    return <ListItem
                        key={i}
                        selected={item.selected}
                        text={`${item.list.length} drawing tools`}
                        textSecondary="Draw"
                        icon={mdiVectorBezier}
                        onClick={onClick}
                        secondaryAction={<IconButton onClick={onRemove}>
                            <Icon path={mdiDelete} />
                        </IconButton>}
                    />;
                case "CurrentArrow":
                case "DrawToolCurrentArrow":
                    return <ListItem
                        key={i}
                        selected={item.selected}
                        text="Current arrow"
                        textSecondary="Arrow"
                        icon={mdiArrowTopRightThin}
                        onClick={onClick}
                        secondaryAction={<IconButton onClick={onRemove}>
                            <Icon path={mdiDelete} />
                        </IconButton>}
                    />;
                case "DrawToolVoltageArrow":
                    return <ListItem
                        key={i}
                        selected={item.selected}
                        text="Voltage arrow"
                        textSecondary="Arrow"
                        icon={mdiArrowTopRight}
                        onClick={onClick}
                        secondaryAction={<IconButton onClick={onRemove}>
                            <Icon path={mdiDelete} />
                        </IconButton>}
                    />;
                case "Wire":
                    return <ListItem
                        key={i}
                        selected={item.selected}
                        text="Wire"
                        icon={mdiCableData}
                        onClick={onClick}
                        secondaryAction={<IconButton onClick={onRemove}>
                            <Icon path={mdiDelete} />
                        </IconButton>}
                    />;
                default:
                    console.error("Unknown item in layers", item);
                    return null;
            }
        })}
    </ListVertical>;
}
