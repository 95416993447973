import { useEffect, useState } from "react";

import { mdiAccount, mdiAccountStar, mdiLogin, mdiLogout } from "@mdi/js";
import Icon from "@mdi/react";
import {
    Alert,
    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress, TextField, Typography,
} from "@mui/material";

import { useStore } from "./Store";
import { isUserLogged, loginUser, logoutUser } from "../services/user";

import { User } from "../types";
import { getDefaultUser } from "../services/default";


export function useUser(): User {
    const { store } = useStore();
    return store.user;
}

export function LoginButton() {
    const { store, setStore } = useStore();
    const user = store.user;

    function setUser(user: User) {
        setStore({ ...store, user });
    }

    const [showDialog, setShowDialog] = useState(false);

    const [loginName, setLoginName] = useState("");
    const [password, setPassword] = useState("");

    const [loginState, setLoginState] = useState<null | "loading" | "error">(null);

    async function handleLogin() {
        setLoginState("loading");
        const user = await loginUser(loginName, password);

        if (user) {
            setUser(user);
            setShowDialog(false);
            setLoginState(null);
        } else {
            setLoginState("error");
        }
    }

    async function handleLogout() {
        setLoginState("loading");
        await logoutUser();
        setUser(getDefaultUser());
        setShowDialog(false);
        setLoginState(null);
    }

    // login with loading app
    useEffect(() => {
        (async () => {
            const user = await isUserLogged();
            if (user) setUser(user);
        })();
    }, []);

    return <>
        {!user.isLogged && <Button
            color="inherit"
            startIcon={<Icon size={1} path={mdiLogin} />}
            onClick={() => setShowDialog(true)}
        >
            Login
        </Button>}
        {user.isLogged && <>
            <Icon size={1} path={user.isAdmin ? mdiAccountStar : mdiAccount} />
            <Typography
                noWrap
                marginLeft={1}
                marginRight={1}
                fontWeight={"bold"}
            >
                {user.name}
            </Typography>
            {user.isAdmin && <Typography>
                (admin)
            </Typography>}
            <Button
                sx={{ ml: 4 }}
                color="inherit"
                startIcon={<Icon size={1} path={mdiLogout} />}
                onClick={handleLogout}
            >
                Logout
            </Button>
        </>
        }

        <Dialog onClose={() => setShowDialog(false)} open={showDialog}>
            <form onSubmit={e => {
                e.preventDefault();
                handleLogin();
            }}>
                <DialogTitle>Login</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        The login is used to customization or manage the application.
                        Login details can only be obtained from your teacher or the application administrator.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Login name"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={loginName}
                        onChange={(e) => setLoginName(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        label="Password"
                        type="password"
                        fullWidth
                        variant="standard"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    {loginState === "error" && <Alert severity="warning">Login or password incorrect </Alert>}
                    {loginState === "loading" && <LinearProgress />}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowDialog(false)}>Cancel</Button>
                    <Button type="submit" variant="contained">Login</Button>
                </DialogActions>
            </form>
        </Dialog>
    </>;

}
