import { AnalysisComponent, ComponentInputProperty } from "../types/analysis";
import { _renderNetListAllowance, getInputPropertyByID, mangleNode } from "../utils/utilities";

export type ComponentNetlistReturn = { params: string, netlist: string, model: string };

export function getDefaultComponentNetlist(component: AnalysisComponent): ComponentNetlistReturn {
    // Note: We suppose that properties are in the correct order

    let netlist = `${component.tag} `;
    const param = "";
    component.contactPoints.forEach((node: string) => {
        // TODO: digital nodes are surrounded by [].
        netlist += `${mangleNode(node)} `;
    });

    let includedKeywordParams = false;
    component.inputProperties.forEach((prop) => {
        // TODO: If a subcircuit parameter is referenced somewhere, it has to be assigned as a param!

        if (!includedKeywordParams && prop.id === "subcirc_par") {
            netlist += "PARAMS: ";
            includedKeywordParams = true;
        }

        netlist += getComponentPropertyNetlist(prop);
        //param += `.param ${component.tag}_${prop.label}=${prop.value}`;
    });

    const model = _renderNetListAllowance(component);

    return { params: param, netlist: netlist, model: model };
}

export function getEmptyComponentNetlist(): ComponentNetlistReturn {
    return { params: "", netlist: "", model: "" };
}

/** Returns netlist for an independent source */
export function getIndependentSourceNetlist(comp: AnalysisComponent): ComponentNetlistReturn {
    if(comp.contactPoints.length < 2){
        throw new Error(`Independent source (${comp.tag}) must have at least two contact points`);
    }

    const node0 = mangleNode(comp.contactPoints[0]);
    const node1 = mangleNode(comp.contactPoints[1]);
    let netlist = `${comp.tag} ${node0} ${node1}`;

    const dc = getInputPropertyByID(comp, "voltage_dc") || getInputPropertyByID(comp, "current_dc");
    const acmag = getInputPropertyByID(comp, "voltage_acmag") || getInputPropertyByID(comp, "current_acmag");
    const acph = getInputPropertyByID(comp, "voltage_acphase") || getInputPropertyByID(comp, "current_acphase");

    netlist += ` DC ${dc?.value || "0"}`;
    netlist += ` AC ${acmag?.value || "0"} ${acph?.value || "0"}`;

    switch (comp.class) {
        case "IndepSinSource":
            netlist += " SIN ";
            netlist += (getInputPropertyByID(comp, "sinus_offset")?.value || "0") + " ";
            netlist += (getInputPropertyByID(comp, "sinus_ampl")?.value || "0") + " ";
            netlist += (getInputPropertyByID(comp, "sinus_freq")?.value || "0") + " ";
            netlist += (getInputPropertyByID(comp, "sinus_delay")?.value || "0") + " ";
            netlist += (getInputPropertyByID(comp, "sinus_damp")?.value || "0") + " ";
            break;
        default:
            break;
    }

    return { params: "", netlist: netlist, model: "" };
}

export function getComponentPropertyNetlist(property: ComponentInputProperty): string {
    switch (property.id) {
        case "subcirc_par":
            return property.used ? `${property.label}=${property.value} ` : "";
        case "mosfet_width":
            return `W=${property.value} `;
        case "mosfet_length":
            return `L=${property.value} `;
        case "c_init_condition":
        case "l_init_condition":
            return "";// TODO
        case "voltage_acphase":
        case "bjt_model":
        case "mosfet_model":
        case "subcirc_model":
        case "r_resistance":
        default:
            return `${property.value} `;
    }
}