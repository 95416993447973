import { getEmptyAnalysis, getEmptyCircuitProject, getDefaultShowSetting, getDefaultDialog } from "./default";

import {
    CanvasSetting, ShowSetting, CircuitListItem, ComponentItem, DBCircuit, Dialog, Point,
} from "../types";

/**
 * Convert circuits v3 - v5 to v6
 */
export function getNewCircuitDefinition(circuit: DBCircuit["circuit"]) {
    let canvas = getEmptyCircuitProject().canvas;
    const analysis = getEmptyAnalysis();
    let show = getDefaultShowSetting(false);
    const dialog = getDefaultDialog();

    let list: CircuitListItem[] = [];
    let points: Point[] = [];

    // TODO: process old definition of display
    if ("canvas" in circuit)
        canvas = circuit.canvas as CanvasSetting;
    if ("list" in circuit)
        list = circuit.list as CircuitListItem[];
    if ("points" in circuit)
        points = circuit.points as Point[];
    if ("show" in circuit)
        show = circuit.show as ShowSetting;
    // TODO: Implement analysis back compatibility
    // if ("analysis" in circuit)
    //     circuit.analysis as OLDAnalysis;

    // v5 definition of component list
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    list = list.map((item: any) => {
        if (item.name === "Component") {
            item = getNewComponentDefinition(item);
        } else if (item.name === "Wire" && item.points.length === 3) {
            const wirePoints = [item.points[0], item.points[2]];
            item = {
                ...item,
                points: wirePoints,
                direction: points[item.points[0]].x === points[item.points[1]].x ? "x" : "y",
            };
        }
        return item;
    });
    points = getNewPointsDefinition(points);
    // TODO: remove unused points

    // TODO: move v5 definition of analysis
    // parametricRanges: circuit?.parametricRanges || []

    return {
        dialog,
        show,
        list,
        points,
        canvas,
        analysis,
    };
}

/**
 * Convert component v3 - v5 to v6
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getNewComponentDefinition(item: any): ComponentItem {
    if (!("name" in item) || item.name !== "Component") {
        item = { ...item, name: "Component" };
    }
    if (!("showTag" in item)) {
        item = { ...item, showTag: true };
    }
    if (typeof item?.value?.text === "string") {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { text, ...value } = item.value;
        item = { ...item, value };
    }
    if (!("dialog" in item)) {
        const dialog: Dialog = { show: false };
        item = { ...item, dialog };
    }
    if (typeof item.usemodel !== "undefined") {
        item = {
            ...item,
            usemodel: undefined,
            useModel: Boolean(item.usemodel),
        };
    }
    if (!Array.isArray(item.list)) {
        item = {
            ...item,
            alternations: item.list.alternations,
            netlistContactPoints: item.list.netlistContactPoints,
            list: item.list.list,
        };
    }
    if (!Array.isArray(item.properties)) {
        const { allowAnalysis, analysisProperties, properties } = item.properties;
        item = {
            ...item,
            properties,
            allowAnalysis,
            analysisProperties,
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    item.list = item.list.map((i: any) => {
        if (i.name === "ContactPoint" && typeof i.number !== "number") {
            return { ...i, number: parseInt(i.number, 10) };
        }
        return i;
    });

    return item;
}

export function getNewPointsDefinition(points: Point[]): Point[] {
    return points.map((point) => ({ x: point.x, y: point.y }));
}