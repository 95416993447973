import { Substitution } from "../types/analysis";

/** Additional Substitutions are added to the base only if they are not already included in the base. */
export function mergeSubstitutions(base: Array<Substitution>, additional: Array<Substitution>): Array<Substitution> {
    const additionalFiltered = substractSubstitutions(additional, base); 
    return [...base, ...additionalFiltered];
}

/** return result of minuend - subtrahend */
export function substractSubstitutions(
    minuend: Array<Substitution>, subtrahend: Array<Substitution>): Array<Substitution> {
    const subtrahendNames: Array<string> = subtrahend.map(item => item.name);
    return minuend.filter(item => !subtrahendNames.includes(item.name));
}
