import { getDefaultStyleDraw } from "./default";
import { getItemArea } from "./item";
import { getContactPointsFromList, getSelectedList, getWiresFromList } from "./list";
import { isConnected } from "./wire";

import {
    CanvasSetting, ComponentItem, ArrowItem, Point, StyleDraw, StyleText, TextItem, WireItem,
    DrawItem, LineDrawItem, ContactPointItem, ArcDrawItem, BezierDrawItem, List,
    ShowSetting,
} from "../types";

const selectedColor = "#6AADE4";
const selectedFillColor = "rgba(106, 173, 228, 0.4)";

export function setCanvasSize(canvas: HTMLCanvasElement, width: number, height: number) {
    // For retina displays and phones with high pixel density
    const devicePixelRatio = window.devicePixelRatio || 1;

    canvas.width = width * devicePixelRatio;
    canvas.height = height * devicePixelRatio;
    canvas.style.width = width + "px";
    canvas.style.height = height + "px";

    const ctx = canvas.getContext("2d");
    ctx?.scale(window.devicePixelRatio, window.devicePixelRatio);
}

export function getCanvasSize(canvas: HTMLCanvasElement) {
    const width = parseInt(canvas.style.width);
    const height = parseInt(canvas.style.height);

    return { width, height };
}

export function getCanvasCenter(canvas: HTMLCanvasElement) {
    const { width, height } = getCanvasSize(canvas);

    const center = { x: Math.round(width / 2), y: Math.round(height / 2) };

    return center;
}

export function drawGrid(canvas: HTMLCanvasElement, canvasSetting: CanvasSetting) {
    const ctx = canvas.getContext("2d");
    const { zoom, shift, grid } = canvasSetting;

    if (!ctx) return;
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    if (!grid.show || grid.size < 1) return;

    const { width, height } = getCanvasSize(canvas);
    const center = getCanvasCenter(canvas);

    ctx.strokeStyle = "#888888";

    if (grid.size * zoom * grid.eachLine < 10) {
        // grid is too small, draw dots
        const dx = (shift.x * zoom + center.x) % (grid.size * zoom);
        const dy = (shift.y * zoom + center.y) % (grid.size * zoom);

        const diff = { x: grid.size * zoom, y: grid.size * zoom };
        for (; diff.x < 8; diff.x = diff.x * 2);
        for (; diff.y < 8; diff.y = diff.y * 2);

        ctx.lineWidth = 1;

        for (let x = dx; x < width; x += diff.x) {
            for (let y = dy; y < height; y += diff.y) {
                ctx.beginPath();
                ctx.moveTo(x, y);
                ctx.lineTo(x + 1, y + 1);
                ctx.stroke();
            }
        }

    } else {
        // classical line grid
        const dx = (shift.x * zoom + center.x) % (grid.size * zoom * grid.eachLine);
        const dy = (shift.y * zoom + center.y) % (grid.size * zoom * grid.eachLine);

        ctx.lineWidth = 0.5;

        for (let x = dx; x < width; x += grid.size * zoom * grid.eachLine) {
            ctx.beginPath();
            ctx.moveTo(Math.round(x), 0);
            ctx.lineTo(Math.round(x), height);
            ctx.stroke();
        }

        for (let y = dy; y < height; y += grid.size * zoom * grid.eachLine) {
            ctx.beginPath();
            ctx.moveTo(0, Math.round(y));
            ctx.lineTo(width, Math.round(y));
            ctx.stroke();
        }
    }
}

// --------------- style ---------------
export function applyStyleDraw(ctx: CanvasRenderingContext2D, style: StyleDraw, zoom: number) {
    ctx.lineWidth = style.width * zoom;
    ctx.strokeStyle = style.color;
    ctx.lineCap = style.cap;

    if (style.dash === "none")
        ctx.setLineDash([]);
    else
        ctx.setLineDash(style.dash.split(",").map(d => parseFloat(d.trim()) * ctx.lineWidth));

    ctx.lineJoin = style.lineJoin;
    // TODO: not use alpha
    if (style.fill) {
        ctx.fillStyle = style.fillColor;
        ctx.fill();
    }

    if (style.closePath)
        ctx.closePath();
}

export function applyStyleText(ctx: CanvasRenderingContext2D, style: StyleText, zoom: number) {
    ctx.font = `${style.fontSize * zoom}px ${style.font}`;
    ctx.fillStyle = style.color;
    ctx.fillStyle = style.color;

    ctx.textAlign = style.fontAlignVertical;

    // back compatibility
    let fontAlignHorizontal = style.fontAlignHorizontal;
    if (style.fontAlignHorizontal as string === "center")
        fontAlignHorizontal = "middle";

    ctx.textBaseline = fontAlignHorizontal;
}

// --------------- draw ---------------

export function clearCanvas(canvas: HTMLCanvasElement) {
    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
}

export function drawList(
    canvas: HTMLCanvasElement | CanvasRenderingContext2D, list: List, scaledPoints: Point[],
    zoom: number, show: ShowSetting,
) {
    if (canvas instanceof HTMLCanvasElement) {
        const ctx = canvas.getContext("2d");
        if (!ctx) return;

        drawList(ctx, list, scaledPoints, zoom, show);
        return;
    }

    const ctx = canvas;

    for (const item of list) {
        switch (item.name) {
            case "Wire":
                drawWire(ctx, item, scaledPoints, zoom);
                continue;
            case "Component":
                drawComponent(ctx, item, scaledPoints, zoom, show);
                continue;
            case "ContactPoint":
                // drawContactPoint is in drawCircuitPoints and drawNodeNames
                // drawContactPoint(ctx, item, scaledPoints, zoom, selected);
                continue;
            case "ComponentText":
            case "DrawToolText":
                drawText(ctx, item, scaledPoints, zoom);
                continue;
            case "DrawToolVoltageArrow":
                drawArrow(ctx, item, scaledPoints, zoom, false);
                continue;
            case "CurrentArrow":
                show.currentArrow && drawArrow(ctx, item, scaledPoints, zoom, true);
                continue;
            case "DrawToolCurrentArrow":
                drawArrow(ctx, item, scaledPoints, zoom, true);
                continue;
            case "Draw":
                drawDraw(ctx, item, scaledPoints, zoom);
                continue;
            default:
                console.log("Unknown item:", item);
        }
    }
}

export function drawCircuitPoints(
    canvas: HTMLCanvasElement | CanvasRenderingContext2D, list: List,
    scaledPoints: Point[], zoom: number,
) {
    if (canvas instanceof HTMLCanvasElement) {
        const ctx = canvas.getContext("2d");
        if (!ctx) return;

        drawCircuitPoints(ctx, list, scaledPoints, zoom);
        return;
    }

    const ctx = canvas;
    const pointsToDraw = getPointsToDraw(list, scaledPoints);

    for (const { point, count, selected } of pointsToDraw) {
        // == 1 - empty wire - empty point
        // == 2 - just wire - no draw point
        // >= 3 - multiple connection - full point
        if (count === 1) {
            drawPoint(ctx, point, true, zoom, selected);
        } else if (count >= 3) {
            drawPoint(ctx, point, false, zoom, selected);
        }
    }
}

export function drawNodeNames(
    canvas: HTMLCanvasElement | CanvasRenderingContext2D, list: List,
    scaledPoints: Point[], zoom: number,
) {
    if (canvas instanceof HTMLCanvasElement) {
        const ctx = canvas.getContext("2d");
        if (!ctx) return;

        drawNodeNames(ctx, list, scaledPoints, zoom);
        return;
    }

    const ctx = canvas;
    const cps = getNodeNamesToDraw(list);

    for (const cp of cps) {
        // TODO: selected
        drawContactPoint(ctx, cp, scaledPoints, zoom);
    }
}

export function drawPoints(canvas: HTMLCanvasElement | CanvasRenderingContext2D, points: Point[], zoom: number) {
    if (canvas instanceof HTMLCanvasElement) {
        const ctx = canvas.getContext("2d");
        if (!ctx) return;

        drawPoints(ctx, points, zoom);
        return;
    }
    const ctx = canvas;

    for (const point of points) {
        drawPoint(ctx, point, false, zoom, false);
    }
}


type PointToDraw = { point: Point, count: number, selected: boolean };

function getPointsToDraw(list: List, points: Point[]): PointToDraw[] {
    const pointIndexesToDraw = getPointIndexesToDraw(list);
    const selectedPointIndexesToDraw = getPointIndexesToDraw(getSelectedList(list));

    const pointsToDraw: PointToDraw[] = [];
    const counts: Record<number, number> = {};

    pointIndexesToDraw.forEach((number) => {
        if (!counts[number]) {
            counts[number] = 0;
        }
        counts[number]++;
    });

    for (const number in counts) {
        const index = parseInt(number);
        const point = points[index];
        if (!point) throw new Error(`Point ${index} not found`);

        pointsToDraw.push({
            point,
            count: counts[number],
            selected: selectedPointIndexesToDraw.includes(index),
        });
    }
    return pointsToDraw;
}

function getNodeNamesToDraw(list: List): ContactPointItem[] {
    const wires = getWiresFromList(list);
    const cps = getContactPointsFromList(list);

    const nodeNames: ContactPointItem[] = [];

    for (const cp of cps) {
        const { namePoint } = cp;
        // not show GND "0"
        if (namePoint === "0") continue;

        // is in nodeNames
        const theSameNodeName = nodeNames.find(nn => nn.namePoint === namePoint);
        if (theSameNodeName) {
            if (isConnected(cp.point, theSameNodeName.point, wires)) continue;
        }

        nodeNames.push(cp);
    }

    return nodeNames;
}

function getPointIndexesToDraw(list: List): number[] {
    const pointIndexesToDraw: number[] = [];
    for (const item of list) {
        switch (item.name) {
            case "Wire":
                pointIndexesToDraw.push(item.points[0], item.points[1]);
                continue;
            case "ContactPoint":
                pointIndexesToDraw.push(item.point);
                continue;
            case "Component":
                pointIndexesToDraw.push(...getPointIndexesToDraw(item.list));
                continue;
        }
    }

    return pointIndexesToDraw;
}

function drawComponent(
    ctx: CanvasRenderingContext2D, item: ComponentItem, ps: Point[], zoom: number, show: ShowSetting,
) {
    if (item.selected) {
        const area = getItemArea(item, ps);
        ctx.beginPath();
        ctx.rect(area.x, area.y, area.width, area.height);
        ctx.fillStyle = selectedFillColor;
        ctx.fill();
    }

    // tag
    if (show.tag && item.showTag)
        drawText(ctx, item.tag, ps, zoom);

    // value
    if (show.value) {
        const text = item.properties.filter(p => p.show).map(p => p.value).join(" ");
        drawText(ctx, { ...item.value, text }, ps, zoom);
    }

    drawList(ctx, item.list, ps, zoom, show);
}

function drawWire(ctx: CanvasRenderingContext2D, item: WireItem, ps: Point[], zoom: number) {
    const points = item.points.map(p => ps[p]);

    ctx.beginPath();
    ctx.moveTo(points[0].x, points[0].y);
    if (item.direction === "x") {
        ctx.lineTo(points[0].x, points[1].y);
    } else {
        ctx.lineTo(points[1].x, points[0].y);
    }
    ctx.lineTo(points[1].x, points[1].y);

    // back compatibility
    if (!item.style) item.style = getDefaultStyleDraw();

    const style = { ...item.style, color: item.selected ? selectedColor : item.style.color };
    applyStyleDraw(ctx, style, zoom);

    ctx.stroke();
}

function drawContactPoint(
    ctx: CanvasRenderingContext2D, item: ContactPointItem, ps: Point[], zoom: number,
) {
    // const point = ps[item.point];
    const namePoint = ps[item.nameObject];

    // TODO: by the old code
    // drawPoint(ctx, point, false, false, zoom);

    const style = { ...item.style, color: item.selected ? selectedColor : item.style.color };
    applyStyleText(ctx, style, zoom);
    ctx.fillText(item.namePoint, namePoint.x, namePoint.y);

    /* TODO: old code
    const number = list.getNumberUsePoint(point);
    point.setEmpty(number < 2);
    if (number !== 2) {
        point.show();
        } else {
        point.hide();
    }
 
    if (display.tools.contactName.getValue() && !(getNamePoint() == 0 && 
    !display.tools.contactGroundName.getValue()) && main) {
        nameObject.show();
    } else {
        nameObject.hide();
    }
    */
}

function drawPoint(
    ctx: CanvasRenderingContext2D, point: Point, empty: boolean, zoom: number, selected: boolean,
) {

    ctx.beginPath();
    ctx.arc(point.x, point.y, 4 * zoom, 0, 2 * Math.PI, false);

    ctx.lineWidth = 2 * zoom;
    ctx.strokeStyle = "#000000";
    // if (marked) {
    //     ctx.strokeStyle = "#4444FF";
    //     ctx.fillStyle = "#4444FF";
    // } else 
    if (empty) {
        ctx.fillStyle = "#FFFFFF";
    } else {
        ctx.fillStyle = "#000000";
    }

    if (selected) ctx.strokeStyle = selectedColor;

    ctx.closePath();
    ctx.fill();
    ctx.stroke();
}

function drawText(ctx: CanvasRenderingContext2D, item: TextItem, ps: Point[], zoom: number) {
    if (!item.text) return;

    const point = ps[item.point];

    const style = { ...item.style, color: item.selected ? selectedColor : item.style.color };
    applyStyleText(ctx, style, zoom);
    ctx.fillText(item.text, point.x, point.y);
}

function drawArrow(
    ctx: CanvasRenderingContext2D, item: ArrowItem, ps: Point[], zoom: number, closed: boolean,
) {
    const points = item.points.map(p => ps[p]);

    ctx.beginPath();
    ctx.moveTo(points[0].x, points[0].y);
    ctx.lineTo(points[1].x, points[1].y);

    let a1 = Math.atan(Math.abs((points[0].y - points[1].y) / (points[0].x - points[1].x)));
    if (points[0].x < points[1].x) {
        a1 = -a1 + Math.PI;
    }
    if (points[0].y < points[1].y) {
        a1 = -a1;
    }

    const ap0x = Math.cos(a1 + 0.25) * 16 * zoom + points[1].x;
    const ap0y = Math.sin(a1 + 0.25) * 16 * zoom + points[1].y;
    const ap1x = Math.cos(a1 - 0.25) * 16 * zoom + points[1].x;
    const ap1y = Math.sin(a1 - 0.25) * 16 * zoom + points[1].y;

    const style: StyleDraw = {
        ...item.style,
        fill: closed,
        closePath: closed,
        fillColor: item.selected ? selectedColor : item.style.color,
        color: item.selected ? selectedColor : item.style.color,
    };
    applyStyleDraw(ctx, style, zoom);
    ctx.strokeStyle = style.color;
    ctx.lineCap = "round";
    ctx.lineJoin = "round";

    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(ap0x, ap0y);
    ctx.lineTo(points[1].x, points[1].y);
    ctx.lineTo(ap1x, ap1y);

    applyStyleDraw(ctx, style, zoom);
    ctx.stroke();
}

// --------------- draw ---------------

function drawDraw(ctx: CanvasRenderingContext2D, item: DrawItem, ps: Point[], zoom: number) {
    const point = ps[item.point];

    ctx.beginPath();
    ctx.moveTo(point.x, point.y);

    let lastPoint = point;
    for (const drawItem of item.list) {
        switch (drawItem.name) {
            case "DrawToolLine":
                lastPoint = drawLine(ctx, drawItem, ps);
                continue;
            case "DrawToolBezier":
                lastPoint = drawBezier(ctx, drawItem, ps);
                continue;
            case "DrawToolArc":
                lastPoint = drawArc(ctx, drawItem, lastPoint, ps);
                continue;
            case "DrawToolArcCenter":
                lastPoint = drawArcCenter(ctx, drawItem, lastPoint, ps);
                continue;
            default:
                console.log("Unknown draw item:", drawItem);
        }
    }

    const style = { ...item.style, color: item.selected ? selectedColor : item.style.color };
    applyStyleDraw(ctx, style, zoom);
    ctx.stroke();
}

function drawLine(ctx: CanvasRenderingContext2D, item: LineDrawItem, ps: Point[]) {
    const points = item.points.map(p => ps[p]);

    for (const point of points)
        ctx.lineTo(point.x, point.y);

    return points[points.length - 1];
}

function drawBezier(ctx: CanvasRenderingContext2D, item: BezierDrawItem, ps: Point[]) {
    const points = item.points.map(p => ps[p]);
    ctx.bezierCurveTo(points[0].x, points[0].y, points[1].x, points[1].y, points[2].x, points[2].y);

    return points[points.length - 1];
}


function drawArc(ctx: CanvasRenderingContext2D, item: ArcDrawItem, startPoint: Point, ps: Point[]) {
    const points = item.points.map(p => ps[p]);

    if (points[0].x === points[1].x && points[0].y === points[1].y) {
        ctx.lineTo(points[0].x, points[0].y);
        return points[points.length - 1];
    }

    const { x, y, radius, startAngle, endAngle, counterClockwise } = getArcFromPoints(item, false, startPoint, ps);
    ctx.arc(x, y, radius, startAngle, endAngle, counterClockwise);

    return points[points.length - 1];
}

function drawArcCenter(ctx: CanvasRenderingContext2D, item: ArcDrawItem, startPoint: Point, ps: Point[]) {
    const { x, y, radius, startAngle, endAngle, counterClockwise } = getArcFromPoints(item, true, startPoint, ps);
    ctx.arc(x, y, radius, startAngle, endAngle, counterClockwise);

    const points = item.points.map(p => ps[p]);
    return points[points.length - 1];
}

// ---------- arc calculation ------------
export function getArcFromPoints(arc: ArcDrawItem, isCenter: boolean, startPoint: Point, ps: Point[]) {
    const points = arc.points.map(p => ps[p]);

    if (isCenter) {
        // deprecated
        const radius = Math.sqrt(
            (points[0].x - startPoint.x) * (points[0].x - startPoint.x) +
            (points[0].y - startPoint.y) * (points[0].y - startPoint.y),
        );
        const startAngle = Math.atan2(startPoint.y - points[0].y, startPoint.x - points[0].x);

        let endAngle = 0;
        if (startPoint.y === points[1].y && startPoint.x === points[1].x) {
            endAngle = startAngle + Math.PI * 2;
        } else {
            endAngle = Math.atan2(points[1].y - points[0].y, points[1].x - points[0].x);
        }

        return {
            x: points[0].x,
            y: points[0].y,
            radius,
            startAngle,
            endAngle,
            counterClockwise: false,
        };
    } else {
        const middle = { x: 0, y: 0 };
        let radius = 0;
        let startAngle = 0;
        let endAngle = 0;
        let counterClockwise = false;

        if (startPoint.y === points[1].y && startPoint.x === points[1].x) {

            middle.x = (startPoint.x - points[0].x) / 2 + points[0].x;
            middle.y = (startPoint.y - points[0].y) / 2 + points[0].y;

            radius = Math.sqrt(
                (middle.x - startPoint.x) * (middle.x - startPoint.x) +
                (middle.y - startPoint.y) * (middle.y - startPoint.y),
            );

            startAngle = Math.atan2(startPoint.y - middle.y, startPoint.x - middle.x);
            endAngle = startAngle + Math.PI * 2;

        } else {
            const xp1 = (startPoint.x - points[0].x) / 2 + points[0].x;
            const xp2 = (points[0].x - points[1].x) / 2 + points[1].x;
            const yp1 = (startPoint.y - points[0].y) / 2 + points[0].y;
            const yp2 = (points[0].y - points[1].y) / 2 + points[1].y;

            const k1 = -(startPoint.x - points[0].x) / ((startPoint.y - points[0].y));
            const k2 = -(points[0].x - points[1].x) / ((points[0].y - points[1].y));

            const b1 = yp1 - k1 * xp1;
            const b2 = yp2 - k2 * xp2;

            middle.x = (b2 - b1) / (k1 - k2);
            middle.y = k1 * middle.x + b1;

            radius = Math.sqrt(
                (middle.x - startPoint.x) * (middle.x - startPoint.x) +
                (middle.y - startPoint.y) * (middle.y - startPoint.y),
            );

            startAngle = Math.atan2(startPoint.y - middle.y, startPoint.x - middle.x);
            endAngle = Math.atan2(points[1].y - middle.y, points[1].x - middle.x);
            let middleAngle = Math.atan2(points[0].y - middle.y, points[0].x - middle.x);

            if (middleAngle < startAngle) {
                middleAngle += Math.PI * 2;
            }
            if (endAngle < startAngle) {
                endAngle += Math.PI * 2;
            }

            if (
                (middleAngle < startAngle && middleAngle < endAngle)
                || (middleAngle > startAngle && middleAngle > endAngle)
            ) {
                counterClockwise = true;
            }
        }

        return {
            x: middle.x,
            y: middle.y,
            radius,
            startAngle,
            endAngle,
            counterClockwise,
        };
    }
}