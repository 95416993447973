import { useState } from "react";
import moment from "moment";
import { groupBy } from "lodash";
import {
    Breadcrumbs,
    Button, Card, CardActionArea, CardContent,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress,
    Tooltip,
    Typography,
} from "@mui/material";

import { useUser } from "./User";
import { useProjectLoad } from "../services/project";

import { dbSelect } from "../services/api";
import { getCircuitFolderName, getCircuitName, useAsyncEffect } from "../services/common";

import { Grid, Icon, ListVertical } from ".";

import { DBCircuit } from "../types";
import { mdiFileOutline, mdiFolderOutline } from "@mdi/js";

type LoadProjectProps = {
    open: boolean,
    onClose: () => void,
};

export default function LoadProject({ open, onClose }: LoadProjectProps) {
    const user = useUser();
    const load = useProjectLoad();

    const [circuits, setCircuits] = useState<null | Record<string, Omit<DBCircuit, "circuit">[]>>(null);
    const [circuitsPrivate, setCircuitsPrivate] = useState<Omit<DBCircuit, "circuit">[]>([]);

    const [folder, setFolder] = useState("");

    useAsyncEffect(async (isMountedRef) => {
        if (open) {
            // if you select all with circuit, the server will return 500 error!
            const circuits = await dbSelect<Omit<DBCircuit, "circuit">>(
                "circuit",
                ["id", "title", "date", "description", "privateId"],
            );
            const groupedCircuits = groupBy(
                circuits.filter(c => !c.privateId),
                c => getCircuitFolderName(c.title) || "Without folder",
            );

            if (isMountedRef.current)
                setCircuits(groupedCircuits);

            if (user.isAdmin && isMountedRef.current)
                setCircuitsPrivate(circuits.filter(c => c.privateId === user.id));
        }
    }, [open, user.id]);

    function handleFolder(folder: string) {
        setFolder(folder);
    }

    async function handleLoadCircuit(id: number) {
        // TODO: show loading
        await load(id);
        setFolder("");
        onClose();
    }

    return <Dialog onClose={onClose} open={open} fullWidth maxWidth="lg">
        <DialogTitle>Load project form server</DialogTitle>
        <DialogContent>
            <DialogContentText sx={{ paddingBottom: 2 }}>
                Here you can find projects created by administrators / teachers for public use.
            </DialogContentText>

            {circuits === null && <LinearProgress />}
            {circuits !== null && <>
                {user.isAdmin && <ListVertical title="Your projects (admin)">
                    <Grid container spacing={2}>
                        {circuitsPrivate.length === 0 && <Typography>No private projects</Typography>}
                        {circuitsPrivate.map(circuit => <Circuit
                            key={circuit.id}
                            isAdmin={user.isAdmin}
                            circuit={circuit}
                            onClick={() => handleLoadCircuit(circuit.id)}
                        />)}
                    </Grid>
                </ListVertical>}
                <ListVertical title="Folders">
                    <Breadcrumbs>
                        <Typography sx={{ cursor: "pointer" }} onClick={() => handleFolder("")}>
                            All folders
                        </Typography>
                        {folder && <Typography color="text.primary">{folder}</Typography>}
                    </Breadcrumbs>

                    <Grid container spacing={2}>
                        {folder && circuits[folder] && circuits[folder]
                            .sort((a, b) => a.title.localeCompare(b.title))
                            .map(circuit => <Circuit
                                key={circuit.id}
                                isAdmin={user.isAdmin}
                                circuit={circuit}
                                onClick={() => handleLoadCircuit(circuit.id)}
                            />)}
                        {folder === "" && Object.keys(circuits)
                            .sort()
                            .filter(folder => folder !== "hide" || user.isAdmin)
                            .map(folder => <Folder key={folder} title={folder} onClick={() => handleFolder(folder)} />)}
                    </Grid>
                </ListVertical>
            </>}

        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
        </DialogActions>
    </Dialog>;
}

function Folder({ title, onClick }: { title: string, onClick: () => void }) {
    return <Grid>
        <Card sx={{ width: 150, textAlign: "center" }} onClick={onClick}>
            <CardActionArea>
                <CardContent>
                    <Icon path={mdiFolderOutline} size={3} />
                    <Typography gutterBottom component="div" noWrap>
                        {title}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    </Grid>;
}

type CircuitProps = {
    circuit: Omit<DBCircuit, "circuit">,
    isAdmin: boolean,
    onClick: () => void,
};


function Circuit({ circuit, isAdmin, onClick }: CircuitProps) {
    return <Grid>
        <Tooltip title={circuit.description}>
            <Card sx={{ width: 150, textAlign: "center" }} onClick={onClick}>
                <CardActionArea>
                    <CardContent>
                        <Typography
                            sx={{ position: "absolute", left: 8, top: 4, fontSize: 12 }}
                            color="text.secondary">
                            ({circuit.id})
                        </Typography>
                        {circuit.description && <Typography sx={{ position: "absolute", top: 4, right: 8 }}>
                            *
                        </Typography>}
                        <Icon path={mdiFileOutline} size={3} />
                        <Typography sx={{ fontSize: 12 }} color="text.secondary">
                            {moment(circuit.date).format("ll")}
                        </Typography>
                        <Typography gutterBottom component="div" noWrap>
                            {getCircuitName(circuit.title)}
                        </Typography>
                    </CardContent>
                </CardActionArea>
                {isAdmin && <>
                    {/* TODO: edit / delete */}
                </>}
            </Card>
        </Tooltip>
    </Grid>;
}