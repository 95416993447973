import easyDB from "easy-db-browser";

import { getUser, loginUser as loginUserAPI, logoutUser as logoutUserAPI } from "./api";

import { DBUser, User } from "../types";

type ClientDB = {
    // id "tokens"
    user: { accessToken: string },
};

const { select, update, remove } = easyDB<ClientDB>();


function getUserTypeFromDB(user: null | DBUser): null | User {
    if (!user) return null;
    return {
        id: Number(user.id),
        name: user.name,
        login: user.login,
        isLogged: true,
        isAdmin: Boolean(user.isAdmin),
    };
}

export async function getAccessToken(): Promise<null | string> {
    const tokens = await select("user", "tokens");
    const accessToken = tokens?.accessToken;

    return accessToken || null;
}

export async function isUserLogged(): Promise<null | User> {
    const user = await getUser();
    return getUserTypeFromDB(user);
}

export async function loginUser(login: string, password: string): Promise<null | User> {
    const user = await loginUserAPI(login, password);

    if (user)
        await update("user", "tokens", { accessToken: user.accessToken });

    return getUserTypeFromDB(user);
}

export async function logoutUser(): Promise<void> {
    await logoutUserAPI();
    await remove("user", "tokens");
}
