
import { ServerResponse } from "../analysis/types";
import { ComponentPreview, DBCircuit, DBComponent, DBUser, TableName, User } from "../types";
import { getAccessToken } from "./user";
import { getNewComponentDefinition, getNewPointsDefinition } from "./deprecated";

// const urlAPI = process.env.NODE_ENV === "development" ?
//     "https://geec.fel.cvut.cz/v6/server/api.php" : "./server/api.php";
// TODO: only for publishing beta versions
const urlAPI = "https://geec.fel.cvut.cz/v6/server/api.php";

async function getHeaders() {
    const accessToken = await getAccessToken();

    return {
        "Content-Type": "application/json",
        ...(accessToken ? { "Authorization": `Bearer ${accessToken}` } : {}),
    };
}

async function get(command: string, params: Record<string, string | number | boolean> = {}) {
    try {
        // eslint-disable-next-line max-len
        const url = `${urlAPI}?command=${encodeURIComponent(command)}&${Object.entries(params).map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join("&")}`;
        const response = await fetch(url, {
            method: "GET",
            headers: await getHeaders(),
        });

        const json = await response.json();

        if (Array.isArray(json)) {
            return formatArrayFromPHP(json);
        } else {
            return formatRowFromPHP(json);
        }
    } catch (e) {
        if (e instanceof Error) {
            return e;
        } else {
            console.error(e);
            return new Error("Unknown error.");
        }
    }
}

async function post(command: string, params: Record<string, unknown> = {}) {
    try {
        const response = await fetch(urlAPI, {
            method: "POST",
            headers: await getHeaders(),
            body: JSON.stringify({ command, ...params }),
        });
        if (response.status >= 500)
            throw new Error("Server error.");
        if (response.status >= 400)
            throw new Error("Client error.");

        const json = await response.json();

        return formatRowFromPHP(json);
    } catch (e) {
        if (e instanceof Error) {
            return e;
        } else {
            console.error(e);
            return new Error("Unknown error.");
        }
    }
}

async function put(command: string, params: Record<string, unknown> = {}) {
    try {
        const response = await fetch(urlAPI, {
            method: "PUT",
            headers: await getHeaders(),
            body: JSON.stringify({ command, ...params }),
        });

        const json = await response.json();

        return formatRowFromPHP(json);
    } catch (e) {
        if (e instanceof Error) {
            return e;
        } else {
            console.error(e);
            return new Error("Unknown error.");
        }
    }
}

// ---------------- User ----------------
export async function getUser(): Promise<null | DBUser> {
    const response = await get("user");

    if ("id" in response && "name" in response) {
        return response as DBUser;
    } else {
        return null;
    }
}

export async function loginUser(login: string, password: string) {
    const response = await post("login", { login, password });

    if ("id" in response && "name" in response && "accessToken" in response) {
        return response as User & { accessToken: string };
    } else {
        return null;
    }
}

export async function logoutUser(): Promise<void> {
    await post("logout", {});
}

// ---------------- Circuit ----------------

export async function insertCircuit(circuitDB: Omit<DBCircuit, "id">, user: User) {
    if (!user.isAdmin)
        return new Error("Insert circuit can only admin!");

    const id = await dbInsert("circuit", circuitDB);
    return id;
}
export async function updateCircuit(circuitDB: DBCircuit, user: User) {
    if (!user.isAdmin)
        return new Error("Insert circuit can only admin!");

    const id = await dbUpdate("circuit", circuitDB.id, circuitDB);
    return id;
}

// ---------------- Component ----------------
export async function getComponentList(): Promise<ComponentPreview[]> {
    return await dbSelect<DBComponent>("component", ["id", "icon", "basic", "category", "name"]);
}

export async function getComponent(id: number) {
    const response = await dbSelectRow<DBComponent>("component", id);
    if (!response) throw new Error(`There is on component number ${id}!`);

    // TODO: check version
    const { name, points, icon, ...component } = response;
    return {
        component: getNewComponentDefinition({ ...component, componentName: name }),
        points: getNewPointsDefinition(points),
    };
}

// ---------------- DB ----------------

/**
 * SELECT query
 * @param table 
 * @param columns if is not set, all columns will be selected
 * @returns json response
 */
export async function dbSelect<T>(table: TableName, columns: string[] = ["*"]): Promise<T[]> {
    const array = await get("SELECT", { table, columns: columns.join(",") });

    return formatArrayFromPHP(array as Record<string, string>[]);
}

export async function dbSelectRow<T>(table: TableName, id: number): Promise<null | T> {
    const array = await get("SELECT-ROW", { table, id }) as Record<string, string>[];

    const row = array[0];
    if (row) {
        return formatRowFromPHP(row) as T;
    } else {
        return null;
    }
}

export async function dbInsert<T extends Record<string, unknown>>(table: TableName, row: T): Promise<number> {
    const response = await post("INSERT", { table, row });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (response as any).id;
}
export async function dbUpdate<T extends Record<string, unknown>>(table: TableName, id: number, row: T): Promise<void> {
    await put("UPDATE", { table, id, row });
    // TODO: check response
}


function formatArrayFromPHP<T>(array: Record<string, string>[]): T[] {
    return array.map(row => formatRowFromPHP(row)) as T[];
}

function formatRowFromPHP<T>(row: Record<string, string>): Record<string, T> {
    const formattedRow: Record<string, T> = {};
    Object.entries(row).forEach(([key, value]) => {
        formattedRow[key] = formatValueFromPHP(value);
    });
    return formattedRow;
}

function formatValueFromPHP(value: string | number | boolean) {
    if (typeof value !== "string") return value;

    if (value.startsWith("[") || value.startsWith("{")) {
        // is JSON
        return JSON.parse(value);
    } else if (String(parseFloat(value)) === value) {
        // is number
        return parseFloat(value);
    } else {
        // is string
        return value;
    }
}

/** analysis server request implementation */
async function serverRequest(url: string, script: string): Promise<ServerResponse> {

    const headers = new Headers();

    headers.set("Content-Type", "application/json");

    const rslt = await fetch(url , {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            mainfile: script,
            otherfiles: [],
        }),
    });

    if(rslt && rslt.ok){
        const resultText = await rslt.text();
        const result = JSON.parse(resultText);

        return {status: result[0], data: result[3], stdout: result[4], stderr: result[5]};
    }else{
        console.log(rslt);
        return {status: 500, data: "No data!!!"};
    }
}

export async function GEECNGSpiceRequest(script: string): Promise<ServerResponse> {
    const url = "https://geec.fel.cvut.cz/v6/server/ngspice.php";
    return await serverRequest(url, script);
}

export async function GEECMapleRequest(script: string): Promise<ServerResponse> {
    const url = "https://geec.fel.cvut.cz/v6/server//maple.php";
    return await serverRequest(url, script);
}

export async function GEECPythonRequest(script: string): Promise<ServerResponse> {
    const url = "https://geec.fel.cvut.cz/v6/server/python.php";
    return await serverRequest(url, script);
}