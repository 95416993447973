import {
    ComponentNetlistReturn, getDefaultComponentNetlist,
    getEmptyComponentNetlist, getIndependentSourceNetlist,
} from "./components";
import { AnalysisCircuit, AnalysisComponent } from "../types";

type GeneratorFunction = "empty" | "default" | "transferFunction" | "independentSource";

export interface SpiceNetlistGenerator {
    transferFunction: (component: AnalysisComponent, proj?: AnalysisCircuit) => ComponentNetlistReturn;
    independentSource: (component: AnalysisComponent, proj?: AnalysisCircuit) => ComponentNetlistReturn;
    default: (component: AnalysisComponent, proj?: AnalysisCircuit) => ComponentNetlistReturn;
    empty: (component: AnalysisComponent, proj?: AnalysisCircuit) => ComponentNetlistReturn;
}

function getGeneratorFunction(component: AnalysisComponent): GeneratorFunction {
    if (component.tag === "") {
        return "empty";
    }

    switch (component.class) {
        case "TransferFunction":
        case "DiffTransferFunction":
            return "transferFunction";
        case "IndepSource":
        case "IndepSinSource":
            return "independentSource";
        // TODO: ctrl sources (node mangling problem)
        default:
            return "default";
    }
}

export function getSpiceGenerator(): SpiceNetlistGenerator {
    return {
        transferFunction: getEmptyComponentNetlist,
        independentSource: getIndependentSourceNetlist,
        default: getDefaultComponentNetlist,
        empty: getEmptyComponentNetlist,
    };
}

export function spiceGetNetlist(proj: AnalysisCircuit, generator: SpiceNetlistGenerator): string {
    // TODO: simulator based
    // TODO: component based
    // TODO: input property based
    // TODO: analysis request based
    // TODO: model management (same redundant models, ...)

    const finalComponents: string[] = [];
    const finalParams: string[] = [];
    const finalModels: string[] = [];

    proj.components.forEach((component: AnalysisComponent) => {
        const getNetlist = generator[getGeneratorFunction(component)];

        if(getNetlist){
            const { params, netlist, model } = getNetlist(component);
            finalComponents.push(netlist);
            finalParams.push(params);
            finalModels.push(model);
        }
    });

    let finalNetlist = finalParams.filter(p => p !== "").join("\n") + "\n";
    finalNetlist += finalComponents.filter(p => p !== "").join("\n") + "\n";
    finalNetlist += finalModels.filter(p => p !== "").join("\n") + "\n";

    return finalNetlist;
}

export function spiceParseNetlist(netlist: string): AnalysisComponent[] {
    // TODO: make it generic for ngspice, ltspice, opus, ...

    // Models
    // .model name type (IS=1e-14 BF=100)
    // type = npn,pnp,...
    // multiline models
    // .model name type (IS=1e-14
    // + BF=100)

    // Subcircuits
    // .subckt <name> <node1> <node2> ...  [PARAM:|PARAMS] <ident>=<value> ...
    // <components>
    // .ends <name>
    type Model = {name: string, lines: string[]};
    const models: Model[] = [];
    let activeSubcktName = "";
    let activeModelName = "";
    let model: string[] = [];

    // split by lines and trim each line
    netlist.split("\n").forEach((l: string) => {
        const line = l.trim();

        // Skip empty lines
        if(line === "") {
            return;
        }

        // Skip comments
        if(line.startsWith("*")){
            return;
        }

        // Subcircuit
        if((line.startsWith(".subckt") || line.startsWith(".SUBCKT")) && activeSubcktName === "") {
            const matchSubckt = line.match(/\.[subckt|SUBCKT]\s+(\w+)\s+(.*)/i);
            if(matchSubckt){
                activeSubcktName = matchSubckt[1];
                // no return, continue to put the line into
            }
        }

        // end of subcircuit
        if(line.startsWith(".ends") || line.startsWith(".ENDS")){
            const matchSubckt = line.match(new RegExp("\\.subckt\\s+()\\s+(.*)"));
            if(matchSubckt){
                model.push(line);
                models.push({name: activeSubcktName, lines: [...model]});
                activeSubcktName = "";
                model = [];
            }
        }

        // active subcircuit
        if(activeSubcktName !== ""){
            model.push(line);
            return;
        }

        // Models (models in a subcircuit should not get here)
        if(line.startsWith(".model") || line.startsWith(".MODEL")){
            const matchModel = line.match(/\.[subckt|MODEL]\s+(\w+)\s+(.*)/i);
            if(matchModel){
                activeModelName = matchModel[1];
                model.push(line);
                return;
            }
        }

        // active model
        if(activeModelName !== ""){
            if(line.startsWith("+")){
                model.push(line);
                return;
            }else{
                // end of the model
                models.push({name: activeModelName, lines: [...model]});
                activeModelName = "";
            }
        }

        // Components

    });
    return [];
}


/**
 * Steps:
 *
 * - Convert netlist to array of lines
 * - Get rid of commented lines
 * - If line starts with "+" append it to previous??
 * - Get all subckts and models (consule the lines)
 * - Get all .param
 * - Get all .include
 * - Get .end/.ends
 * - Get all .save .ac .dc ...
 * - Get all .options
 * - Consume all .whatever_unknown
 *
 *
 *
 */