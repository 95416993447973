import moment from "moment";
import { useEffect, useRef } from "react";
import { ContactPointItem, Point } from "../types";

export function useAsyncEffect(
    effect: (isMountedRef: { current: boolean }) => Promise<void>,
    deps?: React.DependencyList,
) {
    const isMountedRef = useRef(true);

    useEffect(() => {
        isMountedRef.current = true;

        return () => {
            isMountedRef.current = false;
        };
    }, []);

    useEffect(() => {
        effect(isMountedRef);
    }, deps);
}

// for see context set DEV = true
export function useContextDevTool<T>(name: string, variable: T, showCoordinate = true) {
    // development debugging
    if (process.env.NODE_ENV === "development") {
        const prevVariable = useRef<T>(variable);
        useEffect(() => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (global.DEV) {
                const diffs = getDiffs(name, prevVariable.current, variable, showCoordinate);
                if (Object.keys(diffs).length > 0)
                    console.log(`Changed ${name} context`, moment().format(), diffs, variable);
            }
            prevVariable.current = variable;
        }, [variable]);
    }
}

function getDiffs(
    name: string, prev: unknown, next: unknown, showCoordinate: boolean,
): Record<string, [unknown, unknown]> {
    let diffs: Record<string, [unknown, unknown]> = {};

    if (showCoordinate === false && (name.endsWith(".x") || name.endsWith(".y"))) return diffs;

    if (typeof prev !== typeof next) {
        diffs[name] = [prev, next];
    } else if (typeof prev === "object" && prev !== null && next !== null) {
        if (Array.isArray(prev)) {
            if (Array.isArray(next)) {
                if (prev.length !== next.length) {
                    diffs[name] = [prev, next];
                } else {
                    for (let i = 0; i < prev.length; i++) {
                        diffs = { ...diffs, ...getDiffs(`${name}[${i}]`, prev[i], next[i], showCoordinate) };
                    }
                }
            } else {
                diffs[name] = [prev, next];
            }
        } else {
            const keys = Object.keys(prev);
            for (const key of keys) {
                diffs = {
                    ...diffs, ...getDiffs(
                        `${name}.${key}`,
                        (prev as Record<string, unknown>)[key],
                        (next as Record<string, unknown>)[key],
                        showCoordinate,
                    ),
                };
            }
        }
    } else if (prev !== next) {
        diffs[name] = [prev, next];
    }

    return diffs;
}


export function getCircuitFolderName(title: string) {
    const [folder, name] = title.split("/");
    return name ? folder : null;
}
export function getCircuitName(title: string) {
    const [folder, name] = title.split("/");
    return name ? name : folder;
}

export function getMovedPoints(points: Point[], move: Point): Point[] {
    return points.map(p => ({ x: p.x - move.x, y: p.y - move.y }));
}

export function getNewTagName(defaultTagName: string, usedTagNames: string[]): string {
    // without name
    if (defaultTagName.length < 1)
        return "";

    const tagChar = defaultTagName.charAt(0);

    for (let i = 1; i < 1_000_000; i++) {
        const tagName = `${tagChar}${i}`;
        if (!usedTagNames.includes(tagName))
            return tagName;
    }

    throw new Error(`Too many "${tagChar}" components!`);
}
export function getNewContactPointName(namePoint: string, number: number, usedCPNames: string[]) {
    // is defined as ground
    if (number === 0)
        return "0";

    // is written text by user
    if (parseInt(namePoint, 10).toString() !== namePoint)
        return namePoint;

    for (let i = 1; i < 1_000_000; i++) {
        const pointName = i.toString();
        if (!usedCPNames.includes(pointName))
            return pointName;
    }

    throw new Error("Too many contact point names!");
}
export function getMainContactPointByName(cp1: ContactPointItem, cp2: ContactPointItem): null | ContactPointItem {
    if (cp1.number === 0) return cp1;
    if (cp2.number === 0) return cp2;

    const cp1NameNumber = parseInt(cp1.namePoint, 10);
    const cp2NameNumber = parseInt(cp2.namePoint, 10);

    const isCP1String = cp1NameNumber.toString() !== cp1.namePoint;
    const isCP2String = cp2NameNumber.toString() !== cp2.namePoint;

    if (isCP1String && isCP2String) return null;
    if (isCP1String) return cp1;
    if (isCP2String) return cp2;

    return cp1NameNumber < cp2NameNumber ? cp1 : cp2;
}